import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import Link from '@material-ui/core/Link';

import { faPen, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {formatAmount} from 'utils/format';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "left",
    fontSize: "20px"
  },
  ...customSelectStyle,
  ...buttonStyle,
  addressBlock: {
    color: "black",
    fontSize: "15px",
    fontWeight: "400",
    whiteSpace: "pre-wrap"
  },
  addressIconAction: {
    color: "#00cae3",
    width: "15px"
  },
  summaryCell:{
    fontWeight:"400"
  },
  buttonSchedule:{
    textTransform:'none',
    fontWeight:"700"
  },
  alertText:{
    fontSize:"25px",
    lineHeight:"30px"
  },
  subtitle:{
    fontSize:"17px",
    lineHeight:"30px",
    fontWeight:"500"
  }
};

class Step5 extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      pickupDate: "",
      simpleSelect: "0",
      desgin: false,
      code: false,
      develop: false,
      alert:'',
      addresses : []
    };
    this.handleSimple = this.handleSimple.bind(this);
    //this.doSchedulePickup = this.doSchedulePickup.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.doSendNotification = this.doSendNotification.bind(this);
  }
  sendState() {
    return this.state;
  }

  handleDateChange(moment){
    this.setState({pickupDate:moment.format("YYYY-MM-DD")});
  }

  handleSimple(event){
    this.setState({simpleSelect: event.target.value });
  };

  valid(current){
    let yesterday = Datetime.moment().subtract( 1, 'day' );
    return current.isAfter( yesterday );
  }

  isValidated() {
    return true;
  }

  hideAlert(){
    this.setState({alert:''});
  }

  componentDidMount(){
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

    axios.put(apiURL+"/users/draftshipments/"+this.props.draftNo, {step:'NOTIFY'})
    .then( (res) => {
       console.log('Draft was updated!');
    }).catch((error) =>{
      console.log('Draft could not be updated. '+error);
    });

    axios.get(apiURL+"/merchant/addressbook")
    .then( (res) => {
       this.setState({addresses:res.data});
    }).catch((error) =>{
      console.log('No fue posible obtener las direcciones de éste comercio. '+error);
    });

  }

  // doSchedulePickup(){

  //   const { classes } = this.props;

  //  if (this.state.pickupDate === "" || this.state.simpleSelect === "" ){
  //     const alert =(
  //       <SweetAlert
  //         error
  //         style={{ display: "block", marginTop: "-150px", width:"40%" }}
  //         title="Por favor, escoge fecha y rango de horario para la recolección."
  //         onConfirm={() => this.hideAlert()}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnText="Aceptar"
  //         confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
  //         confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
  //       >
  //       </SweetAlert>
  //     );
  //     this.setState({alert});
      
  //     return;
  //  }

  //   const times = [
  //     '7:00 - 9:00',
  //     '9:00 - 11:00',
  //     '11:00 - 13:00',
  //     '13:00 - 15:00',
  //     '15:00 - 17:00',
  //     '17:00 - 19:00'
  //   ]

  //   const pickupDate = this.state.pickupDate;
  //   const pickupTime = times[this.state.simpleSelect];

  //   const request  = {
  //     date:pickupDate,
  //     timeInterval: pickupTime
  //   }

  //   const token = localStorage.getItem('jwt');
  //   axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
  //   axios.post(apiURL+"/merchant/pickuprequest/",request)
  //   .then( (res) => {
        
  //     const alert =(
  //       <SweetAlert
  //             success
  //             style={{ display: "block", marginTop: "-250px", width:"40%" }}
  //             title=""
  //             onConfirm={() => this.hideAlert()}
  //             onCancel={() => this.hideAlert()}
  //             confirmBtnText="Finalizar"
  //             confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.lg}
  //             confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
  //           >
  //             <h1>&iexcl;Listo! Tu envío ha sido programado</h1>
  //             <p style={{textAlign:"center", fontWeight:"400", fontSize:"17px"}}>
  //               Estaremos recogiéndolo el {pickupDate} de {pickupTime} en la siguiente dirección:
  //             </p>
  //             <p style={{textAlign:"center", fontWeight:"400", fontSize:"17px"}}>
  //               Chopinmol<br></br>
  //               Vía 6 3-56 zona 4<br></br>
  //               Edificio OEG nivel 7<br></br>
  //               Guatemala 01004<br></br>
  //               Tel: 5819-9414<br></br>
  //               Atención: Heidy López
  //             </p>
  //           </SweetAlert>
  //     );
  //     this.setState({alert});

  //   }).catch((error) =>{
  //     console.log(error);
  //     let msg = 'Error, por favor, intenta nuevamente.';
  //     if (error.response && error.response.data && error.response.data.error){
  //       msg = error.response.data.error;
  //     }else{
  //       msg = "Ha ocurrido un error inesperado, por favor, intenta de nuevo."; 
  //     }
  //     const alert =(
  //       <SweetAlert
  //         error
  //         style={{ display: "block", marginTop: "-250px", width:"40%" }}
  //         title="Ouch! Hubo un error"
  //         onConfirm={() => this.hideAlert()}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnText="Aceptar"
  //         confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
  //         confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
  //       >
  //         <div className={classes.alertText}>
  //           {msg}
  //         </div>
  //       </SweetAlert>
  //     );
  //     this.setState({alert});
  //   });

  // }

  doSendNotification(){
      const { classes } = this.props;

      let selectedAddress = this.state.addresses[this.state.simpleSelect];

      const alert =(
        <SweetAlert
            success
            style={{ display: "block", marginTop: "-250px", width:"40%" }}
            title=""
            onConfirm={() => this.props.restartWizardAction()}
            onCancel={() => this.hideAlert()}
            confirmBtnText="Finalizar"
            confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.lg}
            confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
          >
            <h1>&iexcl;Listo! La notificación ha sido enviada</h1>
            <p style={{textAlign:"center", fontWeight:"400", fontSize:"17px"}}>
              Estaremos recolectando tus paquetes en la siguiente dirección:
            </p>
            <p style={{textAlign:"center", fontWeight:"400", fontSize:"17px", whiteSpace: "pre-wrap"}}>
            { selectedAddress && (
                <div>
                  {selectedAddress.Name}<br />
                  {selectedAddress.Address}<br />
                  {selectedAddress.Municipio}<br />
                  {selectedAddress.Departamento}<br />
                  Tel: {selectedAddress.Contact}<br />
                  Atención: {selectedAddress.ContactName}<br />
                </div>
            )}
            </p>
          </SweetAlert>
      );
      this.setState({alert});

      const token = localStorage.getItem('jwt');
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
      axios.delete(apiURL+"/users/draftshipments/"+this.props.draftNo)
      .then( (res) => {
         console.log('Draft was disposed');
      }).catch((error) =>{
        console.log('Draft could not be disposed. '+error);
      });
  }
  
  render() {
    const { classes } = this.props;
    const simpleSelect = this.state.simpleSelect;

    let total = 0;
    this.props.deliveries.map ( (i) => total = total + parseFloat(i.cod.value ));

    let dataRows = [];
    dataRows.push(
        ['No. de paquetes:', this.props.deliveries.length],
        ['Total de Guías COD:', this.props.deliveries.length],
        ['Total de monto COD:', formatAmount(total)]
    );

    let selectedAddress = this.state.addresses[simpleSelect];

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>

        {this.state.alert}

          <p className={classes.infoText}>Notificar recolección a ChopinTrack</p>
        </GridItem>
        {/* <GridItem xs={3}/>
        <GridItem xs={3}>
          <p className={classes.infoText}>Resumen de tu envío</p>
        </GridItem> */}

        {/* <GridItem xs={3}>
          <InputLabel htmlFor="pickup-date">
              Escoge una fecha
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              timeFormat={false}
              isValidDate={ this.valid }
              closeOnSelect = {true}
              inputProps={{id:"pickup-date"}}
              onChange = {this.handleDateChange}
            />
          </FormControl>
        </GridItem> */}
        {/* <GridItem xs={3}>
          <InputLabel htmlFor="simple-select">
              Escoge una hora
          </InputLabel>
          <FormControl fullWidth>
            <Select MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    value={simpleSelect}
                    onChange={this.handleSimple}
                    inputProps={{name: "simpleSelect",id: "simple-select"}}
            >
              <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="0">
                7:00 - 9:00
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="1">
                9:00 - 11:00
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="2">
                11:00 - 13:00
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="3">
                13:00 - 15:00
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="4">
                15:00 - 17:00
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="5">
                17:00 - 19:00
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem> */}
        <GridItem xs={12}>
          <p className={classes.subtitle}>Dirección de recolección:</p>
          <p className={classes.addressBlock}>

            <FormControl style={{width:'400px'}}>
              <Select MenuProps={{className: classes.selectMenu}}
                      classes={{select: classes.select}}
                      value={simpleSelect}
                      onChange={this.handleSimple}
                      inputProps={{name: "simpleSelect",id: "simple-select"}}
              >
                {this.state.addresses.map ( (item, index) => {
                      return (
                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value={index}>
                          {item.Address}
                        </MenuItem>
                      )
                  })}
              </Select>
            </FormControl>
            <br/><br/>
            { selectedAddress && (
                <div>
                  {selectedAddress.Name}<br />
                  {selectedAddress.Address}<br />
                  {selectedAddress.Municipio}<br />
                  {selectedAddress.Departamento}<br />
                  Tel: {selectedAddress.Contact}<br />
                  Atención: {selectedAddress.ContactName}<br />
                </div>
            )}
          </p>
          {/* <p className={classes.addressBlock}>
            <FontAwesomeIcon icon={faPen} style={style.addressIconAction}/>
            <Link
              component="button"
              underline="always"
              style={{color:"#00cae3",fontSize:"16px"}}
              onClick={() => {
                alert("Editar dirección");
              }}>editar dirección</Link>
          </p>
          <p className={classes.addressBlock}>
            <FontAwesomeIcon icon={faPlus} style={style.addressIconAction}/>
              <Link
              component="button"
              underline="always"
              style={{color:"#00cae3",fontSize:"16px"}}
              onClick={() => {
                alert("Agregar dirección");
              }}>agregar dirección</Link>
          </p> */}
        </GridItem>
        {/* <GridItem xs={3}>
          
          <Table
              striped
              tableData={dataRows}
              customCellClasses={[classes.summaryCell, classes.summaryCell]}
              customClassesForCells={[0,1]}
            />

        </GridItem> */}

        <GridItem xs={12}>
            <div style={{textAlign:'center', marginTop:"100px", marginBottom:"50px"}}>
              <Button color="primary" 
                      size="lg" 
                      onClick={() => this.doSendNotification()}
                      className={classes.buttonSchedule}>Notificar</Button>
            </div>
          </GridItem>

      </GridContainer>
    );
  }
}

Step5.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step5);
