import React from "react";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Delete extends React.Component{

    render(){
        return(
            <a href="#" onClick={ (e) =>{e.preventDefault(); this.props.deleteFunction();}}>
                <FontAwesomeIcon icon={this.props.icon ? this.props.icon : faTrashAlt} style={this.props.style ? this.props.style : {color:'#f44336'}}/>
            </a>
        );
    }

}

export default Delete;
