import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import ModalImageView from './ModalImageView';

const apiURL = process.env.REACT_APP_API_URL;

const style = {
    thumbnail : {
        width:'60px', 
        display:'inline-block', 
        padding:'5px', 
        marginRight:'10px',
        cursor: 'pointer'
    }
}

class ImageList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            files : this.props.images,
            showImage: ''
        }
        this.onHideWindow = this.onHideWindow.bind(this);
    }

    onHideWindow () {
        this.setState({showImage:''});
    }

    render(){

        const {classes} = this.props;

        if (!this.state.files || this.state.files.length === 0){
            return ('-');
        }

        let modalImage = '';
        if (this.state.showImage !== ''){
            modalImage = (
                <ModalImageView image={this.state.showImage} 
                        open={true} 
                        onClose = {this.onHideWindow}
                        />
            );
        }

        return(
            this.state.files.map(item => {
                return(
                    <>
                        {modalImage}
                        <Paper className={classes.thumbnail} onClick={ () => { this.setState({showImage:item}) } }>
                            <img src={apiURL + '/' + item} style={{width:'50px', height:'75px'}} />
                        </Paper>
                    </>
                )
            })
        )

    }

}

export default withStyles(style)(ImageList);