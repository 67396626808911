/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {GLAccount} from 'service/finance';

//components
import Button from "components/CustomButtons/Button.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Table from "components/Table/Table.js";
import {formatAmount} from 'utils/format';
import Card from "components/Card/Card.js";
import ModalDeliveryView from 'components/Delivery/ModalDeliveryView';
import Link from '@material-ui/core/Link';
import ImageList from 'components/ImageList/ImageList';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
    ...styles,
    ...formStyles,
    ...customSelectStyle,
    ...buttonStyle,
    modalTitle:{
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
        color: "#555555",
        fontWeight: "900"
    },
    modal:{
        width:"90%",
        maxWidth:"90%",
        height: "90%",
        maxHeight: "90%"

    },
    darkerLabel:{
        color:"#878787"
    },
    errorLabel:{
        color: "#e53935"
    },
    modalFooter:{
        margin:"0 auto"
    },
    error:{
        color: "#e53935",
        fontSize: "15px",
        fontWeight: "300"
    },
    select:{
        marginTop:"32px"
    },
    windowToolbar:{
        position: 'absolute',
        top : '5px',
        right : '25px',
        textAlign: "right",
        fontSize:"22px",
        color:"#555555"
    },
    radioRootCustom :{
        paddingTop:'5px'
    },
    formControlCustom: {
        paddingTop: '5px'
    },
    depositRoot:{
        width: '100px',
        marginLeft: '7px'
    },
    alertText:{
        fontSize:"25px",
        lineHeight:"30px"
    },
    tableContainer: {
        width:"97%",
        margin:"0 auto",
        paddingTop: "15px"
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
}

function DisplayAmount( props ){
    const { amount, cost,...other} = props;

    let lAmount = parseFloat(amount);
    let displayAmount = '';
    let amountStyle = {};
    if (lAmount === 0){
        displayAmount = '-';
    }else{
        if (cost){
            amountStyle = {color:"red"};
        }
        displayAmount = 'Q. ' + formatAmount(lAmount);
    }

    return (
        <div style={amountStyle}> {displayAmount} </div> 
    );

}

class SettlementView extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            open:this.props.open,
            serialNo : 0,
            data : [],
            noData: false,
            showParcel: ''
        };

        this.closeModal = this.closeModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.viewParcel = this.viewParcel.bind(this);
        this.hideViewWindow = this.hideViewWindow.bind(this);
    }

    fetchData() {
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

        axios.get(apiURL + "/merchant/settlement/" + this.props.serialNo)
            .then( (res) => {
                this.setState({data : res.data, noData:false});
            }).catch( (error) =>{
                this.setState({data: [], noData:true});
                console.log(error);
            });
    }

    closeModal(){
        this.setState({open:false});
        this.props.onClose();
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.serialNo !== prevProps.serialNo){
            this.setState({...this.props});
        }
    }

    viewParcel(trackingNo){
        this.setState({showParcel:trackingNo});
    }

    hideViewWindow(){
        this.setState({showParcel:''});
    }

    render(){

        const { classes } = this.props;

        let dataRows = [];

        let no = 0;

        let totalTransfer = 0;

        for (let parcel of this.state.data){

            no++;

            //COD collected
            let totalCOD = 0;
            let codList = parcel.Details.filter( item => item.GLAccountCode === GLAccount.COD_TO_SETTLE)
            codList.forEach ( item => totalCOD += parseFloat(item.Debit));
            
            //Shipping charge
            let totalShipping = 0;
            let shippingList = parcel.Details.filter( 
                        item => item.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE && item.Reference === 'SHIPPING');
            shippingList.forEach (item => totalShipping += parseFloat(item.Credit))

            //Return charge
            let totalReturn = 0;
            let returnList = parcel.Details.filter( 
                        item => item.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE && item.Reference === 'RETURN');
            returnList.forEach (item => totalReturn += parseFloat(item.Credit))

            //COD charge
            let codCharge = 0;
            let codChargeList = parcel.Details.filter( item => item.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE && item.Reference === 'COD');
            codChargeList.forEach (item => codCharge += parseFloat(item.Credit))

            //Other charge
            let otherCharges = 0;
            let otherChargesList = parcel.Details.filter( item => item.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE && item.Reference === 'OTHER');
            otherChargesList.forEach (item => otherCharges += parseFloat(item.Credit))

            //Subsidizer
            let subsidy = 0;
            let subsidyList = parcel.Details.filter( item => item.GLAccountCode === GLAccount.PROVISION_FOR_AR);
            subsidyList.forEach (item => subsidy += parseFloat(item.Credit))


            //Transfer
            let transfer = 0;
            let transferList = parcel.Details.filter( item => item.GLAccountCode === GLAccount.BANK);
            transferList.forEach (item => transfer += parseFloat(item.Credit))

            dataRows.push ([
                    no,
                    <Link
                    component="button"
                    underline="always"
                    style={{fontSize:"14px", userSelect:"text", color:"purple"}}
                    onClick={() => {this.viewParcel(parcel.TrackingNo)}}>{parcel.TrackingNo}</Link>,
                    <DisplayAmount amount={totalCOD} />,
                    <DisplayAmount amount={totalShipping} cost={true} />,
                    <DisplayAmount amount={totalReturn} cost={true} />,
                    <DisplayAmount amount={codCharge} cost={true} />,
                    <DisplayAmount amount={otherCharges} cost={true} />,
                    <DisplayAmount amount={subsidy} cost={true} />,
                    <DisplayAmount amount={transfer} />
                ]
            )

            totalTransfer += transfer;
        }

        let modalParcel = '';

        if (this.state.showParcel !== ''){
            modalParcel =  (
                <ModalDeliveryView
                    open = {true}
                    trackingNo={this.state.showParcel}
                    onClose={this.hideViewWindow}  
                />
            );
        }

        

        if (this.state.data.length > 0){
            dataRows.push({
                color:"gray",
                data: [
                    '','','','','','','',
                    'Total',
                    <strong><DisplayAmount amount={totalTransfer}/></strong>,
                ]
            });
        }

        let customCellClasses = [classes.cell,classes.cell,classes.right,classes.right,classes.right,classes.right,
                                classes.right,classes.right,classes.right];
        let customClassesForCells = [0,1,2,3,4,5,6,7,8];

        let customHeadCellClasses = [
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader + " " + classes.right,
        ];
        let customHeadClassesForCells = [0,1,2,3,4,5,6,7,8];

        return(
                    
            <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        {modalParcel}
                        <Card>
                            <GridItem xs={12} sm={12} md={12}>
                                <h3>Detalles de liquidación</h3>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                No. de transferencia: {this.state.data.length > 0 && this.state.data[0].DocumentNo}
                                <br />
                                Descripción: {this.state.data.length > 0 && this.state.data[0].Description}
                                <br />
                                Archivos adjuntos: <br />
                                {this.state.data.length > 0 && <ImageList images={this.state.data[0].Files} />}
                            </GridItem>
                            <div className={classes.tableContainer}>
                                <Table
                                    striped
                                    showLoading
                                    noData = {this.state.noData}
                                    tableHead={[
                                        "No.",
                                        "Guía",
                                        <>COD<br/>recolectado</>,
                                        <>Costo<br/>envío</>,
                                        <>Costo<br/>devolución</>,
                                        <>Costo<br/>recolección COD</>,
                                        <>Otros<br/>cobros</>,
                                        <>Provisión para<br/>guías sin COD</>,
                                        <>Total<br/>transferido</>,
                                    ]}
                                    tableData={dataRows}

                                    customCellClasses={customCellClasses}
                                    customClassesForCells={customClassesForCells}

                                    customHeadCellClasses={customHeadCellClasses}
                                    customHeadClassesForCells={customHeadClassesForCells}

                                    />
                            </div>

                            <GridItem xs={12} sm={12} md={12} style={{textAlign:'center', marginTop:'10px', marginBottom:'10px'}}>
                                <Button color="primary"  onClick={()=>this.props.onClose()}>
                                    Atrás
                                </Button>
                            </GridItem>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }

}

export default withStyles(style)(SettlementView);