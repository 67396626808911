import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { faDollarSign, faBoxOpen, faCommentAltDollar, faTruck, faExclamationCircle, faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  codStatus,
  simpleBarChart,
  liquidaciones,
  parcels,
  issues
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");
const gt_flag = require("assets/img/flags/GT.png");

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div>
      {/* <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Paquetes entregados</p>
              <h3 className={classes.cardTitle}>
                49/50 <small></small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  More info
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue</p>
              <h3 className={classes.cardTitle}>GTQ 34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Alertas</p>
              <h3 className={classes.cardTitle}>5</h3>dashboardStyle
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Ver alertas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fa fa-arrow-circle-right" style={{fontSize:"30px", padding:"12px"}}/>
              </CardIcon>
              <p className={classes.cardCategory}>En ruta</p>
              <h3 className={classes.cardTitle}>40</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}

      <GridContainer>
      
        {/* Estado COD */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <FontAwesomeIcon icon={faDollarSign} style={{fontSize:"30px", marginLeft:"10px", marginRight:"10px"}}/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Estado COD (Quetzales)</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={codStatus.data}
                type="Pie"
                options={codStatus.options}
              />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <i className={"fas fa-circle "} style={{color:'#d17905'}} /> Liquidado
              <i className={"fas fa-circle "} style={{color:'#00bcd4'}} /> Flotando
              <i className={"fas fa-circle "} style={{color:'#f05b4f'}} /> Cobrado
              <i className={"fas fa-circle "} style={{color:'#f4c63d'}} /> En proceso de liquidación
            </CardFooter>
          </Card>
        </GridItem>

        {/* Estado Paquetes */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <FontAwesomeIcon icon={faBoxOpen} style={{fontSize:"30px", marginLeft:"2px", marginRight:"2px"}}/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Estado de Paquetes</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                  className = {classes.myBar}
                  data={simpleBarChart.data}
                  type="Bar"
                  options={simpleBarChart.options}
                  responsiveOptions={simpleBarChart.responsiveOptions}
                  listener={simpleBarChart.animation}
                />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <br></br>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {/* COD de los últimos 7 días */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <FontAwesomeIcon icon={faCommentAltDollar} style={{fontSize:'40px'}}/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Liquidaciones COD Últimos 7 Días</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                  className = {classes.myLiquidaciones}
                  data={liquidaciones.data}
                  type="Bar"
                  options={liquidaciones.options}
                  responsiveOptions={liquidaciones.responsiveOptions}
                  listener={liquidaciones.animation}
                />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <br></br>
            </CardFooter>
          </Card>
        </GridItem>

        {/* Volumen de envíos por día */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FontAwesomeIcon icon={faTruck} style={{fontSize:'40px'}}/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Volumen de Envíos por Día</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                  className = {classes.myParcels}
                  data={parcels.data}
                  type="Bar"
                  options={parcels.options}
                  responsiveOptions={parcels.responsiveOptions}
                  listener={parcels.animation}
                />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <br></br>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {/* Principales causas de excepciones */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} style={{fontSize:'40px'}}/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Principales Causas de Excepciones de Entrega</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={issues.data}
                type="Pie"
                options={issues.options}
              />
            </CardBody>
            <CardFooter stats >
              <i className={"fas fa-circle "} style={{color:'#00bcd4'}} /> Dirección equivocada
              <i className={"fas fa-circle "} style={{color:'#f05b4f'}} /> Destinatario no respondió
              <i className={"fas fa-circle "} style={{color:'#f4c63d'}} /> Daño mecánico del vehículo
              <i className={"fas fa-circle "} style={{color:'#d17905'}} /> Problemas en carreteras
              <i className={"fas fa-circle "} style={{color:'#453d3f'}} /> Clima inclemente 
              <i className={"fas fa-circle "} style={{color:'#59922b'}} /> Persona rechazó envío 
            </CardFooter>
          </Card>
        </GridItem>

        {/* GEO */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <FontAwesomeIcon icon={faMapMarkerAlt} style={{fontSize:'40px'}}/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Geografía de Entregas</h4>
            </CardHeader>
            <CardBody>
  
              <iframe style={{border:"none"}}  
                      src="https://www.google.com/maps/d/embed?mid=1Io1ENEsWXdRwnrGv7j308fA869qAeySa" width="700" height="382"></iframe>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Ventas por departamento
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={5}>
                  <Table
                    tableData={[
                      [
                        <img src={gt_flag} alt="ug_flag" key={"flag"} />,
                        "Guatemala",
                        "2.920",
                        "53.23%"
                      ],
                      [
                        <img src={gt_flag} alt="gt_flag" key={"flag"} />,
                        "Quetzaltenango",
                        "1.300",
                        "20.43%"
                      ],
                      [
                        <img src={gt_flag} alt="gt_flag" key={"flag"} />,
                        "San Marcos",
                        "760",
                        "10.35%"
                      ],
                      [
                        <img src={gt_flag} alt="gt_flag" key={"flag"} />,
                        "Huehuetenango",
                        "690",
                        "7.87%"
                      ],
                      [
                        <img src={gt_flag} alt="gt_flag" key={"flag"} />,
                        "Izabal",
                        "600",
                        "5.94%"
                      ],
                      [
                        <img src={gt_flag} alt="gt_flag" key={"flag"} />,
                        "Chiquimula",
                        "550",
                        "4.34%"
                      ]
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <VectorMap
                    map={"world_mill"}
                    backgroundColor="transparent"
                    zoomOnScroll={false}
                    containerStyle={{
                      width: "100%",
                      height: "280px"
                    }}
                    containerClassName="map"
                    regionStyle={{
                      initial: {
                        fill: "#e4e4e4",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0
                      }
                    }}
                    series={{
                      regions: [
                        {
                          values: mapData,
                          scale: ["#AAAAAA", "#444444"],
                          normalizeFunction: "polynomial"
                        }
                      ]
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer> */}
      
    </div>
  );
}
