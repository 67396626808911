import React from "react";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Open extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <a href="#" onClick={ (e) =>{e.preventDefault(); this.props.openFunction();}}>
            <FontAwesomeIcon icon={this.props.icon ? this.props.icon : faExternalLink} style={this.props.style ? this.props.style : {color:'#00cae3'}}/>
            </a>
        );
    }

}

export default Open;
