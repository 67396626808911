/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";


const apiURL = process.env.REACT_APP_API_URL;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const style = {
    ...styles,
    ...formStyles,
    ...customSelectStyle,
    ...buttonStyle,
    modalTitle:{
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
        color: "#555555",
        fontWeight: "900"
    },
    modal:{
        width: '80%',
        maxWidth:'80%',
        height: "90%",
        maxHeight: "90%"
    },
    darkerLabel:{
        color:"#878787"
    },
    errorLabel:{
        color: "#e53935"
    },
    modalFooter:{
        margin:"0 auto"
    },
    error:{
        color: "#e53935",
        fontSize: "15px",
        fontWeight: "300"
    },
    select:{
        marginTop:"32px"
    },
    windowToolbar:{
        position: 'absolute',
        top : '5px',
        right : '25px',
        textAlign: "right",
        fontSize:"22px",
        color:"#555555"
    },
    radioRootCustom :{
        paddingTop:'5px'
    },
    formControlCustom: {
        paddingTop: '5px'
    },
    depositRoot:{
        width: '100px',
        marginLeft: '7px'
    },
    alertText:{
        fontSize:"25px",
        lineHeight:"30px"
      }
}

class ModalImageView extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            open : this.props.open
        }
        this.closeModal = this.closeModal.bind(this);      
    }

    closeModal(){
        this.setState({open:false});
        this.props.onClose();
    }

    render(){

        const { classes } = this.props;
    
        return(

            <Dialog
                classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
                }}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeModal}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="form-dialog-title">Archivo adjunto</DialogTitle>

                <div className={classes.windowToolbar}>
                    <FontAwesomeIcon icon={faTimes} style={{cursor:"pointer"}} onClick={this.closeModal}/>
                </div>
                <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
                >
                   
                    <GridContainer>
                       
                        <GridItem  xs={12}>
                            <img src={apiURL + '/' + this.props.image} style={{width:'100%'}}/>
                        </GridItem>

                    </GridContainer>

                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    
                    <Button onClick={this.closeModal} color="danger" style={{marginLeft:'25px'}}>
                        Cerrar
                    </Button>
                                
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(style)(ModalImageView);