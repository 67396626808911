/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CircularProgress from '@material-ui/core/CircularProgress';

import Select from "@material-ui/core/Select";
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from "@material-ui/core/MenuItem";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');


const style = {
    ...styles,
    ...formStyles,
    modalTitle:{
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
        color: "#555555",
        fontWeight: "900"
    },
    modal:{
        width:"70%",
        maxWidth:"800px"
    },
    darkerLabel:{
        color:"#878787"
    },
    errorLabel:{
        color: "#e53935"
    },
    modalFooter:{
        margin:"0 auto"
    },
    error:{
        color: "#e53935",
        fontSize: "15px",
        fontWeight: "300"
    },
    select:{
        marginTop:"32px"
    }
}

class DeliveryEdit extends React.Component{

    constructor(props){
        super(props);

        this.closeModal = this.closeModal.bind(this);
        this.save = this.save.bind(this);

        this.state = {
            open:this.props.open,
            delivery: this.props.delivery,
            id: this.props.id,
            departamentos: [],
            municipios: [],
            saving: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

        //to set Select value = 0 (Por favor seleccione...)
        if (this.state.delivery.departamento.error){
            this.state.delivery.departamento.code = 0;
        }
        if (this.state.delivery.municipio.error){
            this.state.delivery.municipio.code = 0;
        }

        //To be used in validation
        this.stringFields = ['name', 'description', 'address'];
        this.amountFields = []; //['cod'];
        this.cellphoneFields = ['cellphone'];
        this.selectFields = ['departamento', 'municipio'];
        this.digits = 8;
    }

    closeModal(){
        this.setState({open:false});
        this.props.onClose();
    }

    save(){

        const allOk = this.validateDelivery();
        if (!allOk){
            return;
        }

        this.setState({saving:true}, () => {
            this.props.onSave(this.state.delivery);
            this.setState({open:false});
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id){
            this.setState({...this.props});
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

        axios.get(apiURL + "/departamentos")
        .then( (res) => {
            this.setState({departamentos:res.data});
        }).catch( (error) =>{
            console.log(error);
        });

        if (!this.state.delivery.departamento.error)
                this.getMunicipios(this.state.delivery.departamento.code);

    }

    handleChange(e){
        const fieldName = e.target.id.replace('field_','');
        let d = this.state.delivery;
        
        if (fieldName === 'departamento' || fieldName === 'municipio'){
            if (d[fieldName].code !== e.target.value){
                d[fieldName].code = e.target.value;
                if (fieldName === 'departamento'){ 
                    d[fieldName].value = this.state.departamentos.find( (item) => item.code === e.target.value ).name;
                    d.municipio = {value:'', code:0, error:'Por favor selecciona un municipio.'}; //then, propagate to children and clean municipio
                    this.getMunicipios(e.target.value);
                }else{
                    d[fieldName].value = this.state.municipios.find( (item) => item.code === e.target.value ).name;
                }
            }
        }else{
            d[fieldName].value = e.target.value;
        }

        this.setState({delivery:d});
    }

    handleOnBlur(e){
        const fieldName = e.target.id.replace('field_','');
        this.validate(fieldName, e.target.value);
    }

    getMunicipios(departamento){
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        axios.get(apiURL + "/municipios?departamento=" + departamento)
        .then( (res) => {
            this.setState({municipios:res.data});
        }).catch ( (error) =>{
            console.log(error);
        });
    }

    validate(field, value){
        
        let d = this.state.delivery;
        if (this.stringFields.includes(field)){
            if (value.length < 1 || value.length > 100){
                d[field].error = 'Contenido está incorrecto. Debe tener mínimo 1 caracteres.';
            }else{
                delete d[field].error;
            }
        }else if (this.amountFields.includes(field)){
            if (isNaN(value)){
                d[field].error = 'Monto COD no es válido.';
            }else{
                if (value <= 0) d[field].error = 'Monto COD debe ser mayor a cero.';
                else delete d[field].error;
            }
        }else if (this.cellphoneFields.includes(field)){
            let n = value.replace(/\D/g,'');
            if (isNaN(n)){
                d[field].error = 'El número de celular es requerido.';
            }else{
                if (n.length !== this.digits)  d[field].error = 'El número de celular debe tener ' + this.digits + ' dígitos.';
                else delete d[field].error;
            }
        }else if (this.selectFields.includes(field)){
            const label = field.charAt(0).toUpperCase() + field.slice(1);
            if (value.length === 0 || value === 0 || !d[field].hasOwnProperty('code')){
                d[field].error = label + ' no ha sido seleccionado.';
            }else  if (d[field].hasOwnProperty('code') && ( d[field].code === 0 || d[field].code === '0' ) ){
                d[field].error = label + ' no ha sido seleccionado.';
            } else delete d[field].error;
        }
        
        this.setState({delivery:d});
        return !d[field].hasOwnProperty('error');

    }

    validateDelivery(){
        let allFields = this.stringFields.concat(this.amountFields, this.cellphoneFields, this.selectFields);
        let d = this.state.delivery;
        var allOk = true;
        
        for (let field of allFields){
           allOk = this.validate(field, d[field].value) && allOk;
        }
        
        return allOk;
    }

    render(){

        const { classes } = this.props;
        const delivery = this.state.delivery;

        var fields = [
            {type:'input', code:'name', label:'Nombre', placeholder:'Nombre de quien recibe'},
            {type:'input', code:'packageno', label:'Factura', placeholder:'Número de órden o factura'},
            {type:'input', code:'description', label:'Contenido', placeholder:'Descripción del contenido del paquete'},
            {type:'input', code:'cod', label:'Monto COD', placeholder:'Monto por cobrar al entregar el paquete'},
            {type:'input', code:'cellphone', label:'No. Celular', placeholder:'Celular de quien recibe'},
            {type:'input', code:'address', label:'Dirección', placeholder:'Dirección de entrega'},
            {type:'select', code:'departamento', label:'Departamento', placeholder:'Departamento', values:this.state.departamentos},
            {type:'select', code:'municipio', label:'Municipio', placeholder:'Municipio', values:this.state.municipios},
            {type:'input', code:'referencias', label:'Instrucciones', placeholder:'Instrucciones adicionales sobre el punto de entrega'},
        ]

        var form = [];
        for (let f of fields){
            let input = '';
            if (f.type === 'input'){
                input = (
                    <GridContainer key = {f.code}>
                        <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal + " "+classes.darkerLabel}>
                                {f.label}
                            </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                            <CustomInput
                                id={"field_"+f.code} formControlProps={{fullWidth: true, onChange : this.handleChange, onBlur: this.handleOnBlur}}
                                inputProps={{
                                    placeholder: f.placeholder,
                                    value: delivery[f.code].value
                                }}
                                helperText= {delivery[f.code].error}
                                error = {delivery[f.code].error ? true:false}
                            />
                        </GridItem>
                    </GridContainer>
                );
            }else if (f.type === 'select'){

                let options = [];
                for (let v of f.values){
                    options.push(
                        <MenuItem
                            classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}}
                            value={v.code} key = {v.code}
                        >
                            {v.name}
                        </MenuItem>
                    );
                }

                input = (
                    <GridContainer key = {f.code}>
                        <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal + " " + classes.darkerLabel}>
                            {f.label}
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                            <Select
                                fullWidth={true}
                                MenuProps={{className: classes.selectMenu}}
                                classes={{select: classes.select}}
                                value={delivery[f.code].code}
                                onChange={(e)=>{ e.target.id = 'field_'+f.code; this.handleChange(e);}}
                                onBlur={(e)=>{ e.target.id = 'field_'+f.code; this.handleOnBlur(e);}}
                                inputProps={{name: "field_"+f.code, id: "field_"+f.code}}
                                error = {delivery[f.code].error ? true:false}
                            >
                                <MenuItem disabled classes={{root: classes.selectMenuItem}} value = {0} key = {0}>
                                    Selecciona un {f.label.toLowerCase()}
                                </MenuItem>
                                {options}
                            </Select>
                            {delivery[f.code].error ? <FormHelperText className={classes.error}>{delivery[f.code].error}</FormHelperText> : ''}
                        </GridItem>
                    </GridContainer>
                )
            }
            form.push (input);
        }

        let saveBtnContent = 'Guardar';
        if (this.state.saving){
            saveBtnContent = (
                <div>
                    <CircularProgress size={20} style={{marginLeft:'5px', color:"#ffffff"}}/>
                    Guardando...
                </div>
            );
        }
        return(
            <Dialog
                classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
                }}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeModal}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
                >
                    <div className={classes.modalTitle}>
                        {this.state.id === -1 ? 'Agregar envío': 'Editar envío No. ' + (this.state.id + 1)}
                    </div>
                </DialogTitle>
                <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
                >

                    <form>
                        {form}
                    </form>

                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                <Button color="info" onClick={this.save}>{saveBtnContent}</Button>
                <Button
                    onClick={this.closeModal}
                    color="danger"
                >
                    Cerrar
                </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(style)(DeliveryEdit);