import React from "react";

import { faSort, faSortUp, faSortDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from '@material-ui/core/Link';

export default function SortColumn( props ){
    const { code, sorting, direction, name, onClick, ...other} = props;

    let icon = '';
    if (sorting === code){
        if (direction > 0){
            icon = <FontAwesomeIcon icon={faSortUp} style={{fontSize:"20px", marginLeft:"5px", color:"#7c7c7c"}}/>
        }else{
            icon = <FontAwesomeIcon icon={faSortDown} style={{fontSize:"20px", marginLeft:"5px", color:"#7c7c7c"}}/>
        }
    }else{
        icon = <FontAwesomeIcon icon={faSort} style={{fontSize:"20px", marginLeft:"5px", color:"#7c7c7c"}}/>
    }

    return (
        <>
            <Link style={{color:"black", textDecoration:"none", cursor:"pointer"}} onClick={()=> onClick(code)}>
                {name}
                {icon}
            </Link>
        </>
    );
}
