import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { Redirect } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import logo from "assets/img/logo.png";

import auth from "service/authentication";

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const useStyles = makeStyles(styles);

export default function LoginPage() {
 
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  var [loggedIn, setLoggedIn] = React.useState(0);
  var [logging, setLogging] = React.useState(false);
  setTimeout(function() {setCardAnimation("");}, 700);
  const classes = useStyles();

  const formOk = (email, pass) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())){
      setLoggedIn(-2);
      return false;
    }

    if (pass.length<=5){
      setLoggedIn(-3);
      return false;
    }
    return true;
  }

  const doLogin = () => {
    //localStorage.setItem("token","hello world!");
    let email = document.getElementById('email');
    let pass = document.getElementById('password');
    
    if (!formOk(email.value, pass.value)){
      setLogging(false);
      return;
    }

    setLogging(true);

    const data = {
      email : email.value,
      password: pass.value
    }
    axios.post(apiURL+"/users/login/", data)
    .then( (res) => {
      auth.saveToken(res.data.token)
      setLogging(false);
      setLoggedIn(1);
    }).catch((error) =>{
      setLogging(false);
      setLoggedIn(-1);
    });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter'){
      doLogin();
    }
  }

  return (
    auth.isAuthenticated() ? (
      window.location.href = '/'
    ):
    (
    <div className={classes.container} style={{marginTop:'150px'}}>
      <GridContainer justify="center" align="center">
        <GridItem xs={12} sm={6} md={4}>
          <img src={logo} style={{width:'200px', marginBottom:'20px'}}></img>
          <form onSubmit={doLogin}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                {/* <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-twitter",
                    "fab fa-google-plus"
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div> */}
              </CardHeader>
              <CardBody>
                {/* <CustomInput
                  labelText="First Name.."
                  id="firstname"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                /> */}
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                    onKeyPress : handleKeyDown
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                    onKeyPress : handleKeyDown
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}

                />
                {(loggedIn === -1) ? <div style={{color:'red'}}>Error, credenciales incorrectas</div>:''}
                {(loggedIn === -2) ? <div style={{color:'red'}}>Por favor, escribe un correo electrónico válido</div>:''}
                {(loggedIn === -3) ? <div style={{color:'red'}}>Por favor, escribe tu contraseña</div>:''}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {logging ? ('Ingresando...')
                  : (<Button color="rose" simple size="lg" block onClick={doLogin}>Entrar</Button>)
                }
                
              </CardFooter>
            </Card>
          </form>
          ChopinTrack es una marca de Chopinmol.com<br></br>
          All Rights Reserved<br></br>{new Date().getFullYear()}
        </GridItem>
      </GridContainer>
    </div>
    )
  );
}
