const saveToken = (jwt) => {
    localStorage.setItem('jwt', jwt);
}

const getUser = () => {
    let id = '';
    let role = '';
    let userName = '';
    if (localStorage.getItem('jwt') !== null){
        var base64Payload = localStorage.getItem('jwt').split('.')[1];
        var payload = JSON.parse(decodeURIComponent(atob(base64Payload)));
        id = payload.hasOwnProperty('id') ? payload.id : '';
        role = payload.hasOwnProperty('role') ? payload.role : process.env.REACT_APP_ROLE;
        userName = payload.hasOwnProperty('userName') ? payload.userName : 'Usuario'
    }else{
        id = '';
        role = '';
        userName = '';
    }
    
    return {id, role, userName};
}

const isAuthenticated = () => {
    if (localStorage.getItem('jwt') === null) return false;
    return true;
}

module.exports = {
    getUser,
    isAuthenticated,
    saveToken
}