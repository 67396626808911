import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import Link from '@material-ui/core/Link';
import TrailTable from "components/TrailTable/TrailTable.js";
import TrackingStatus from "components/TrackingStatus/TrackingStatus.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Box from '@material-ui/core/Box';
import { faPrint, faAt, faSms, faShareAlt, faBan, faBellExclamation, faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from "components/CustomButtons/Button.js";
import ButtonCore from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames";
import purple from '@material-ui/core/colors/purple';
import ReactToPrint from 'react-to-print';
import {formatAmount} from 'utils/format';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import {createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import auth from "service/authentication";
import { Toolbar } from '@material-ui/core';
import ParcelService from '../ParcelService/ParcelService';
import ParcelCarrier from '../ParcelCarrier/ParcelCarrier';
import ParcelFinance from '../ParcelFinance/ParcelFinance';
import ParcelChange from '../ParcelChange/ParcelChange';
import TextField from '@material-ui/core/TextField';


const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');
const fileDownload = require('js-file-download');

const theme = createMuiTheme({
    palette: {
        primary: purple,
        showCalendar: false
    }
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const style = {
    ...buttonStyle,
    root: {
        flexGrow: 1,
        width: '100%',
        margin:'0 auto',
        backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
        display:'flex',
        justifyContent: 'center'
    },
    card: {
        display: "inline-block",
        position: "relative",
        width: "100%",
        margin: "25px 0",
        boxShadow: "0 1px 4px 0 rgba(0,0,0, 0.14)",
        borderRadius: "6px",
        color: "rgba(0,0,0, 0.87)",
        background: "white",
        transition: "all 300ms linear",
        minHeight: "410px",
        paddingBottom: "40px",
        display:'flex', 
        flexDirection:"column",
        justifyContent: "center"
        },
    viewContainer: {
        width:"97%",
        margin:"0 auto",
    },
    loading:{
        fontSize:"18px", 
        marginTop:"8px", 
        marginLeft:"0px", 
        textAlign:"center"
    },
    alertText:{
      fontSize:"25px",
      lineHeight:"30px"
    },
    canceled:{
        color:'#7b7b7b'
    },
    notFoundPanel:{
        textAlign:'left', 
        fontSize:'20px', 
        borderLeft:'1px dotted #dadada', 
        display:'flex', 
        flexDirection:"column",
        justifyContent: "center"
    },
    codRoot:{
        width: '100px',
        marginRight: '7px'
    },
    hidden: {
        display: 'none'
    }
}

class DeliveryView extends React.Component{

    constructor(props){
        super(props);
        let trackingNo = props.trackingNo ? props.trackingNo : '9539-1GU-2EA8F';
        this.state = {
            parcel:undefined,
            trackingNo: trackingNo,
            showCancelWarning: false,
            canceling: false,
            notFound: false,
            addAction : false,
            openTab: 0,
            editCOD: false,
            cod:0,
            changeReason: '',
            askSave: false,
            saving: false,
            saveError: ''
        };
    
        this.cancelParcel = this.cancelParcel.bind(this);
        this.deliveryViewport = React.createRef();
        this.pullParcelData = this.pullParcelData.bind(this);

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleTabChange = this.handleTabChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.saveCOD = this.saveCOD.bind(this);
        this.tryToSave = this.tryToSave.bind(this);
    }

    componentDidMount() {
        this.pullParcelData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.trackingNo !== prevProps.trackingNo){
            this.setState({trackingNo : this.props.trackingNo}, () => {
                this.pullParcelData();
            });
        }
    }

    pullParcelData() {
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        axios.get(apiURL + "/merchant/parcel/"+this.state.trackingNo)
            .then( (res) => {
                this.setState({parcel:res.data, notFound: false, cod: res.data.cod});
            }).catch( (error) =>{
                if(error.response && error.response.status && error.response.status === 404){
                    this.setState({notFound:true});
                }
            });
    }

    handleTabChange (event, newValue) {
      
        this.setState(
          { 
            openTab: newValue
          }
        );
    }

    cancelParcel(){

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

        this.setState({canceling:true});

        axios.delete(apiURL + "/merchant/parcel/"+this.props.trackingNo)
        .then( (res) => {
            this.setState({showCancelWarning: false, canceling:false});
            this.pullParcelData();
        }).catch( (error) =>{
            console.log(error);
        });

    }

    handleClick (event) {
        this.setState({
            anchorEl : event.currentTarget
        })
    };
    
    handleClose (mode) {
        if (mode == 'sticker'){

            let endpoint = '/merchant/parcels/print';
            let baseName = 'Guías';

            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            axios.post(apiURL+endpoint, [{trackingno:{value:this.state.trackingNo}}], {responseType: 'blob'})
            .then( (res) => {
                var filename = baseName + "-" + new Date().getTime() + ".pdf";      
                fileDownload(res.data, filename);
            }).catch((error) =>{
                console.log(error);
            });

        }

        this.setState({
            anchorEl : null
        })
    };

    onInputChange(field, event){
        let o = new Object();
        o[field] = event.target.value;
        this.setState(o);
    }

    tryToSave () {
        if (this.state.parcel.cod === this.state.cod){
            this.setState({saveError:'Monto COD es el mismo de antes'});
            return;
        }
        if (this.state.changeReason.length < 3){
            this.setState({saveError:'Escribe el motivo del cambio'});
            return;
        }
        this.setState({saveError:'', askSave:true});
    }

    saveCOD() {
        this.setState({saving:true});

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        axios.put(apiURL+'/backoffice/parcel/'+this.state.parcel.parcelId, 
                {parcelId: this.state.parcel.parcelId, fields: {cod: { value: this.state.cod, reason:this.state.changeReason }}})
        .then( (res) => {
            let parcel = this.state.parcel;
            parcel.cod = this.state.cod;
            this.setState({saving:false, askSave:false, changeReason:'', saveError:'', editCOD:false, parcel});
        }).catch((error) =>{
            let msg = 'No fue posible guardar la información';
            if (error.response && error.response.data && error.response.data.error){
                msg = error.response.data.error;
            }
            this.setState({saving:false, askSave:false, saveError : msg});
            console.log(error);
        });
    }

    render(){

        const { classes } = this.props;
        const parcel = this.state.parcel;

        const handleTabChange = this.handleTabChange;
        const openTab = this.state.openTab;

        if (this.state.notFound){
            return (
                <Card className={classes.card}>
                    <div className={classes.viewContainer}>
                        <GridContainer justify="center" style={{marginTop:"50px"}}>
                            <GridItem xs={5} style={{textAlign:'right'}}>
                                <FontAwesomeIcon icon={faBellExclamation} size="10x" style={{color:'#ff9800'}}/>
                            </GridItem>
                            <GridItem xs={7} className={classes.notFoundPanel}>
                                <p><strong>{this.state.trackingNo}</strong></p>
                                <p>Guía no encontrada</p>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Card>
            )
        }

        if (typeof parcel === 'undefined'){
            return (
                <Card className={classes.card}>
                    <div className={classes.viewContainer}>
                        <GridContainer justify="center" style={{marginTop:"15px"}}>
                            <GridItem xs={12}>
                                <p className={classes.loading}>
                                    Cargando...<CircularProgress size={30} style={{color:"#a72abd", marginLeft:"7px"}}/>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Card>
            )
        }

        let cancelWarning = '';
        if (this.state.showCancelWarning){
            let text = this.state.canceling ? 
                        ( <div>Cancelando...  <CircularProgress size={30} style={{color:"#a72abd"}}/> </div>) 
                        : 'Seguro que deseas cancelar éste envío?';
            cancelWarning = (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-200px", width:"40%"  }}
                title="Cancelar envío"
                onConfirm={() => this.cancelParcel()}
                onCancel={() => this.setState({showCancelWarning:false})}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Sí, cancelarlo"
                cancelBtnText="Mantenerlo activo"
                showCancel
                reverseButtons={false}
                disabled = {this.state.canceling}
              >
                <div className={classes.alertText}>{text}</div>
              </SweetAlert>
            )
        }

        const containerClass = classes.viewContainer +" " +
        cx({
          [classes.canceled]: parcel.statusCode === 4
        });


        let codBlock = (
            'Q. ' + formatAmount(parcel.cod)
        );

        if (auth.getUser().role === 'backoffice'){
            if (!this.state.editCOD){
                codBlock = (
                    <>
                        Q. {formatAmount(parcel.cod)}
                        <Link style={{cursor:'pointer', marginLeft:'10px'}} onClick={ () => {this.setState({saveError:'', editCOD:true})} }>
                            <FontAwesomeIcon icon={faEdit} />
                        </Link>
                    </>
                )
            }else{
                codBlock = (
                    <div style={{textAlign:'center'}}>

                        <TextField  value={this.state.cod} label="COD" onChange={ (event) => this.onInputChange('cod', event) } 
                            classes = {{root: classes.codRoot}} inputProps ={ {style: {textAlign:'right'}} } />
                        <TextField  value={this.state.changeReason} 
                                    label='Motivo  del cambio' onChange={ (event) => this.onInputChange ('changeReason', event) }/>
                        <br />
                        {this.state.saveError.length > 0 && ( <div style={{color:'red'}}> {this.state.saveError} <br /></div>) }
                        <Button simple color="primary" onClick={() => { this.tryToSave() }} >
                            Guardar
                        </Button>
                        <Button simple color="danger" onClick={()=>{this.setState({editCOD:false})}}>
                            Cancelar
                        </Button>
                       
                    </div>
                )
            }
        }
        
        let popup = '';
        if (this.state.askSave){
            let text = this.state.saving ? 
                    ( <div>Guardando...  <CircularProgress size={30} style={{color:"#a72abd"}}/> </div>) 
                    : `Seguro que deseas cambiar el monto COD de ${formatAmount(this.state.parcel.cod)} a ${formatAmount(this.state.cod)} ?`;

            popup = (
                <SweetAlert
                    style={{ display: "block", marginTop: "-200px", width:"40%"  }}
                    title="Cambiar Monto COD"
                    onConfirm={ e => this.saveCOD()}
                    onCancel={() => this.setState({askSave:false})}
                    confirmBtnCssClass={classes.button + " " + classes.success + (this.state.saving ? " " + classes.hidden : '')}
                    cancelBtnCssClass={classes.button + " " + classes.danger + (this.state.saving ? " " + classes.hidden : '') }
                    confirmBtnText="Guardar"
                    cancelBtnText="Cancelar"
                    showCancel
                    reverseButtons={true}
                    disabled = {this.state.deleting}
                    closeOnClickOutside = {false}
                >
                    <div className={classes.alertText}>{text}</div>
                </SweetAlert>
            )
        }
        
        return(
            <Card className={classes.card} ref={this.deliveryViewport}>
                <div className={containerClass}>
                    {cancelWarning}
                    {popup}
                    <GridContainer justify="center" style={{marginTop:"15px"}}>
                        <GridItem xs={4}>
                            <p style={{fontSize:"20px", marginTop:"12px", marginLeft:"0px", fontWeight:"900"}}>
                                No. de guía: {parcel.trackingNo}
                            </p>
                            <p style={{marginTop:"-10px"}}>
                                {parcel.merchant}
                            </p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p style={{fontSize:"18px", marginTop:"0px"}}>
                                Compartir por
                                <Button justIcon round color="white" style={{marginLeft:"10px"}}
                                        onClick={() => {alert("Compartir guía");}}
                                >
                                    <i className={"fab fa-whatsapp "} style={{color:'#25D366'}} />
                                </Button>
                                <Button justIcon round color="white" style={{marginLeft:"10px"}}
                                        onClick={() => {alert("Compartir guía");}}
                                >
                                    <i className={"fab fa-facebook-messenger "} style={{color:'#0078FF'}} />
                                </Button>
                                <Button justIcon round color="white" style={{marginLeft:"10px"}}
                                        onClick={() => {alert("Compartir guía");}}
                                >
                                    <FontAwesomeIcon icon={faAt} style={{fontSize:"20px", marginLeft:"2px", color:"#f44336"}}/>
                                </Button>
                                <Button justIcon round color="white" style={{marginLeft:"8px"}}
                                        onClick={() => {alert("Compartir guía");}}
                                >
                                    <FontAwesomeIcon icon={faSms} style={{fontSize:"20px", marginLeft:"2px",color:"#ff9800"}}/>
                                </Button>
                                <Button justIcon round color="white" style={{marginLeft:"8px"}}
                                        onClick={() => {alert("Compartir guía");}}
                                >
                                    <FontAwesomeIcon icon={faShareAlt} style={{fontSize:"20px", color:"#555555"}}/>
                                </Button>
                            </p>
                        </GridItem>
                        <GridItem xs={2} style={{textAlign:'right'}}>
                            <Button aria-controls="simple-menu" simple aria-haspopup="true" onClick={this.handleClick} style={{color:"#00cae3"}}>
                              <FontAwesomeIcon icon={faPrint} style={{fontSize:"20px", marginLeft:"5px"}}/> Imprimir
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={() => {this.handleClose('sticker')}}>Imprimir sticker</MenuItem>
                                <MenuItem onClick={() => {this.handleClose('trail')}}>
                                    <ReactToPrint
                                        trigger={() =>  <div>Imprimir guía e historial de estados</div>}
                                        content={() => this}
                                        />
                                </MenuItem>
                            </Menu>                          


                            {/* <CustomDropdown
                                    buttonIcon={Print}
                                    buttonProps={{
                                    round: true,
                                    style: { marginBottom: "0", marginLeft:"20px", color:"purple" },
                                    color: "white",
                                    justIcon : true
                                    }}
                                    dropdownList={[
                                        "Imprimir sticker",
                                       
                                    ]}
                                /> */}
                               
                            
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{marginTop:'20px'}}>
                        <GridItem xs={6}>
                            <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                    {/* <tr>
                                        <td style={{fontWeight:"900", width:"30%"}}>No. de guía</td>
                                        <td style={{paddingLeft:"20px"}}>{parcel.trackingNo}</td>
                                    </tr> */}
                                    <tr>
                                        <td style={{fontWeight:"900", width:"130px"}}>Fecha de envío</td><td style={{paddingLeft:"20px"}}>
                                            {parcel.statusCode === 4 ? 'Envío cancelado' : parcel.fechaEnvio ? parcel.fechaEnvio : 'Recolección pendiente'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"900"}}>Fecha de entrega</td><td style={{paddingLeft:"20px"}}>
                                            {parcel.fechaEntrega ? parcel.fechaEntrega : '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"900"}}>Estado</td><td style={{paddingLeft:"20px"}}>
                                            <TrackingStatus status={parcel.statusCode} statusDescription={parcel.status}/>
                                        </td>
                                    </tr>
                                    <tr><td style={{fontWeight:"900"}}>Firma de recibido</td><td style={{paddingLeft:"20px"}}>
                                    <Link
                                        component="button"
                                        underline="always"
                                        style={{color:"#00cae3",fontSize:"14px"}}
                                        onClick={() => {
                                        alert("Mostrar firma del usuario");
                                        }}>Ver Firma</Link>
                                    </td></tr>
                                    <tr><td style={{fontWeight:"900"}}>Contenido</td><td style={{paddingLeft:"20px"}}>{parcel.description}</td></tr>
                                    <tr style={{verticalAlign:'top'}}>
                                        <td style={{fontWeight:"900"}}>Monto COD</td>
                                        <td style={{paddingLeft:"20px"}}>
                                           {codBlock}
                                        </td>
                                        </tr>
                                </tbody>
                            </table>
                        </GridItem>
                        <GridItem xs={6}>
                        <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr style={{verticalAlign:'top'}}>
                                        <td style={{fontWeight:"900"}}>Detalles de entrega</td>
                                        <td style={{paddingLeft:"20px"}}>
                                            {parcel.recipient}<br></br>
                                            {parcel.address}<br></br>
                                            {parcel.municipio}, {parcel.departamento}<br></br>
                                            {parcel.references}<br></br>
                                            Tel: {parcel.contact}
                                        </td>
                                    </tr>
                                    {/* <tr style={{verticalAlign:'top'}}>
                                        <td style={{fontWeight:"900", paddingTop:"20px"}}>Piloto</td>
                                        <td style={{paddingLeft:"20px", paddingTop:"20px"}}>
                                            Juan Pérez<br></br>
                                            +502 5555-5555
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </GridItem>
                        {/* <GridItem xs={4}>
                            <p style={{fontWeight:"900"}}>
                                Ruta de entrega
                            </p>
                            <img src={mapImage} alt="..." />
                        </GridItem> */}
                    </GridContainer>
                    <GridContainer style={{marginTop:"15px"}}>
                        <GridItem xs={12} style={{textAlign:'center'}}>
                            { (parcel.statusCode === 0 && auth.getUser().role === 'merchant' ) ? (
                                <p style={{textAlign:'center', paddingBottom:'10px', borderBottom:'1px dotted #dadada'}}>
                                    <ButtonCore color="secondary" variant="outlined" danger 
                                            style = {{textTransform:'none'}}
                                            onClick={ () => this.setState({showCancelWarning:true})}>
                                        <FontAwesomeIcon icon={faBan} style={{fontSize:"20px", marginRight:"5px", color:"#f44336"}}/>
                                        Cancelar envío
                                    </ButtonCore>
                                </p>
                            ) : <div style={{borderBottom:'1px dotted #dadada'}} />}


                            { (auth.getUser().role === 'backoffice') ? (

                                <MuiThemeProvider theme={theme}>
                                    
                                    <Tabs
                                    value={openTab}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    classes={{flexContainer:classes.flexContainer}}
                                    style={{flexGrow:'1'}}
                                    aria-label="scrollable force tabs example"
                                    >
                                        <Tab label="Tracking" value={0} {...a11yProps(0)} />
                                        <Tab label="Cambios" value={1} {...a11yProps(1)} />
                                        <Tab label="Transportista" value={2} {...a11yProps(2)} />
                                        <Tab label="Servicios" value={3} {...a11yProps(3)} />
                                        <Tab label="Finanzas" value={4} {...a11yProps(4)} />
                                    </Tabs>
                                        
                                    <TabPanel value={openTab} index={0} key={0}>
                                        <TrailTable trail={parcel.trail} parcelId={parcel.parcelId} 
                                            trackingNo={parcel.trackingNo} status={parcel.statusCode}
                                            onSave = {this.pullParcelData}
                                            />
                                    </TabPanel>
                                    <TabPanel value={openTab} index={1} key={1}>
                                        <ParcelChange parcelId = {parcel.parcelId}/>
                                    </TabPanel>
                                    <TabPanel value={openTab} index={2} key={2}>
                                        <ParcelCarrier parcel = {parcel}/>
                                    </TabPanel>
                                    <TabPanel value={openTab} index={3} key={3}>
                                        <ParcelService parcelId = {parcel.parcelId} />
                                    </TabPanel>
                                    <TabPanel value={openTab} index={4} key={4}>
                                        <ParcelFinance parcelId = {parcel.parcelId} cod={parcel.cod}/>
                                    </TabPanel>

                                </MuiThemeProvider>

                            ) : (
                                <TrailTable trail={parcel.trail} parcelId={parcel.parcelId} 
                                        trackingNo={parcel.trackingNo} status={parcel.statusCode}
                                        onSave = {this.pullParcelData}
                                        />
                            ) }
                            
                        </GridItem>
                    </GridContainer>
                </div>
            </Card>
        )
    }
}

export default withStyles(style)(DeliveryView);