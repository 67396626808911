
import Dashboard from "views/Dashboard/Dashboard.js";
import AdminDashboard from "views/AdminDashboard/AdminDashboard.js";
import Wizard from "views/Forms/Wizard.js";
import TrackingTableContainer from "components/TrackingTable/TrackingTableContainer";
import ViewShipment from "views/Shipments/ViewShipment.js";
import Calendar from "views/Calendar/Calendar.js";
import MerchantList from "views/Merchant/MerchantList.js";
import FleetConfig from "views/Fleet/FleetConfig.js";
import CloseParcels from "components/CloseParcels/CloseParcels";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
//font awesome  icons
import { faHome, faSearch, faBoxOpen, faPen, faAnalytics, faWrench, faShippingFast, faSackDollar } from "@fortawesome/pro-light-svg-icons";
import DraftShipments from "components/Shipments/DraftShipments";
import MerchantSettlement from "components/MerchantSettlement/MerchantSettlement";
import SettlementList from "components/MerchantSettlement/SettlementList";
import auth from "service/authentication";

var role = auth.isAuthenticated() ? auth.getUser().role : 'guest';

var dashRoutes;

if (role === 'merchant'){

  dashRoutes = [
    {
      path: "/dashboard",
      name: "Home",
      icon: DashboardIcon,
      faIcon: faHome,
      component: Dashboard,
      layout: "/admin"
    },
    {
      path: "/e-track",
      name: "Rastrear Envíos",
      icon: DashboardIcon,
      faIcon: faSearch,
      component: TrackingTableContainer,
     // component: TrackingTableView,
      layout: "/admin"
    },
    {
      icon: DashboardIcon,
      collapse: true,
      name: "Programar envíos",
      faIcon: faBoxOpen,
      state: "pageCollapse",
      views: [
        {
          path: "/shipment-wizard",
          name: "Generar envíos",
          faIcon: faBoxOpen,
          component: Wizard,
          layout: "/admin",
          child: true
        },
        {
          path: "/shipment-wip",
          name: "Ver envíos en borrador",
          faIcon: faBoxOpen,
          component: DraftShipments,
          layout: "/admin",
          child: true
         },
        // {
        //   path: "/shipment-add",
        //   name: "Agregar un envío más",
        //   faIcon: faBoxOpen,
        //   component: Wizard,
        //   layout: "/admin",
        //   child: true
        // }
      ]
    },
    {
      path: "/viewshipment",
      name: "Ver Envío",
      icon: DashboardIcon,
      faIcon: faPen,
      component: ViewShipment,
      layout: "/admin",
      redirect: true
    },
    {
      path: "/settlement",
      name: "Liquidaciones",
      icon: DashboardIcon,
      faIcon: faAnalytics,
      component: SettlementList,
      layout: "/admin"
    }
  ];

}else if (role === 'backoffice'){

  dashRoutes = [
    {
      path: "/dashboard",
      name: "Home",
      icon: DashboardIcon,
      faIcon: faHome,
      component: AdminDashboard,
      layout: "/admin"
    },
    {
      name: "Gestión de envíos",
      icon: DashboardIcon,
      collapse: true,
      faIcon: faShippingFast,
      state: "servicesCollapse",
      views: [
        // {
        //   path: "/pickup-agenda",
        //   name: "Agenda de recolección",
        //   faIcon: faBoxOpen,
        //   component: Calendar,
        //   layout: "/admin",
        //   child: true
        // },
        {
          path: "/shipments",
          name: "Manejo de guías",
          faIcon: faBoxOpen,
          component: TrackingTableContainer,
          layout: "/admin",
          child: true
        },
        {
          path: "/close",
          name: "Cerrar guías",
          faIcon: faBoxOpen,
          component: CloseParcels,
          layout: "/admin",
          child: true
        }
      ]
    },
    {
      name: "Liquidaciones",
      icon: DashboardIcon,
      collapse: true,
      faIcon: faSackDollar,
      state: "settlementCollapse",
      views: [
        {
          path: "/settlement-wizard",
          name: "Nueva liquidación",
          faIcon: faBoxOpen,
          component: MerchantSettlement,
          layout: "/admin",
          child: true
        },
        {
          path: "/settlement-history",
          name: "Historial",
          faIcon: faBoxOpen,
          component: SettlementList,
          layout: "/admin",
          child: true
        }
      ]
    },
    {
      name: "Configuración",
      icon: DashboardIcon,
      collapse: true,
      faIcon: faWrench,
      state: "pageCollapse",
      views: [
        {
          path: "/merchant",
          name: "Comercios afiliados",
          faIcon: faBoxOpen,
          component: MerchantList,
          layout: "/admin",
          child: true
        },
        {
          path: "/fleet",
          name: "Flota",
          faIcon: faBoxOpen,
          component: FleetConfig,
          layout: "/admin",
          child: true
        }
      ]
    },
    {
      path: "/viewshipment",
      name: "Ver Envío",
      icon: DashboardIcon,
      faIcon: faPen,
      component: ViewShipment,
      layout: "/admin",
      redirect: true
    }
  ];
}else if (role === 'carrier') {
  dashRoutes = [
   
    {
      name: "Gestión de envíos",
      icon: DashboardIcon,
      collapse: true,
      faIcon: faShippingFast,
      state: "servicesCollapse",
      views: [
        {
          path: "/shipments",
          name: "Manejo de guías",
          faIcon: faBoxOpen,
          component: TrackingTableContainer,
          layout: "/admin",
          child: true
        }
      ]
    },
    {
      path: "/viewshipment",
      name: "Ver Envío",
      icon: DashboardIcon,
      faIcon: faPen,
      component: ViewShipment,
      layout: "/admin",
      redirect: true
    }
  ];
}else if (role === 'guest'){
  dashRoutes = [
    {
      path: "/dashboard",
      name: "Home",
      icon: DashboardIcon,
      faIcon: faHome,
      component: AdminDashboard,
      layout: "/admin"
    }
  ];
}


export default dashRoutes;
