import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
import Table from "components/Table/Table.js";
import TrackingStatus from "components/TrackingStatus/TrackingStatus.js";
import ModalDeliveryView from 'components/Delivery/ModalDeliveryView';
import {formatAmount} from 'utils/format';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import auth from "service/authentication";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { faPrint } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from "components/CustomButtons/Button.js";
import Menu from '@material-ui/core/Menu';
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const fileDownload = require('js-file-download');

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const rowsToDisplay = 20;
const checkboxStatus = 0;

const styles = {
    ...customSelectStyle,
    ...formStyles,
    ...buttonStyle,
    card: {
        display: "inline-block",
        position: "relative",
        width: "100%",
        margin: "25px 0",
        boxShadow: "0 1px 4px 0 rgba(0,0,0, 0.14)",
        borderRadius: "6px",
        color: "rgba(0,0,0, 0.87)",
        background: "white",
        transition: "all 300ms linear",
        minHeight: "410px"
      },
    tableContainer: {
        width:"97%",
        margin:"0 auto"
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
    hide : {
        display: 'none'
    }
}

class TrackingTable extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            deliveries: [],
            showParcel : '',
            noData: false,
            totalRecords: 0,
            page : 1,
            selectedTracks: [],
            anchorEl: null,
            printError : ''
        };
       
        this.viewParcel = this.viewParcel.bind(this);
        this.hideViewWindow = this.hideViewWindow.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleListChange = this.handleListChange.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleClosePrint = this.handleClosePrint.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.status !== prevProps.status 
            || this.props.dateStart !== prevProps.dateStart
            || this.props.dateEnd !== prevProps.dateEnd
            || this.props.filterString !== prevProps.filterString){
                this.setState(
                    {page:1, deliveries:[], totalRecords:0},
                    ()=>{this.fetchData()}
                );
                
        }
    }

    fetchData(){

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        let queryParams = '';
        //filters
        queryParams += this.props.hasOwnProperty('status') ? 'status=' + encodeURIComponent(this.props.status) + '&' : '';
        queryParams += this.props.hasOwnProperty('dateStart') ? 'dateStart=' + encodeURIComponent(this.props.dateStart) + '&' : '';
        queryParams += this.props.hasOwnProperty('dateEnd') ? 'dateEnd=' + encodeURIComponent(this.props.dateEnd) + '&' : '';
        queryParams += this.props.hasOwnProperty('filterString') ? 'filters=' + encodeURIComponent(this.props.filterString) + '&' : '';
        //pagination
        queryParams += 'page=' + this.state.page + '&';

        axios.get(apiURL + "/merchant/parcels?"+queryParams)
        .then( (res) => {
            if (res.data.data.length > 0){
                this.setState({noData:false, deliveries:res.data.data, totalRecords:res.data.totalRecords});
            }else{
                this.setState({noData:true, deliveries:[], totalRecords:0});
            }
        }).catch( (error) =>{
            console.log(error);
        });
       
    }

    viewParcel(trackingNo){
        this.setState({showParcel:trackingNo});
    }

    hideViewWindow(){
        this.setState({showParcel:''});
    }

    onPageChange(event){
        this.setState(
            {page:event.target.value, deliveries:[], totalRecords:0},
            () => {
                this.fetchData();
            }
        );
    }

    handleListChange (event) {
        let tracks = this.state.selectedTracks;
        if (event.target.checked){
            tracks.push(event.target.value);
        }else{
            let index = tracks.indexOf(event.target.value);
            tracks.splice(index, 1);
        }
        this.setState({selectedTracks: tracks});
    }

    handlePrint (event) {
        this.setState({
            anchorEl : event.currentTarget
        })
    }

    handleClosePrint (param) {
        this.setState({anchorEl: null});

        if (param === 'tracks' || param === 'list'){

            if (this.state.selectedTracks.length === 0){
                this.setState({printError: 'Selecciona por lo menos una guía para imprimir'});
                return
            }

            let endpoint = '/merchant/parcels/print';
            let baseName = 'Guías';
            if (param === 'list'){
                endpoint = '/merchant/manifiesto/print';
                baseName = 'Manifiesto';
            }
            let data = [];
            for (let t of this.state.selectedTracks){
                data.push({trackingno: {value: t}})
            }
            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            axios.post(apiURL+endpoint, data, {responseType: 'blob'})
            .then( (res) => {
                var filename = baseName + "-" + new Date().getTime() + ".pdf";      
                fileDownload(res.data, filename);
            }).catch((error) =>{
                console.log(error);
            });
        }

    }

    render(){
        
        const { classes } = this.props;
        let dataRows = [];
        let rowNo = (this.state.page-1)*rowsToDisplay+1;
        const deliveries = this.state.deliveries;

        let modalParcel = '';

        if (this.state.showParcel !== ''){
            modalParcel =  (
                <ModalDeliveryView
                    open = {true}
                    trackingNo={this.state.showParcel}
                    onClose={this.hideViewWindow}  
                />
            );
        }
       
        for (let d of deliveries){
            const trackingNo = (
                <div>
                <Link
                    component="button"
                    underline="always"
                    style={{fontSize:"14px", userSelect:"text"}}
                    onClick={() => {this.viewParcel(d.trackingNo)}}>{d.trackingNo}</Link>
                </div>
            );
            const tStatus = <TrackingStatus status={d.status} statusDescription={d.statusDescription}/>
            const found = this.state.selectedTracks.includes(d.trackingNo);
            const checkbox = (
                <Checkbox
                    checked = {found}
                    tabIndex={-1} onChange={this.handleListChange}
                    inputProps={{value: d.trackingNo}}
                    style = {{padding:'0px'}}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{checked: classes.checked,root: classes.checkRoot}}
                />
            );
            dataRows.push(
                [
                    checkbox,
                    rowNo,
                    d.merchant,
                    trackingNo,
                    d.packageNo,
                    d.created,
                    tStatus,
                    d.recipient,
                    formatAmount(d.cod),
                    d.municipio,
                    d.departamento
                ]
            )
            rowNo++;
        }

        let bracketOpen = (this.state.page - 1) * rowsToDisplay + 1;
        let bracketClose = bracketOpen + rowsToDisplay - 1;
        if (bracketClose > this.state.totalRecords) bracketClose = this.state.totalRecords;
        let pages = Math.ceil(this.state.totalRecords / rowsToDisplay);

        let customCellClasses = [classes.right];
        let customClassesForCells = [8];

        let customHeadCellClasses = [
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader,
            classes.tableHeader
        ];
        let customHeadClassesForCells = [0,1,2,3,4,5,6,7,8,9,10];

        if (auth.getUser().role !== 'backoffice' && auth.getUser().role !== 'carrier'){ //hide redundant data
            customCellClasses.push(classes.hide);
            customClassesForCells.push(2);

            customHeadCellClasses[2] = customHeadCellClasses[2] + " " + classes.hide;
        } 

        if (this.props.status !== checkboxStatus){ //hide checkboxes when status is not relevant
            customCellClasses.push(classes.hide);
            customClassesForCells.push(0);

            customHeadCellClasses[0] = customHeadCellClasses[0] + " " + classes.hide;
        }
        
        let printError;
        if (this.state.printError.length > 0){
            printError = (
                <SweetAlert
                error
                style={{ display: "block", marginTop: "-175px", width:"40%" }}
                title={this.state.printError}
                onConfirm={() => this.setState({printError:''})}
                onCancel={() => this.setState({printError:''})}
                confirmBtnText="Ok"
                confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
                />
            );
        }

        return(
            <div>
                {modalParcel}
                {printError}
            
                <div className={classes.tableContainer}>

                {this.props.status === checkboxStatus && 
                <div style={{textAlign:'left'}}>
                    <Link aria-controls="simple-menu" simple aria-haspopup="true" onClick={this.handlePrint} 
                            style={{color:"#00cae3",textAlign:'right', cursor:'pointer'}}>
                        <FontAwesomeIcon icon={faPrint} style={{fontSize:"20px", marginLeft:"5px"}}/> Imprimir
                    </Link>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClosePrint}
                    >
                        <MenuItem onClick={() => {this.handleClosePrint('tracks')}}>Imprimir guías</MenuItem>
                        <MenuItem onClick={() => {this.handleClosePrint('list')}}>Imprimir manifiesto</MenuItem>
                            
                    </Menu>    
                </div>}

                <Table
                showLoading
                noData = {this.state.noData}
                striped
                tableHead={[
                    "",
                    "No.",
                    "Comercio",
                    "Guía de envío",
                    "Ref.",
                    "Fecha de envío",
                    "Estado actual",
                    "Destinatario",
                    "Monto COD",
                    "Municipio",
                    "Departamento"
                ]}
                tableData={dataRows}

                 customCellClasses={customCellClasses}
                 customClassesForCells={customClassesForCells}

                 customHeadCellClasses={customHeadCellClasses}
                 customHeadClassesForCells={customHeadClassesForCells}

                />

                {(pages > 0 &&
                    (
                        <div>
                            <GridContainer justify="center" style={{marginTop:'10px', borderTop:"1px solid #e0e0e0", padding:'5px'}}>
                                <GridItem xs={12} style={{lineHeight:'40px', textAlign:'right'}}>
                                    Mostrando del {bracketOpen} al {bracketClose} de {this.state.totalRecords} registros encontrados.
                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="center" style={{borderTop:"1px solid #e0e0e0", padding:'5px'}}>
                                <GridItem xs={12} style={{lineHeight:'40px', textAlign:'right'}}>
                                    Página {(pages === 1) ? '1' : (
                                        <FormControl style={{width:'60px'}}>
                                        <Select MenuProps={{className: classes.selectMenu}}
                                            classes={{select: classes.select}}
                                            value={this.state.page}
                                            onChange={this.onPageChange}
                                            style={{textAlign:'center'}}
                                            inputProps={{name: "selectedOperator",id: "simple-select"}}
                                            >
                                                {Array(pages).fill(0).map ( (o, index) =>{
                                                    return (
                                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value={index+1} key={index+1}>
                                                            {index+1}
                                                        </MenuItem>
                                                    )
                                                } )}
                                        </Select> 
                                        </FormControl>
                                    )} de {pages}
                                </GridItem>
                            </GridContainer>
                        </div>
                    )
                )}

                </div>

            </div>
        );
    }

}

export default withStyles(styles)(TrackingTable);
