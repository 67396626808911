import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Face from "@material-ui/icons/Face";
import Settings from "@material-ui/icons/Settings";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { faBuilding, faPhoneRotary, faMapMarkerAlt, faAddressCard,faPen, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Link from '@material-ui/core/Link';
import darlin from "assets/img/faces/darlin.jpg";
import roxana from "assets/img/faces/roxana.jpg";
import heidy from "assets/img/faces/heidy.jpg";
import CardAvatar from "components/Card/CardAvatar.js";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import CustomInput from "components/CustomInput/CustomInput.js";


const styles = {
    ...customCheckboxRadioSwitch,
    formControl: {
        paddingTop: "0px !important"
      },
    cardTitle: {
        fontWeight:'900'
    },
    content:{
        fontSize: '20px',
        borderTop: '1px solid #eaeaea',
        paddingTop: '10px'
    },
    subtitle:{
      fontSize:"17px",
      lineHeight:"30px",
      fontWeight:"500"
    },
    addressBlock: {
        color: "black",
        fontSize: "15px",
        fontWeight: "400",
        whiteSpace: "pre-wrap"
    },
    addressIconAction: {
        color: "#00cae3",
        width: "15px"
    }
}

const addresses = [
    'Chopinmol\nVía 6 3-56 zona 4\nEdificio OEG nivel 7\nGuatemala 01004\nTel: 5819-9414\nAtención: Heidy López',
    'Chopinmol, Bodega 1\nOfibodegas Naranjo\nBodega # 1\nGuatemala 01057\nTel: 7334-1232\nAtención: Cristofer Raymundo',
    'Chopinmol, Bodega 2\nOfibodegas Naranjo\nBodega # 2\nGuatemala 01057\nTel: 7311-1612\nAtención: Miguel Vásquez',
  ]
  

class MerchantConfig extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            simpleSelect: '0',
            checkedA:true
        }
        this.handleSimple = this.handleSimple.bind(this);
        this.setCheckedA = this.setCheckedA.bind(this);
    }

    handleSimple(event){
        this.setState({simpleSelect: event.target.value });
    };

    setCheckedA(checked){
        this.setState({checkedA:checked});
    }

    render(){
        const { classes } = this.props;
        const simpleSelect = this.state.simpleSelect;

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardBody>
                <NavPills
                    color="primary"
                    alignCenter
                    tabs={[
                    {
                        tabButton: "Perfil",
                        tabIcon: Info,
                        tabContent: (

                            <div class={classes.content} style={{lineHeight:'35px'}}>
                                <FontAwesomeIcon icon={faBuilding}/> Chopinmol.com <br />
                                <FontAwesomeIcon icon={faMapMarkerAlt}/> Via 6 3-56, Guatemala 01004<br/>
                                <FontAwesomeIcon icon={faPhoneRotary}/> 5454-5454 <br/>
                                <FontAwesomeIcon icon={faAddressCard}/> Contacto: Heidy López<br/>
                                <br/>
                                <p style={{width:'100%', textAlign:'center'}}>
                                    <Button color="rose" round onClick={() => {alert('Editar perfil de comercio')}}>Editar</Button>
                                </p>
                            </div>
                            
                        )
                    },
                    {
                        tabButton: "Direcciones",
                        tabIcon: LocationOn,
                        tabContent: (
                            <div class={classes.content}>
                            <GridItem xs={12}>
                                <p className={classes.subtitle}>Dirección para recolección:</p>
                                <p className={classes.addressBlock}>

                                    <FormControl style={{width:'200px'}}>
                                    <Select MenuProps={{className: classes.selectMenu}}
                                            classes={{select: classes.select}}
                                            value={simpleSelect}
                                            onChange={this.handleSimple}
                                            inputProps={{name: "simpleSelect",id: "simple-select"}}
                                    >
                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="0">
                                        Vía 6, 3-56 zona 4
                                        </MenuItem>
                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="1">
                                        El Naranjo, Bodega 1
                                        </MenuItem>
                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value="2">
                                        El Naranjo, Bodega 2
                                        </MenuItem>
                                    </Select>
                                    </FormControl>
                                    <br></br><br></br>
                                    {addresses[simpleSelect]}
                                </p>
                                <p className={classes.addressBlock}>
                                    <FontAwesomeIcon icon={faPen} class={classes.addressIconAction}/>
                                    <Link
                                    component="button"
                                    underline="always"
                                    style={{color:"#00cae3",fontSize:"16px"}}
                                    onClick={() => {
                                        alert("Editar dirección");
                                    }}>editar dirección</Link>
                                </p>
                                <p className={classes.addressBlock}>
                                    <FontAwesomeIcon icon={faPlus} class={classes.addressIconAction}/>
                                    <Link
                                    component="button"
                                    underline="always"
                                    style={{color:"#00cae3",fontSize:"16px"}}
                                    onClick={() => {
                                        alert("Agregar dirección");
                                    }}>agregar dirección</Link>
                                </p>
                            </GridItem>
                            </div>
                        )
                    },
                    {
                        tabButton: "Usuarios",
                        tabIcon: Face,
                        tabContent: (
                            <div class={classes.content} style={{paddingTop:'10px'}}>
                                <GridContainer justify="center" style={{width:"98%", margin:"0 auto"}}>
                                    <GridItem xs={12} style={{textAlign:'right'}}>
                                        <Button 
                                            color="primary" 
                                            round 
                                            style={{width:"55px", height:"60px"}}
                                            onClick={ () => {alert('Agregar un usuario')} }
                                        >
                                            <FontAwesomeIcon icon={faPlus} size='2x' />
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <Card profile>
                                            <CardAvatar profile>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <img src={roxana} alt="..." />
                                            </a>
                                            </CardAvatar>
                                            <CardBody profile>
                                            <h6 className={classes.cardCategory}>CEO</h6>
                                            <h4 className={classes.cardTitle}>Roxana Kawas</h4>
                                            <p className={classes.description}>
                                                roxana.kawas@chopinmol.com
                                            </p>
                                            <Button color="rose" round>
                                                Editar
                                            </Button>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <Card profile>
                                            <CardAvatar profile>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <img src={heidy} alt="..." />
                                            </a>
                                            </CardAvatar>
                                            <CardBody profile>
                                            <h6 className={classes.cardCategory}>Servicio al cliente</h6>
                                            <h4 className={classes.cardTitle}>Hiedy López</h4>
                                            <p className={classes.description}>
                                                heidy.lopez@chopinmol.com
                                            </p>
                                            <Button color="rose" round>
                                                Editar
                                            </Button>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <Card profile>
                                            <CardAvatar profile>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <img src={darlin} alt="..." />
                                            </a>
                                            </CardAvatar>
                                            <CardBody profile>
                                            <h6 className={classes.cardCategory}>Logística</h6>
                                            <h4 className={classes.cardTitle}>Darlin Vicente</h4>
                                            <p className={classes.description}>
                                                darlin.vicente@chopinmol.com
                                            </p>
                                            <Button color="rose" round>
                                                Editar
                                            </Button>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        )
                    },
                    {
                        tabButton: "Configuración",
                        tabIcon: Settings,
                        tabContent: (
                            <div class={classes.content}>
                                <GridContainer style={{width:'97%', margin:'0 auto'}}>
                                    <GridItem xs={6} style={{textAlign:'right'}}>
                                        <div style={{paddingTop:'27px'}}>
                                            Costo base por envío
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} style={{textAlign:'left'}}>
                                        <CustomInput
                                            id={"field_"}
                                            inputProps={{
                                                placeholder: 'Q. 25.00',
                                                value: 'Q. 25.00'
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} style={{textAlign:'right'}}>
                                        <div style={{paddingTop:'27px'}}>
                                            Porcentaje de comisión por COD
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} style={{textAlign:'left'}}>
                                        <CustomInput
                                            id={"field_"}
                                            inputProps={{
                                                placeholder: '2%',
                                                value: '2%'
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} style={{textAlign:'right'}}>
                                       <div style={{paddingTop:'27px'}}>
                                        Cobro automático de costo de envío
                                       </div>
                                    </GridItem>
                                    <GridItem xs={6} style={{textAlign:'left'}}>
                                    <div style={{paddingTop:'22px'}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                checked={this.state.checkedA}
                                                onChange={event => this.setCheckedA(event.target.checked)}
                                                value="checkedA"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label=""
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <br/>
                                <p style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
                                    <Button color="rose" round onClick={() => {alert('Guardar configuración')}}>Guardar</Button>
                                </p>
                            </div>
                        )
                    }
                    ]}
                />
                </CardBody></Card>
                </GridItem>
            </GridContainer>
        );
    }

}

export default withStyles(styles)(MerchantConfig);