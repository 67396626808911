/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { faTimes, faPaperclip } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import TextField from '@material-ui/core/TextField';
import Table from "components/Table/Table.js";
import TrackingStatus from "components/TrackingStatus/TrackingStatus.js";
import {formatAmount} from 'utils/format';
import CircularProgress from '@material-ui/core/CircularProgress';
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const style = {
    ...styles,
    ...formStyles,
    ...customSelectStyle,
    ...buttonStyle,
    modalTitle:{
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
        color: "#555555",
        fontWeight: "900"
    },
    modal:{
        width:"90%",
        maxWidth:"90%",
        height: "90%",
        maxHeight: "90%"

    },
    darkerLabel:{
        color:"#878787"
    },
    errorLabel:{
        color: "#e53935"
    },
    modalFooter:{
        margin:"0 auto"
    },
    error:{
        color: "#e53935",
        fontSize: "15px",
        fontWeight: "300"
    },
    select:{
        marginTop:"32px"
    },
    windowToolbar:{
        position: 'absolute',
        top : '5px',
        right : '25px',
        textAlign: "right",
        fontSize:"22px",
        color:"#555555"
    },
    radioRootCustom :{
        paddingTop:'5px'
    },
    formControlCustom: {
        paddingTop: '5px'
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
    depositRoot:{
        width: '100px',
        marginLeft: '7px'
    },
    alertText:{
        fontSize:"25px",
        lineHeight:"30px"
    }
}

// const banks = [
//     {code:'BI', name: 'BI - 34523421'},
//     {code: 'GyT', name: 'G&T - 12312312'}
// ]

function DisplayFiles( props ){
    const { fileList, ...other} = props;

    if (fileList.length === 0){
        return ('');
    }

    let fileNames = '';

    for (let i = 0; i<fileList.length; i++){
        fileNames +=  fileNames.length === 0 ? fileList[i].name : ', ' + fileList[i].name;
    }

    return (
        fileNames
    );

}

class ModalCloseTransaction extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            open:this.props.open,
            deliveries: this.props.deliveries,
            slipNo: '',
            description: '',
            saving: false,
            selectedFiles: [],
            error: ''
        };

        this.closeModal = this.closeModal.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSlipNoChange = this.onSlipNoChange.bind(this);
        this.doSave = this.doSave.bind(this);
        this.onFileAttach = this.onFileAttach.bind(this);
    }

    closeModal(){
        this.setState({open:false});
        this.props.onClose();
    }

    componentDidUpdate(prevProps) {
        if (this.props.deliveries !== prevProps.deliveries){
            this.setState({...this.props});
        }
    }

    onInputChange (field, event){
        let o = new Object();
        o[field] = event.target.value;

        this.setState(o);
    }

    onSlipNoChange (event) {
        this.setState({slipNo:event.target.value});
    }

    onFileAttach (event) {
        const files = event.target.files;
        //event.target.value = null; //clean up input field 
    
        for (let file of files){
            if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('jpeg') && !file.name.toLowerCase().endsWith('png')){
                this.setState({error:'Solamente puedes adjuntar archivos JPG, JPEG o PNG.'});
                return;
            }
        }

        this.setState({selectedFiles:files}); 
    }

    doSave () {
        //if total amount > 0, deposit slip should be mandatory, if not (i.e. COD = 0 for all subject parcels), just close all parcels

        if (this.state.slipNo.length === 0){
            this.setState({error:'Por favor, ingresa el numero de boleta.'});
            return;
        }

        if (this.state.description.length === 0){
            this.setState({error:'Por favor, ingresa una descripción.'});
            return;
        }

        if (this.state.selectedFiles.length === 0){
            this.setState({error:'Por favor, selecciona un archivo.'});
            return;
        }

        this.setState({saving: true} , () => {
            let parcelList = this.state.deliveries.map ( item => item.parcelId );

            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

            const files = this.state.selectedFiles;
            let formData = new FormData();
            for (let f of files){
                formData.append('files',f)
            }

            formData.append('slipNo', this.state.slipNo);
            formData.append('description', this.state.description);
            formData.append('parcels', JSON.stringify(parcelList));

            axios.post(apiURL + "/backoffice/parcel/close", formData)
            .then( (res) => {
                this.setState({open:false});
                this.props.onClose();
                this.props.onSave();
            }).catch( (error) =>{
                this.setState({saving: false});
                console.log(error);
            });
        });
        
    }

    render(){

        const { classes } = this.props;
        const deliveries = this.state.deliveries;

        let dataRows = [];
        let rowNo = 1;

        let totalDeposit = 0;

        for (let d of deliveries){
            const trackingNo = d.trackingNo;
            const tStatus = <TrackingStatus status={d.status} statusDescription={d.statusDescription}/>

            totalDeposit += parseFloat(d.cod);

            dataRows.push(
                [
                    rowNo,
                    d.carrierName,
                    d.merchant,
                    trackingNo,
                    d.packageNo,
                    tStatus,
                    d.recipient,
                    d.municipio,
                    d.departamento,
                    formatAmount(d.cod)
                ]
            )
            rowNo++;
        }

        dataRows.push({
            color:"gray",
            data:[
                "","", "", "", "", "", "","",
                <strong>Total</strong>,
                <strong>Q. {formatAmount(totalDeposit)}</strong>
                ]
            }
        );

        let customCellClasses = [classes.right];
        let customClassesForCells = [9];

        let customHeadCellClasses = [
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader + " " + classes.right,
        ];
        let customHeadClassesForCells = [0,1,2,3,4,5,6,7,8,9];

        let errorPopup = '';

        if (this.state.error.length > 0){
            errorPopup = (
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-175px", width:"40%" }}
                    title="Ouch! Hubo un error"
                    onConfirm={() => this.setState({error:''})}
                    confirmBtnText="Ok"
                    confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                    confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
                >
                    <div className={classes.alertText}>
                        {this.state.error}
                    </div>
                </SweetAlert>
            );
        }

        return(

            <Dialog
                classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
                }}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeModal}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="form-dialog-title">Cierre de guías</DialogTitle>

                <div className={classes.windowToolbar}>
                    <FontAwesomeIcon icon={faTimes} style={{cursor:"pointer"}} onClick={this.closeModal}/>
                </div>
                <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
                >
                    {errorPopup}
                    { totalDeposit > 0 && (
                        
                        <GridContainer>
                            <GridItem xs={1} style={{marginTop:'23px'}}>
                                Depósito:
                            </GridItem>
                            <GridItem  xs={11}>
                                
                                <TextField id="standard-basic" label="# de boleta" value={this.state.slipNo} 
                                    onChange={(event) => this.onInputChange('slipNo',event) }/>
                                {`  `}
                                <TextField id="standard-basic" label="Descripción" value={this.state.description} style={{width:'300px'}}
                                    onChange={(event) => this.onInputChange('description',event) } />
                                {`  `}
                                <DisplayFiles fileList = {this.state.selectedFiles} />
                                <Button color="primary" simple onClick={() => { document.getElementById('slip_file').click(); }} style={{textDecoration:'none'}}>
                                    <FontAwesomeIcon icon={faPaperclip} onClick={this.closeModal}/> Adjuntar archivo
                                </Button>
                                <input type="file" name="slip_file" id="slip_file" onChange={this.onFileAttach} multiple accept=".jpg,.jpeg,.png" style={{display:"none"}}/>
                            </GridItem>

                        </GridContainer>

                    ) }


                    <GridContainer>
                        <GridItem xs={12}>

                            <Table
                                showLoading
                                noData = {this.state.noData}
                                striped
                                tableHead={[
                                    "No.",
                                    "Transportista",
                                    "Comercio",
                                    "Guía de envío",
                                    "Ref.",
                                    "Estado actual",
                                    "Destinatario",
                                    "Municipio",
                                    "Departamento",
                                    "Monto COD"
                                ]}
                                tableData={dataRows}

                                customCellClasses={customCellClasses}
                                customClassesForCells={customClassesForCells}

                                customHeadCellClasses={customHeadCellClasses}
                                customHeadClassesForCells={customHeadClassesForCells}
                            />
                            
                        </GridItem>
                    </GridContainer>

                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    
                    {this.state.saving ? 
                        (
                            <>
                                <CircularProgress size={20} style={{marginRight:'5px', color:"purple"}}/>
                                Guardando...
                            </>
                        ) : (
                            <>
                                <Button onClick={this.doSave} color="primary">
                                    Guardar cierre
                                </Button>
                                <Button onClick={this.closeModal} color="danger" style={{marginLeft:'25px'}}>
                                    Cancelar
                                </Button>
                            </>
                        )
                    }
                                
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(style)(ModalCloseTransaction);