import React from "react";
import Table from "components/Table/Table.js";
import {formatAmount} from 'utils/format';
import withStyles from "@material-ui/core/styles/withStyles";
import {GLAccount} from 'service/finance';

const style = {
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
}

class SummaryView extends React.Component {

    constructor(props){
        super(props);
    }

    render () {

        const {classes} = this.props;

         //amount we owe
         let codToSettle = 0;
         //amount owed to us
         let receivable = 0;
 
         for (let parcel of this.props.transactions){
             for (let detail of parcel.details){
                 if (detail.GLAccountCode === GLAccount.COD_TO_SETTLE){
                     codToSettle += parseFloat(detail.Credit);
                 }else if (detail.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE){
                     receivable += parseFloat(detail.Debit);
                 }
             }
         }
 
         let dataRows = [];
         let totalDebit = codToSettle;
         let totalCredit = codToSettle;
 
         if (this.props.transactions.length > 0){
            dataRows.push ([
                'COD por liquidar',
                formatAmount(codToSettle),
                '-'
            ]);
    
            dataRows.push ([
                '...Cuentas por cobrar',
                '-',
                formatAmount(receivable),
            ])
    
            dataRows.push([
                <strong>...Total a transferir</strong>,
                '-',
                <strong>{formatAmount(codToSettle - receivable)}</strong>
            ])

            dataRows.push({
                color:"gray",
                data: [
                    '',
                    formatAmount(totalDebit),
                    formatAmount(totalCredit),
                ]
            });
         }
 
         let customHeadCellClasses =[
             classes.tableHeader + ' ' + classes.description,
             classes.tableHeader + ' ' + classes.right,
             classes.tableHeader + ' ' + classes.right,
         ];
 
         let customCellClasses = [classes.cell, classes.cell + ' ' + classes.right, classes.cell + ' ' + classes.right, ];

         return (
            <Table
            showLoading

            tableHead={["Descripción", "Debe", "Haber"]}

            tableData={dataRows}

            customHeadCellClasses={customHeadCellClasses}
            customHeadClassesForCells={[0,1,2,3,4]}

            customCellClasses={customCellClasses}
            customClassesForCells={[0,1,2,3,4]}

            customRowsClass = {classes.row} />
         )
    }
}

export default withStyles(style)(SummaryView);