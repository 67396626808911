import React from "react";
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
    tooltip:{
        fontSize: "20px"
    }
};

class ErrorLink extends React.Component {

    render(){

        const { classes } = this.props;

        var value = '';
        if (this.props.val.toString().trim().length > 0){
            value = this.props.val.toString().trim();
        }else{
            value = 'Error';
        }

        return (
            <Tooltip
            id="tooltip-top"
            title={this.props.tooltip}
            placement="top" 
            classes={{ tooltip: classes.tooltip }}>

                <Link
                component="button"
                underline="always"
                style={{fontSize:"18px", textAlign:"left", userSelect:"text", color:"red"}}
                onClick={this.props.action}>{value}</Link>

            </Tooltip>
        );
    }

}

export default withStyles(style)(ErrorLink);