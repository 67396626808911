import React from "react";
import { withStyles } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { faFilter, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ButtonCore from "@material-ui/core/Button";
import { Toolbar } from '@material-ui/core';
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Chip from '@material-ui/core/Chip';
import cloneDeep from 'lodash.clonedeep';

const styles = {
    ...customSelectStyle,
    ...formStyles,
    dropdownDividerItem: {
        margin: "5px 0",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        height: "1px",
        overflow: "hidden"
      },
      tooltip:{
        fontSize: "15px"
      },
      filterInput:{
          width:'185px',
          paddingTop: '6px !important',
          paddingLeft: '15px'
    },
    root:{
        minHeight: '40px'
    }
}

const numberOperators = ['Mayor que', 'Mayor o igual que', 'Igual que', 'Diferente', 'Menor que', 'Menor o igual que'];
const numberOps = ['gt', 'gte', 'eq', 'noteq', 'lt', 'lte'];

const stringOperators = ['Contiene', 'No contiene'];
const stringOps = ['in','notin'];

class DataFilter extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            anchorEl:null,
            popupAnchorEl : null,
            popupTitle: '',
            popupSelectedOperator: 0,
            popupType: null,
            popupInput: '',
            popupList: [],
            popupSelectedListValues: [],
            popupSelectedListLabels: [],
            popupInputError: undefined,
            filters: this.props.filters,
            filterSelected : undefined,
            savedFilters: []
        }

        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handlePopupClose = this.handlePopupClose.bind(this);
        this.onOperatorChange = this.onOperatorChange.bind(this);
        this.handlePopupInputChange = this.handlePopupInputChange.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.handleListChange = this.handleListChange.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
    }

    handleMenuClose(event, filterSelected){
        const anchorEl = this.state.anchorEl;
        this.setState({anchorEl:null});

        if (filterSelected !== 'backdropClick'){ // means that menu entry was selected
   
            let filter;
            for (let i = 0; i < this.state.filters.length; i++){
                filter = this.state.filters[i].fields.find( f => f.code === filterSelected);
                if (filter) break;
            }

            let list = (filter.type === 'lookup') ? filter.data : [];
            //Initialize Popup 
            this.setState(
                {
                    popupAnchorEl:anchorEl, 
                    popupTitle: filter.label, 
                    popupType:filter.type, 
                    popupList: list,
                    popupSelectedOperator:0,
                    popupSelectedListValues: [],
                    popupSelectedListLabels: [],
                    popupInputError: undefined,
                    popupInput: '',
                    filterSelected: filter
                }
            );
        }
    }

    handlePopupClose () {
        this.setState({popupAnchorEl:null});
    }

    onOperatorChange (event) {
        this.setState({popupSelectedOperator: event.target.value});
    }

    handlePopupInputChange (event) {
        this.setState({popupInput: event.target.value});
    }

    handleListChange ( event ){
        let current = this.state.popupSelectedListValues;
        let labels = this.state.popupSelectedListLabels;
        if (event.target.checked){
            current.push(event.target.value);
            labels.push(event.target.title);
        }else{
            let index = current.indexOf(event.target.value);
            current.splice(index, 1);
            index = labels.indexOf(event.target.title);
            labels.splice(index, 1);
        }
        this.setState({popupSelectedListValues:current, popupSelectedListLabels:labels});
    }

    onFilterSave(){
        //Validate
        switch (this.state.popupType){
            case 'number':
                if (this.state.popupInput.length ===0 || isNaN(this.state.popupInput)){
                    this.setState({popupInputError:'Escribe un número'});
                    return;
                }
                break;
            case 'string':
                if (this.state.popupInput.length < 3 ){
                    this.setState({popupInputError:'Escribe un valor'});
                    return;
                }
                if (this.state.popupInput.length > 80){
                    this.setState({popupInputError:'Máximo 80 caracteres'});
                    return;
                }
                if (this.state.popupInput.indexOf(':') !== -1){
                    this.setState({popupInputError:'Símbolo : no es permitido'});
                    return;
                }
                if (this.state.popupInput.indexOf('|') !== -1){
                    this.setState({popupInputError:'Símbolo | no es permitido'});
                    return;
                }
                break;
            case 'lookup':
                if (this.state.popupSelectedListValues.length === 0){
                    this.setState({popupInputError:'Seleccionar por lo menos un valor'});
                    return;
                }
                break;
        }
        //Save
        let filter = cloneDeep(this.state.filterSelected);
        let operator = this.state.popupSelectedOperator;
        if (this.state.popupType === 'number' || this.state.popupType === 'string'){
            filter.value = this.state.popupInput;
            filter.operator = operator;
            filter.operatorLabel = this.state.popupType === 'number' ? numberOperators[operator] : stringOperators[operator];
            filter.sql = filter.code + ':' + ( this.state.popupType === 'number' ? numberOps[operator] : stringOps[operator] ) + ':' + this.state.popupInput;
        }else if (this.state.popupType === 'lookup'){
            delete filter.data; //free up some memory
            filter.list = this.state.popupSelectedListValues;
            filter.value = this.state.popupSelectedListLabels;
            filter.operator = 0;
            filter.operatorLabel = ': ';
            filter.sql = filter.code + ':in:' + this.state.popupSelectedListValues;
            if (this.state.popupSelectedListValues.length > 1)
                filter.label += 's'; //make it plural
        }

        //save filter & close popup
        let savedFilters = this.state.savedFilters;
        savedFilters.push(filter);
        this.setState({popupAnchorEl:null, savedFilters});

        //notify parent
        this.props.onFilterChange(savedFilters);
    }

    deleteFilter(index){
        let savedFilters = this.state.savedFilters;
        savedFilters.splice(index,1);
        this.setState({savedFilters});

        //notify parent
        this.props.onFilterChange(savedFilters);
    }

    render() {

        const {classes} = this.props;     

        var menuItems = [];
        let index = 0;
        for (let group of this.state.filters){
            if (group.hasOwnProperty('topDivider') && group.topDivider)
                menuItems.push(<Divider  key={index} className={classes.dropdownDividerItem}/>);
            index++;
            if (group.group)
                menuItems.push(<MenuItem key={index} className={classes.root} disabled={true} style={{paddingLeft:'5px'}}>{group.group}</MenuItem>);
            index++;
            for (let field of group.fields){
                menuItems.push(<MenuItem key={index} className={classes.root} onClick={(event) => this.handleMenuClose(event, field.code)} >{field.label}</MenuItem>)
                index++;
            }
        }

        var operators = undefined;
        if  (this.state.popupType === 'number'){
            operators = numberOperators;
        }else if (this.state.popupType === 'string'){
            operators = stringOperators;
        }

        return(
            <div id="filter-container">

            <Tooltip title="Agregar filtros" aria-label="add" classes={{ tooltip: classes.tooltip }}>
                <IconButton 
                    aria-label="fechas" 
                    onClick={(event) => this.setState({anchorEl:event.currentTarget})} >
                    <FontAwesomeIcon icon={faFilter} style={{fontSize:'20px', color:'purple'}}/>
                </IconButton>
            </Tooltip>

            {this.state.savedFilters.map( (item, index) => {
                return (
                    <Chip key={index} 
                        label={`${item.label} ${item.operatorLabel.toLowerCase()} ${item.value}`} 
                        onDelete={()=> this.deleteFilter(index) } 
                        color="primary" 
                        style={{marginLeft:'5px'}}
                        variant="outlined" />
                )
            })}
            
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
            >
               {menuItems}
            </Menu>

            <Popover
                id="add-filter"
                open={Boolean(this.state.popupAnchorEl)}
                anchorEl={this.state.popupAnchorEl}
                onClose={this.handlePopupClose}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <AppBar position="static" elevation={0} >
                  <Toolbar style={{paddingRight:'0px', minHeight:'56px'}}>
                    <Typography style={{flexGrow:'1'}}>
                        {this.state.popupTitle}
                    </Typography>
                    <Button simple onClick={()=>this.handlePopupClose()} style={{textAlign:'right', width:'20px'}}>
                        <FontAwesomeIcon icon={faTimes} style={{fontSize:'30px', color:'white'}}/>
                    </Button>
                  </Toolbar>
                </AppBar>

                <div style={{marginTop:'10px', width:'400px', maxHeight:'400px', overflowY:'auto', paddingLeft:'20px', paddingRight:'10px'}}>
                    
                    {/* Filter type: input */}
                    {( operators && 
                        <div>
                            <FormControl style={{width:'135px'}}>
                                <Select MenuProps={{className: classes.selectMenu}}
                                classes={{select: classes.select}}
                                value={this.state.popupSelectedOperator}
                                onChange={this.onOperatorChange}
                                inputProps={{name: "selectedOperator",id: "simple-select"}}
                                >
                                    {operators.map ( (o, index) =>{
                                        return (
                                            <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} value={index} key={index}>
                                                {o}
                                            </MenuItem>
                                        )
                                    } )}
                                </Select> 
                            </FormControl>

                            <CustomInput
                                id={"field"} 
                                inputProps={{
                                    placeholder: 'Valor',
                                    value: this.state.popupInput
                                }}
                                formControlProps={{
                                    className: classes.filterInput,
                                    onChange: this.handlePopupInputChange
                                }}
                                helperText= {this.state.popupInputError}
                                error = {Boolean(this.state.popupInputError)}
                            />
                        </div>
                    )}

                    {/* Filter type: lookup */}
                    {
                        (this.state.popupList.length > 0 && 
                            (<div>
                                {this.state.popupList.map ( (item, index) => {
                                        return (
                                            <FormControlLabel
                                            key = {index}
                                            style={{display:'block'}}
                                            control={
                                                <Checkbox
                                                tabIndex={-1}
                                                onChange={this.handleListChange}
                                                inputProps={{
                                                    value: item.code,
                                                    title: item.value
                                                }}
                                                checkedIcon={
                                                    <Check className={classes.checkedIcon} />
                                                }
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                                root: classes.labelRoot
                                            }}
                                            label={item.value}
                                            />
                                        )
                                    })
                                }
                            </div>)
                        )
                    }
                  
                </div>

                 <GridContainer justify="center" style={{borderTop:"1px solid #e0e0e0", padding:'5px'}}>
                    {((this.state.popupType === 'lookup' && this.state.popupInputError ) && (
                        <GridItem xs={12} style={{textAlign:'right', color:'#f44336'}}>
                            {this.state.popupInputError}
                        </GridItem>
                    ))}
                    <GridItem xs={12} style={{textAlign:'right'}}>
                      <ButtonCore  style={{color:'purple'}} color="primary" onClick={()=>this.handlePopupClose()}>
                        Cancelar
                      </ButtonCore>
                      <ButtonCore  style={{color:'purple', marginLeft:'10px'}} color="primary" onClick={()=>this.onFilterSave()}>
                        Aplicar
                      </ButtonCore>
                    </GridItem>
                </GridContainer>
                
              </Popover>

            </div>
        )
    }
}

export default withStyles(styles)(DataFilter);