import React from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { faCheckCircle, faArrowCircleRight, faExclamationTriangle, faClock, faBan, faList, faUndo, faLockAlt} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import purple from '@material-ui/core/colors/purple';
import TrackingTable  from 'components/TrackingTable/TrackingTable';
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Toolbar } from '@material-ui/core';
import DateRangeInput from "components/DateRangeInput/DateRangeInput";
import DataFilter from "components/DataFilter/DataFilter";
import {addDays} from 'date-fns';
import auth from "service/authentication";

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const theme = createMuiTheme({
    palette: {
        primary: purple,
        showCalendar: false
    }
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const styles = {
    root: {
        flexGrow: 1,
        width: '100%',
        margin:'0 auto',
        backgroundColor: theme.palette.background.paper,
    },
    card: {
      display: "inline-block",
      position: "relative",
      width: "100%",
      margin: "25px 0",
      boxShadow: "0 1px 4px 0 rgba(0,0,0, 0.14)",
      borderRadius: "6px",
      color: "rgba(0,0,0, 0.87)",
      background: "white",
      transition: "all 300ms linear",
      minHeight: "410px"
    }
}


const filters = [
  {
    group:'Filtros paquete',
    fields: [
      {code: 'packageno', label:'Referencia', type: 'string'},
      {code: 'cod', label : 'Monto COD', type: 'number'},
      {code: 'description', label: 'Descripción del paquete', type:'string'}
    ]
  },
  {
    topDivider: true,
    group:'Filtros destinatario',
    fields: [ //!!!buildMunisAndDeptos function is sensitive to the ordering of this array
      {code: 'name', label : 'Nombre', type:'string'},
      {code: 'address', label : 'Dirección', type: 'string'},
      {code: 'municipio', label : 'Municipio', type:'lookup', data: []},
      {code: 'departamento', label : 'Departamento', type:'lookup', data: []},
    ]
  }
]

//Add filter that makes sense for role
if (auth.getUser().role === 'backoffice' || auth.getUser().role === 'carrier'){
  filters[0].fields.push({code: 'merchant', label: 'Comercio', type:'string'})
}

class TrackingTableContainer extends React.Component {

    constructor(props){
      super(props);
      let startDate = addDays(new Date(), -7);
      let endDate = new Date();
      this.state ={
          value: 0,
          filterDateStart : this.getDateFormat(startDate),
          filterDateEnd   : this.getDateFormat(endDate),
          viewingStatus    : 0,
          filterString    : '',
          totals          : [],
          departamentos   : [],
          municipios      : []
      }
      this.handleTabChange = this.handleTabChange.bind(this);
      this.onDateRangeChange = this.onDateRangeChange.bind(this);
      this.onFilterChange = this.onFilterChange.bind(this);
      this.fetchStatusTotals = this.fetchStatusTotals.bind(this);
    }

    buildMunisAndDeptos(munis, deptos, forStatus = 0){

      //munis
      let munisArray = [];
      for (let m of munis){
        if (m.StatusCode === forStatus){
          munisArray.push ({code: m.municipioCode, value: m.municipioName});
        }
      }
      filters[1].fields[2].data = munisArray;

      //deptos
      let deptosArray = [];
      for (let d of deptos){
        if (d.StatusCode === forStatus){
          deptosArray.push ({code: d.departamentoCode, value:d.departamentoName});
        }
      }
      filters[1].fields[3].data = deptosArray;

    }

    fetchStatusTotals(){
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
       
        let queryParams = '';
        queryParams += 'dateStart=' + encodeURIComponent(this.state.filterDateStart) + '&';
        queryParams += 'dateEnd=' + encodeURIComponent(this.state.filterDateEnd) + '&';

        axios.get(apiURL + "/merchant/parcels/totals?"+queryParams)
        .then( (res) => {
            this.buildMunisAndDeptos(res.data.munis, res.data.deptos, this.state.viewingStatus);
            this.setState({totals:res.data.totals, municipios: res.data.munis, departamentos:res.data.deptos});
        }).catch( (error) =>{
            console.log(error);
        });
    }

    componentDidMount() {
      this.fetchStatusTotals();
    }
  
    getDateFormat(date){
      return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
    }

    onDateRangeChange (startDate, endDate){
      this.setState({
        filterDateStart: this.getDateFormat(startDate),
        filterDateEnd: this.getDateFormat(endDate)
      }, () => this.fetchStatusTotals());
    }
  
    handleTabChange (event, newValue) {
      this.buildMunisAndDeptos(this.state.municipios, this.state.departamentos, newValue);
      this.setState(
        { 
          value: newValue, 
          viewingStatus: newValue
        }
      );
    }

    onFilterChange(filters){
      let filterString = '';
      for (let f of filters){
        filterString += (filterString.length === 0) ? f.sql : `|${f.sql}`;
      }
      this.setState({filterString});
    }

    getTotalByStatus(status){
      if (this.state.totals.length === 0) return 0;

      //all
      if (status===99){
        let total = 0;
        for (let s of this.state.totals){
          total+= parseInt(s.Total)
        }
        return total;
      }

      //specific status
      let s = this.state.totals.find ( item => parseInt(item.StatusCode) === status);
      if (s && s.hasOwnProperty('Total')) return parseInt(s.Total);
      
      return 0;
      
    }

    render() {
        const handleTabChange = this.handleTabChange;
        const value = this.state.value;
        const {classes} = this.props;       

        const tabsStatus = [0, 1, 2, 3, 4, 6, 5, 99];
        const labelRecoleccion = `Rec. pendiente (${this.getTotalByStatus(0)})`;
        const labelRuta = `En ruta (${this.getTotalByStatus(1)})`;
        const labelAlerta = `Alerta (${this.getTotalByStatus(3)})`;
        const labelEntregado = `Entregado (${this.getTotalByStatus(2)})`;
        const labelDevolucion = `Devolución (${this.getTotalByStatus(5)})`;
        const labelCerrado = `Cerrada (${this.getTotalByStatus(6)})`;
        const labelCancelado = `Cancelado (${this.getTotalByStatus(4)})`;
        const labelTodos = `Todos (${this.getTotalByStatus(99)})`;
        return (
          <Card className={classes.card}>

            <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
            <AppBar position="static" color="inherit">
                <Toolbar>
                  <Tabs
                  value={value}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  scrollButtons="auto"
                  variant="scrollable"
                  textColor="primary"
                  style={{flexGrow:'1'}}
                  aria-label="scrollable force tabs example"
                  >
                      <Tab label={labelRecoleccion} value={0} icon={<FontAwesomeIcon icon={faClock} style={{fontSize:'20px'}}/>} {...a11yProps(0)} />
                      <Tab label={labelRuta} value={1} icon={<FontAwesomeIcon icon={faArrowCircleRight} style={{fontSize:'20px'}}/>} {...a11yProps(1)} />
                      <Tab label={labelAlerta} value={3} icon={<FontAwesomeIcon icon={faExclamationTriangle} style={{fontSize:'20px'}}/>} {...a11yProps(2)} />
                      <Tab label={labelEntregado} value={2} icon={<FontAwesomeIcon icon={faCheckCircle} style={{fontSize:'20px'}}/>} {...a11yProps(3)} />
                      <Tab label={labelDevolucion} value={5} icon={<FontAwesomeIcon icon={faUndo} style={{fontSize:'20px'}}/>} {...a11yProps(4)} />
                      <Tab label={labelCerrado} value={6} icon={<FontAwesomeIcon icon={faLockAlt} style={{fontSize:'20px'}}/>} {...a11yProps(5)} />
                      <Tab label={labelCancelado} value={4} icon={<FontAwesomeIcon icon={faBan} style={{fontSize:'20px'}}/>} {...a11yProps(6)} />
                      <Tab label={labelTodos} value={99} icon={<FontAwesomeIcon icon={faList} style={{fontSize:'20px'}}/>} {...a11yProps(7)} />
                  </Tabs>
                  
                  <DateRangeInput onDateRangeChange = {this.onDateRangeChange}/>

                </Toolbar>
            </AppBar>

            <GridContainer justify="center" style={{borderBottom:"1px solid #e0e0e0", padding:'5px'}}>
                <GridItem xs={12} style={{lineHeight:'40px'}}>
                    <DataFilter filters={filters} onFilterChange={this.onFilterChange}/>
                </GridItem>
            </GridContainer>

            {tabsStatus.map ( (item, index) => {
              return(
                <TabPanel value={value} index={item} key={index}>
                    <TrackingTable 
                      status    = {this.state.viewingStatus} 
                      dateStart = {this.state.filterDateStart}
                      dateEnd   = {this.state.filterDateEnd}
                      filterString = {this.state.filterString}
                      />
                </TabPanel>
              )
            })}

            </MuiThemeProvider>
           
            </div>
          </Card>
        )  
    }
}

export default withStyles(styles)(TrackingTableContainer)