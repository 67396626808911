import React from "react";
import PropTypes from "prop-types";

import Link from '@material-ui/core/Link';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { faFileExcel, faCheckCircle, faBell } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CircularProgress from '@material-ui/core/CircularProgress';

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import Snackbar from "components/Snackbar/Snackbar.js";
import NotificationImportant from "@material-ui/icons/NotificationImportant";


const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
  uploadArea : {
    border: "2px dashed #eeeeee",
    borderRadius: "10px",
    width: "80%",
    margin: "0 auto",
    padding: "100px",
    textAlign: "center",
    fontSize: "20px",
    marginTop: "50px"
  },
  ...buttonStyle,
  uploadAreaActive:{
    border: "2px dashed #a72abd",
    borderRadius: "10px",
    width: "80%",
    margin: "0 auto",
    padding: "100px",
    textAlign: "center",
    fontSize: "20px",
    marginTop: "50px"
  },
  uploadIcon:{
    display:"block",
    fontSize:"80px",
    margin: "0 auto",
    color: "#eeeeee",
    padding:"5px"
  },
  uploadIconActive:{
    display:"block",
    fontSize:"80px",
    margin: "0 auto",
    color: "#1d6f42",
    padding: "5px"
  },
  alertText:{
    fontSize:"25px",
    lineHeight:"30px"
  }
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile : null,
      draggingOver : false,
      fileProcessed: false,
      error: '',
      snack:true
    };

    this.dragDeepCount = 0;

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    return this.state.fileProcessed;
  }

  postFile(selectedFile){
    const file = selectedFile;
    const data = new FormData();
    data.append('file', file);
    //API request
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.post(apiURL+"/merchant/xlsx/", data)
    .then( (res) => {
      this.props.setDataPipe(res.data.filedata, res.data.draftNo);
      this.setState({fileProcessed:true});
      this.props.nextFunction();
    }).catch((error) =>{
      if (error.toString().includes('Network Error')){
        this.setState({error: "No ha sido posible conectarse al servidor. Por favor, intenta más tarde."});
      }else{
        if (error.response && error.response.data && error.response.data.error){
          this.setState({error: error.response.data.error});
        }else{
          this.setState({error: "Ha ocurrido un error inesperado, por favor, intenta de nuevo."}); 
        }
      }
    });
  }

  onChangeHandler(event){
    const file = event.target.files[0];
    event.target.value = null; //clean up input field 

    if (!file.name.toLowerCase().endsWith('xlsx')){
      this.setState({error:'Solamente puedes cargar archivos en formato Microsoft XLSX.'});
      return;
    }

    this.setState({selectedFile:file}, () => this.postFile(this.state.selectedFile)); 
  }

  componentDidMount() {
    this.dropArea.addEventListener('dragover', this.handleDragOver);
    this.dropArea.addEventListener('drop', this.handleDrop);
    this.dropArea.addEventListener('dragenter', this.handleDragEnter);
    this.dropArea.addEventListener('dragleave', this.handleDragLeave);
  }

  componentWillUnmount() {
      this.dropArea.removeEventListener('dragover', this.handleDragOver);
      this.dropArea.removeEventListener('drop', this.handleDrop);
      this.dropArea.removeEventListener('dragenter', this.handleDragEnter);
      this.dropArea.removeEventListener('dragleave', this.handleDragLeave);
  }

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      this.setState({draggingOver:false});
      this.dragDeepCount = 0;

      const files = [...e.dataTransfer.files];

      if (files.length > 1){
        this.setState({error:'Solamente es permitido cargar un archivo a la vez.'});
        return;
      }

      const file = files[0];

      if (!file.name.toLowerCase().endsWith('xlsx')){
        this.setState({error:'Solamente puedes cargar archivos en formato Microsoft XLSX.'});
        return;
      }

      this.setState({
        selectedFile:file
      }, () => {
          this.postFile(this.state.selectedFile);
      }); 
  }

  handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({draggingOver:true});
    this.dragDeepCount++;
  }

  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragDeepCount--;
    if (this.dragDeepCount === 0){
      this.setState({draggingOver:false});
    }    
  }

  setDropArea = (dropArea) => {
    this.dropArea = dropArea;
  };
  
  tryAgain = () => {
    this.dragDeepCount = 0;
    this.setState({
      selectedFile : null,
      draggingOver : false,
      fileProcessed: false,
      error: ''
    });
  }

  render() {

    const { classes } = this.props;

    if (this.state.selectedFile){
      let content = '';
      if (!this.state.fileProcessed){
        content = (<div>Procesando archivo... <CircularProgress size={20} style={{color:"#e53935"}}/></div>);
      }else{
        content = (<div>Archivo procesado <FontAwesomeIcon icon={faCheckCircle} style={{color:"#4caf50"}} /> </div>);
      }

      var uploadText = (
        <div>
          <div style={{fontWeight:"900"}}>{this.state.selectedFile.name}</div>
          <div style={{marginTop:"20px"}}>
            {content}
          </div> 
        </div>
      );
    }else{ 
      var uploadText = (
        <div>
          Arrastra tu archivo de excel aquí o <Link
          component="button"
          underline="always"
          style={{color:"#00cae3",fontSize:"20px"}}
          onClick={(e) => {
              e.preventDefault();
              document.getElementById('parcels_file').click();
          }}>selecciónalo aquí</Link>.
        </div>
      );
    } 

    let popup = '';
    if (this.state.fileProcessed && 1==0){
      popup = (
        <SweetAlert
            success
            style={{ display: "block", marginTop: "-175px", width:"40%" }}
            title=""
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnText="Continuar"
            confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
            confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
          >
              <div className={classes.alertText}>
                Excelente, tu archivo ha sido procesado exitosamente.
              </div>
          </SweetAlert>
      );
    }

    if (this.state.error.length > 0){
      popup = (
        <SweetAlert
            error
            style={{ display: "block", marginTop: "-175px", width:"40%" }}
            title="Ouch! Hubo un error"
            onConfirm={() => this.tryAgain()}
            onCancel={() => this.tryAgain()}
            confirmBtnText="Intentar nuevamente"
            confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
            confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
          >
              <div className={classes.alertText}>
                {this.state.error}
              </div>
          </SweetAlert>
      );
    }

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>          
          <div style={this.state.draggingOver ? style.uploadAreaActive :style.uploadArea} ref={this.setDropArea}>
            <FontAwesomeIcon 
              icon={faFileExcel} 
              style={ (this.state.selectedFile || this.state.draggingOver ) ? style.uploadIconActive : style.uploadIcon}
            />
            {uploadText}
            <input type="file" name="parcels_file" id="parcels_file" onChange={this.onChangeHandler} accept=".xlsx" style={{display:"none"}}/>
          </div>
          <p style={{textAlign:"center", fontSize:"20px", marginTop:"30px", marginBottom:"50px"}}>
            Si aún no tienes el formato que requerimos, puedes&nbsp;
            <Link
            component="button"
            underline="always"
            style={{color:"#00cae3",fontSize:"20px"}}
            onClick={() => {
              alert("Descargar formato de ejemplo");
            }}>bajarlo aquí</Link>.
          </p>
          {popup}
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
