import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table";

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
}

class ParcelChange extends React.Component { 

    constructor(props){
        super(props);

        this.state = {
            changesData: [],
            noData: false
        }

        this.pullData = this.pullData.bind(this);
    }

    componentDidMount(){
        this.pullData();
    }

    pullData () { 

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        axios.get(apiURL + "/backoffice/parcel/changes/"+this.props.parcelId)
            .then( (res) => {
                if (res.data.length === 0){
                    this.setState({noData:true});
                }else{
                    this.setState({changesData: res.data});
                }
            }).catch( (error) =>{
                console.log(error);
            });

    }

    render(){
        
        const { classes } = this.props;

        let dataRows = [];

        for(let c of this.state.changesData){
            dataRows.push(
                [
                    c.Field,
                    c.ValueBefore,
                    c.ValueAfter,
                    c.Reason,
                    c.RequestUser + ' el ' + c.RequestDate,
                    c.Decision,
                    c.DecisionUser + ' el ' + c.DecisionDate
                ]
            )
        }

        let customHeadCellClasses = Array(7).fill(classes.tableHeader);

        let customCellClasses = Array(7).fill(classes.cell);

        return (
            <GridContainer justify="center">
                <GridItem xs={12}>
                    <Table
                        stripped
                        showLoading
                        noData = {this.state.noData}
                        tableHead={["Atributo", "Valor anterior", "Valor nuevo", "Motivo del cambio", "Solicitado por", "Decisión", "Aprovado por"]}
                        tableData={dataRows}
                        
                        customHeadCellClasses={customHeadCellClasses}
                        customHeadClassesForCells={[0,1,2,3,4,5,6]}

                        customCellClasses={customCellClasses}
                        customClassesForCells={[0,1,2,3,4,5,6]}

                        customRowsClass = {classes.row}
                    />
                </GridItem>
            </GridContainer>
        );

    }

}

export default withStyles(style)(ParcelChange);