/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";

import DeliveryView from 'components/Delivery/DeliveryView';

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const style = {
    ...styles,
    ...formStyles,
    modalTitle:{
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
        color: "#555555",
        fontWeight: "900"
    },
    modal:{
        width:"90%",
        maxWidth:"90%",
        height: "90%",
        maxHeight: "90%"

    },
    darkerLabel:{
        color:"#878787"
    },
    errorLabel:{
        color: "#e53935"
    },
    modalFooter:{
        margin:"0 auto"
    },
    error:{
        color: "#e53935",
        fontSize: "15px",
        fontWeight: "300"
    },
    select:{
        marginTop:"32px"
    },
    windowToolbar:{
        position: 'absolute',
        top : '5px',
        right : '25px',
        textAlign: "right",
        fontSize:"22px",
        color:"#555555"
    }
}

class ModalDeliveryView extends React.Component{

    constructor(props){
        super(props);

        this.closeModal = this.closeModal.bind(this);

        this.state = {
            open:this.props.open,
            trackingNo: this.props.trackingNo,
        };
    }

    closeModal(){
        this.setState({open:false});
        this.props.onClose();
    }

    componentDidUpdate(prevProps) {
        if (this.props.trackingNo !== prevProps.trackingNo){
            this.setState({...this.props});
        }
    }

    render(){

        const { classes } = this.props;
        const trackingNo = this.state.trackingNo;

        return(
            <Dialog
                classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
                }}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeModal}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <div className={classes.windowToolbar}>
                    <FontAwesomeIcon icon={faTimes} style={{cursor:"pointer"}} onClick={this.closeModal}/>
                </div>
                <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
                >

                    <DeliveryView trackingNo={trackingNo} />

                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={this.closeModal} color="danger">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(style)(ModalDeliveryView);