import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
import Table from "components/Table/Table.js";
import TrackingStatus from "components/TrackingStatus/TrackingStatus.js";
import ModalDeliveryView from 'components/Delivery/ModalDeliveryView';
import {formatAmount} from 'utils/format';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import auth from "service/authentication";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { faDollarSign} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from "components/CustomButtons/Button.js";
import Menu from '@material-ui/core/Menu';
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Card from "components/Card/Card.js";
import ModalSettlementTransaction from "./ModalSettlementTransaction";
import InputLabel from "@material-ui/core/InputLabel";
import cloneDeep from 'lodash.clonedeep';
import SortColumn from '../SortColumn/SortColumn';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');


const styles = {
    ...customSelectStyle,
    ...formStyles,
    ...buttonStyle,
    card: {
        display: "inline-block",
        position: "relative",
        width: "100%",
        margin: "25px 0",
        boxShadow: "0 1px 4px 0 rgba(0,0,0, 0.14)",
        borderRadius: "6px",
        color: "rgba(0,0,0, 0.87)",
        background: "white",
        transition: "all 300ms linear",
        minHeight: "410px"
      },
    tableContainer: {
        width:"97%",
        margin:"0 auto",
        paddingTop: "15px"
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
    hide : {
        display: 'none'
    }
}


class MerchantSettlement extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            deliveries: [],
            showParcel : '',
            noData: false,
            selectedTracks: [],
            selectionError : '',
            showModal: false,
            checkAll: false,
            sorting: null,
            sortingDirection : 1
        };
       
        this.viewParcel = this.viewParcel.bind(this);
        this.hideViewWindow = this.hideViewWindow.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleListChange = this.handleListChange.bind(this);
        this.openClosingWindow = this.openClosingWindow.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.sortTable = this.sortTable.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData(){

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

        axios.get(apiURL + "/backoffice/parcel/tosettle/merchant")
        .then( (res) => {
            if (res.data.length > 0){
                this.setState({noData:false, deliveries:res.data});
            }else{
                this.setState({noData:true, deliveries:[]});
            }
        }).catch( (error) =>{
            console.log(error);
        });
       
    }

    viewParcel(trackingNo){
        this.setState({showParcel:trackingNo});
    }

    hideViewWindow(){
        this.setState({showModal:false, showParcel: ''});
    }

    handleListChange (event) {
        let tracks = this.state.selectedTracks;
        if (event.target.checked){
            tracks.push(event.target.value);
        }else{
            let index = tracks.indexOf(event.target.value);
            tracks.splice(index, 1);
        }
        this.setState({selectedTracks: tracks});
    }

    openClosingWindow () {
       
        if (this.state.selectedTracks.length === 0){
            this.setState({selectionError: 'Selecciona por lo menos una guía'});
            return
        }

        this.setState({showModal:true});
    }

    onSave(){
        this.fetchData();
    }

    handleCheckAll(event){
        if (event.target.checked){
            let tracks = this.state.deliveries.map ( item => item.trackingNo );
            this.setState({checkAll:true, selectedTracks:tracks});
        }else{
            this.setState({checkAll:false, selectedTracks: []});
        }
    }

    sortTable(column){
        let sortingDirection = this.state.sortingDirection;
        if (this.state.sorting === column){
            sortingDirection = -1 * sortingDirection;
        }else{
            sortingDirection = 1;
        }
        this.setState({sorting: column, sortingDirection});
    }

    render(){

        const { classes } = this.props;
        let dataRows = [];
        let rowNo = 1;
        let deliveries = this.state.deliveries;
       
        if (this.state.sorting){
            if (this.state.sorting === 'merchant'){
                deliveries = this.state.deliveries.sort ( (a, b) => { 
                    if (a.merchant.toUpperCase() > b.merchant.toUpperCase()){
                        return this.state.sortingDirection;
                    }else if (a.merchant.toUpperCase() < b.merchant.toUpperCase()){
                        return -1*this.state.sortingDirection;
                    }
                })
            }else if (this.state.sorting === 'dateSent'){
                deliveries = this.state.deliveries.sort ( (a, b) => { 
                    let aArr = a.created.split("/");
                    let bArr = b.created.split("/");
                    let x = new Date('20'+aArr[2] + '-' + aArr[1] + '-' + aArr[0]);
                    let y = new Date('20'+bArr[2] + '-' + bArr[1] + '-' + bArr[0]);
                    if (x > y){
                        return this.state.sortingDirection;
                    }else if (x < y){
                        return -1*this.state.sortingDirection;
                    }
                })
            }
        }

        for (let d of deliveries){
            const trackingNo = (
                <div>
                <Link
                    component="button"
                    underline="always"
                    style={{fontSize:"14px", userSelect:"text", color:"purple"}}
                    onClick={() => {this.viewParcel(d.trackingNo)}}>{d.trackingNo}</Link>
                </div>
            );
            const tStatus = <TrackingStatus status={d.status} statusDescription={d.statusDescription}/>
            const found = this.state.selectedTracks.includes(d.trackingNo);
            const checkbox = (
                <Checkbox
                    checked = {found}
                    tabIndex={-1} onChange={this.handleListChange}
                    inputProps={{value: d.trackingNo}}
                    style = {{padding:'0px'}}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{checked: classes.checked,root: classes.checkRoot}}
                />
            );
            dataRows.push(
                [
                    checkbox,
                    rowNo,
                    d.carrierName,
                    d.merchant,
                    trackingNo,
                    d.packageNo,
                    d.created,
                    tStatus,
                    d.recipient,
                    formatAmount(d.cod),
                    d.municipio,
                    d.departamento
                ]
            )
            rowNo++;
        }

        let customCellClasses = [classes.right];
        let customClassesForCells = [9];

        let customHeadCellClasses = [
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader + " " + classes.right,
            classes.tableHeader,
            classes.tableHeader
        ];
        let customHeadClassesForCells = [0,1,2,3,4,5,6,7,8,9,10,11,12];
        
        let selectionError;
        if (this.state.selectionError.length > 0){
            selectionError = (
                <SweetAlert
                error
                style={{ display: "block", marginTop: "-175px", width:"40%" }}
                title={this.state.selectionError}
                onConfirm={() => this.setState({selectionError:''})}
                onCancel={() => this.setState({selectionError:''})}
                confirmBtnText="Ok"
                confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
                />
            );
        }

        let modalClosing = '';
        if (this.state.showModal){
            let selectedDeliveries = deliveries.filter( item => this.state.selectedTracks.includes(item.trackingNo ));
            let cloned = cloneDeep(selectedDeliveries);
            modalClosing = (
                <ModalSettlementTransaction 
                    deliveries={cloned} 
                    open = {true}
                    onClose={this.hideViewWindow}  
                    onSave = {this.onSave}
                    />
            )
        }

        let modalParcel = '';

        if (this.state.showParcel !== ''){
            modalParcel =  (
                <ModalDeliveryView
                    open = {true}
                    trackingNo={this.state.showParcel}
                    onClose={this.hideViewWindow}  
                />
            );
        }
       

        return(
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {modalParcel}
                        {modalClosing}
                        {selectionError}
                    
                        <GridItem xs={12} sm={12} md={12}>
                            <h3>Liquidación de guías al comercio</h3>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            Seleccion las guías que deseas liquidar y luego presiona "Liquidar guías".
                        </GridItem>

                        <div className={classes.tableContainer}>

                        <div style={{textAlign:'left'}}>
                            <Button color="primary" onClick={this.openClosingWindow} 
                                    style={{textAlign:'right', cursor:'pointer'}}>
                                <FontAwesomeIcon icon={faDollarSign} style={{fontSize:"20px", marginLeft:"5px"}}/> Liquidar guías
                            </Button>
   
                        </div>

                        <Table
                        showLoading
                        noData = {this.state.noData}
                        striped
                        tableHead={[
                            <Checkbox
                                checked = {this.state.checkAll}
                                tabIndex={-1} onChange={this.handleCheckAll}
                                style = {{padding:'0px'}}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{checked: classes.checked,root: classes.checkRoot}}
                            />,,
                            "No.",
                            "Transportista",
                            <SortColumn name='Comercio' code="merchant" 
                                        sorting={this.state.sorting} 
                                        onClick={this.sortTable} 
                                        direction={this.state.sortingDirection}/>,
                            "Guía de envío",
                            "Ref.",
                            <SortColumn name='Fecha de envío' code="dateSent" 
                                        sorting={this.state.sorting} 
                                        onClick={this.sortTable} 
                                        direction={this.state.sortingDirection} />,
                            "Estado actual",
                            "Destinatario",
                            "Monto COD",
                            "Municipio",
                            "Departamento"
                        ]}
                        tableData={dataRows}

                        customCellClasses={customCellClasses}
                        customClassesForCells={customClassesForCells}

                        customHeadCellClasses={customHeadCellClasses}
                        customHeadClassesForCells={customHeadClassesForCells}

                        />

                        </div>
                    </Card>
               </GridItem>
            </GridContainer>
        );
    }

}

export default withStyles(styles)(MerchantSettlement);
