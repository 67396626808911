import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Link from '@material-ui/core/Link';
import SweetAlert from "react-bootstrap-sweetalert";
import { faTrashAlt, faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress';


const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');
const fileDownload = require('js-file-download');

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...buttonStyle,
  header:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap"
  },
  center:{
    textAlign:"center"
  },
  right:{
    textAlign:"right"
  },
  cellNoWrap:{
    whiteSpace: "nowrap"
  },
  buttonGenerar:{
    textTransform:"none",
    fontWeight:'900'
  },
  downloadText:{
    fontSize:"28px", 
    color:"#555555", 
    fontWeight:"900", 
    marginTop:"35px", 
    marginBottom:"40px"
  }
};

class Step4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      alert:'guias',
      generating: false
    };

    this.printTracking = this.printTracking.bind(this);
    this.printManifiesto = this.printManifiesto.bind(this);
    this.print = this.print.bind(this);
  }
  sendState() {
    return this.state;
  }

  componentDidMount(){
    if (document.querySelector('.makeStyles-mainPanel-2')){
      document.querySelector('.makeStyles-mainPanel-2').scrollTop = 0;
    }
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.put(apiURL+"/users/draftshipments/"+this.props.draftNo, {step:'PRINT'})
    .then( (res) => {
       console.log('Draft was updated!');
    }).catch((error) =>{
      console.log('Draft could not be updated. '+error);
    });
  }

  hideAlert(){

    if (this.state.alert === 'guias'){
      this.setState({alert:'manifiesto'})
    }else if (this.state.alert === 'manifiesto'){
      this.setState({alert:''});
      this.props.nextFunction();
    }
  }

  print(doc){

    this.setState({generating:true});

    let endpoint = '/merchant/parcels/print';
    let baseName = 'Guías';
    if (doc === 'manifiesto'){
      endpoint = '/merchant/manifiesto/print';
      baseName = 'Manifiesto';
    }

    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.post(apiURL+endpoint, this.props.deliveries, {responseType: 'blob'})
    .then( (res) => {
      var filename = baseName + "-" + new Date().getTime() + ".pdf";      
      fileDownload(res.data, filename);
      this.hideAlert();
      this.setState({generating:false});
    }).catch((error) =>{
      console.log(error);
      this.setState({generating:false});
    });

  }

  printTracking(){
    this.print('tracking');
  }

  printManifiesto(){
    this.print('manifiesto');
  }

  render() {
    let deliveries = this.props.deliveries;
    const { classes } = this.props;
    let dataRows = [];
    deliveries.forEach( (d, key) => { 
      const actions = (
        <div>
          <FontAwesomeIcon icon={faTrashAlt} style={{color:'#f44336', marginLeft:'10px'}}/>
        </div>
      );
      const trackingNo = (
         <div>
           <Link
            component="button"
            underline="always"
            style={{fontSize:"14px", userSelect:"text", color:"#9c27b0"}}
            onClick={() => {
              alert("Detalle guía");
            }}>{d.trackingno.value}</Link>
         </div>
      );
      dataRows.push(
        [
          actions,
          key + 1,
          trackingNo,
          d.name.value,
          d.packageno.value,
          d.description.value,
          d.cod.value,
          d.cellphone.value,
          d.address.value,
          d.municipio.value,
          d.departamento.value,
          d.referencias.value
        ]
      )
    });
   
    let alert = '';
    if (this.state.alert !== ''){
      let action = this.printTracking;
      let msg = 'Imprime tu PDF con las guías';
      if (this.state.alert === 'manifiesto'){
        msg = 'Imprime tu PDF con el manifiesto';
        action = this.printManifiesto;
      }

      let buttonText = 'Descargar';
      if (this.state.generating){
        buttonText = (
          <div>Descargando... <CircularProgress size={20} style={{marginLeft:'5px', color:"#ffffff"}}/></div>
        )
      }

      alert = (
        <SweetAlert
            style={{ display: "block", marginTop: "-175px", width:"40%" }}
            title=""
            closeOnClickOutside={false}
            onConfirm={action}
            // onCancel={() => this.hideAlert()}
            disabled = {this.state.generating}
            confirmBtnText= {buttonText}
            confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
            confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
          >
              <FontAwesomeIcon icon={faDownload} style={{fontSize:"75px", color:"#555555"}}/>
              <div className={classes.downloadText}>
                {msg}
              </div>
          </SweetAlert>
      );
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
          <Table
              striped
              tableHead={[
                "Acciones",
                "No.",
                "Guía de Envío",
                "Nombre",
                "Factura",
                "Contenido",
                "Monto COD",
                "No. Celular",
                "Dirección",
                "Municipio",
                "Departamento",
                "Instrucciones"
              ]}
              tableData={dataRows}

              customCellClasses={[classes.center, classes.center, classes.cellNoWrap, classes.cellNoWrap, classes.right]}
              customClassesForCells={[0,1,2,5,7]}

              customHeadCellClasses={ Array(13).fill(classes.header)}
              customHeadClassesForCells={[0,1,2,3,4,5,6,7,8,9,10,11,12]}

            />

            {alert}

          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

Step4.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step4);