import React from "react";

import { faCheckCircle, faArrowCircleRight, faExclamationTriangle, faClock, faBan, faUndo, faLockAlt, faLock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from '@material-ui/core/Link';

class TrackingStatus extends React.Component{

    render(){
        const status = this.props.status;
        let statusLabel = '';
        let faIcon = undefined;
        let iconStyle = {};
        switch(status){
            case 0: //Pendiente recolección
                statusLabel = this.props.statusDescription;
                faIcon = faClock;
                iconStyle = {
                    color : "#a72abd",
                    fontSize: "38"
                }
                break;
            case 1: //En ruta
                statusLabel = this.props.statusDescription;
                faIcon = faArrowCircleRight;
                iconStyle = {
                    color: "#555555"
                }
                break;
            case 2: //Entregado
                statusLabel = this.props.statusDescription;
                faIcon = faCheckCircle;
                iconStyle = {
                    color: "#4caf50"
                }
                break;
            case 3: //alerta
                statusLabel = (
                    // <Link
                    // component="button"
                    // underline="always"
                    // style={{color:"#00cae3",fontSize:"14px"}}
                    // onClick={() => {
                    // alert("Detalle de la alerta");
                    // }}>{this.props.statusDescription}</Link>
                    this.props.statusDescription
                );
                faIcon = faExclamationTriangle;
                iconStyle = {
                    color: "#f44336"
                }
                break;
            case 4: // cancel
                statusLabel =  this.props.statusDescription
                faIcon = faBan;
                iconStyle = {
                    color: "#f44336"
                }
                break;
            case 5: // return
                statusLabel =  this.props.statusDescription
                faIcon = faUndo;
                iconStyle = {
                    color: "#f44336"
                }
                break;
            case 6:
                statusLabel =  this.props.statusDescription
                faIcon = faLockAlt;
                iconStyle = {
                    color: "#999"
                }
                break;
            default:
                statusLabel = this.props.statusDescription;
                faIcon = faArrowCircleRight;
                iconStyle = {
                    color: "#555555"
                }
        }
        iconStyle.fontSize = "18px";
        return(
            <div style={{whiteSpace: "nowrap"}}>
                <FontAwesomeIcon icon={faIcon} style={iconStyle}/>
                <div style={{marginLeft:"7px", display:"inline"}}>
                    {statusLabel}
                </div>
            </div>
        );

    }

}

export default (TrackingStatus);