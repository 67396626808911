import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { faPlus, faContainerStorage } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// react component plugin for creating a beautiful datetime dropdown picker
import auth from "service/authentication";
import Table from "components/Table/Table";
import {formatAmount} from 'utils/format';
import Delete    from 'components/CRUDLinks/Delete';
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "components/CustomButtons/Button.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import CustomInput from "components/CustomInput/CustomInput.js";


const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const serviceType = [
    {code: 'SHIPPING', name:'Envío'},
    {code: 'COD', name: 'Cobro COD'},
    {code: 'RETURN', name: 'Devolución'},
    {code: 'OTHER', name: 'Otro'}
]


const style = {
    ...buttonStyle,
    ...formStyles,
    ...customSelectStyle,
    row:{
        height: "28px !important"
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    headerRight:{
        textAlign:'right',
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    cellRight : {
        textAlign:'right',
        fontSize: '17px',
        whiteSpace: 'nowrap'
    },
    tableHeaderAction: {
        width:'10px'
    },
    alertText:{
      fontSize:"20px",
      lineHeight:"25px"
    },
    hidden:{
        display: "none"
    },
    inputRight:{
        textAlign: 'right'
    },
    inline:{
        display: 'inline'
    }
}

function ShowAmount(props) {
    const { label, amount, deleted, ...other } = props;
  
    let subStyle = {display:'inline'};
    let amountStyle = {display:'inline'};

    if (deleted){
        subStyle.color = 'gray';
        subStyle.fontStyle = 'italic';
        subStyle.textDecoration = 'line-through';
        amountStyle.color = 'gray';
        amountStyle.fontStyle = 'italic';
        amountStyle.textDecoration = 'line-through';
    }else{
        if (amount < 0){
            amountStyle.color = 'red';
        }
    }

    return (
        <>
            <sub style={subStyle}> {label} </sub>{` `}
            <div style={amountStyle}> Q.  {formatAmount(amount)} </div> 
        </>
    );
}

function ServiceName( props ){
    const { code, ...other} = props;

    let name = '';

    for (let s of serviceType){
        if (s.code === code){
            name = s.name;
            break;
        }
    }

    return ( name );
}

function ServiceDescription( props ){
    const  { service, ...other} = props;

    if (service.Deleted){
        return (
            <div style={{color:'gray', fontStyle:'italic', fontDecoration:'line-through'}}> 
                {service.Description} <sub> ({service.UserDeleted} el {service.DeletedDate}) </sub>
            </div>
        )
    }else{
        return (
            <>
                {service.Description} <sub> ({service.ServiceRateId ? 'Autogenerado' : service.UserCreated} el {service.CreatedDate}) </sub>
            </>
        )
    }

}

class ParcelService extends React.Component {

    constructor ( props ) {
        super(props);
        this.state = {
            financeData: [],
            serviceToDelete : -1,
            deleting: false,
            showDeleted: false,
            addRow: false,
            newService: 0,
            newDescription: '',
            newCost: null,
            newSalesPrice: null,
            error: '',
            noData: false
        }

        this.pullData = this.pullData.bind(this);
        this.askDelete = this.askDelete.bind(this);
        this.deleteService = this.deleteService.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.saveService = this.saveService.bind(this);
    }

    componentDidMount () {
        this.pullData();
    }

    pullData(){
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        axios.get(apiURL + "/backoffice/parcel/service/"+this.props.parcelId)
            .then( (res) => {
                if (res.data.length === 0){
                    this.setState({noData: true});
                }else{
                    this.setState({noData:false, financeData:res.data})
                }
                
            }).catch( (error) =>{
                this.setState({noData: true, financeData:[]});
                console.log(error);
            });
    }

    askDelete(serviceId){
        this.setState({serviceToDelete:serviceId});
    }

    dontDelete(){
        this.setState({serviceToDelete:-1});
    }
    deleteService(e) {

        this.setState({deleting:true} , () => {
            
            let serviceId = this.state.serviceToDelete;
            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            axios.delete(apiURL+"/backoffice/parcel/service/"+serviceId, {data: {reason:e}})
            .then( (res) => {
                let services = this.state.financeData;
                let index = services.findIndex( element => element.ParcelServiceId == serviceId );
                services.splice(index,1);
                this.setState({financeData : services, serviceToDelete:-1, deleting:false} , () => this.pullData());
            }).catch((error) =>{
                this.setState({serviceToDelete:-1, deleting:false});
                console.log('Service could not be disposed. '+error);
            });

        });
    }

    onAddClick(){
        this.setState({
            addRow: true,
            newService: '',
            newDescription: '',
            newCost: null,
            newSalesPrice: null,
            error:''
        })
    }

    onInputChange(field, event) {
        let o = new Object();
        o[field] = event.target.value;
        this.setState(o);
    }

    saveService(){
        if (this.state.newService.length === 0 || this.state.newDescription.length < 5 
            || this.state.newCost.toString().length === 0 || this.state.newSalesPrice.toString().length === 0){
                this.setState({error:'Todos los campos son obligatorios.'})
                return;
        }
        if (isNaN(this.state.newCost) || isNaN(this.state.newSalesPrice)){
            this.setState({error:'El costo y el precio deben ser números.'});
            return;
        }
        
        let data = {
            parcelId  : this.props.parcelId,
            cost : parseFloat(this.state.newCost),
            salesPrice : parseFloat(this.state.newSalesPrice),
            description: this.state.newDescription,
            serviceCode: this.state.newService
        }

        this.setState({saving : true, error: ''}, () => {

            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            axios.post(apiURL + "/backoffice/parcel/service/", data).
                then ( (res) => { 
                    this.setState({addRow:false, saving:false} , () => this.pullData());
                } 
            ).catch ( ( error ) => { 
                let msg;
                if (error.response && error.response.data && error.response.data.error){
                    msg =  error.response.data.error;
                }else{
                    msg = "Ha ocurrido un error inesperado, por favor, intenta de nuevo."; 
                }
                
                this.setState({error: msg, saving: false}); 
            } );

        });

    }

    render () {

        const { classes } = this.props;

        let financeData = this.state.showDeleted ? this.state.financeData : this.state.financeData.filter ( item => item.Deleted === false );

        console.log(financeData);

        let eliminados = this.state.financeData.length - financeData.length;

        let dataRows = [];
        let totalCost = 0;
        let totalSales = 0;

        /*  Add new */

        if (this.state.addRow){
            let serviceInput = (
                <FormControl style={{width:'200px'}}>
                    <Select MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    value={this.state.newService}
                    onChange={ (event) => this.onInputChange('newService', event) }
                    inputProps={{name: "newService"}}
                    >
                        {serviceType.map ( (s, index) =>{
                            return (
                                <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                        value={s.code} key={index}>
                                            {s.name}
                                </MenuItem>
                            )
                        } )}
                    </Select> 
                </FormControl>
            )

            let notesInput = (
                <CustomInput
                    id={"descriptionInput"} 
                    inputProps={{
                        placeholder: 'Descripción del servicio',
                        value: this.state.newDescription
                        
                    }}
                    formControlProps={{
                        onChange: (event) => this.onInputChange('newDescription', event),
                        style: {width:'450px'}
                    }}
                />
            )

            let costInput = (
                <CustomInput
                    id={"costInput"} 
                    inputProps={{
                        value: this.state.newCost,
                        classes : {input: classes.inputRight}
                    }}
                    formControlProps={{
                        onChange: (event) => this.onInputChange('newCost', event),
                        style: {width:'150px'}
                    }}
                />
            )

            let salesInput = (
                <CustomInput
                    id={"saesInput"} 
                    inputProps={{
                        value: this.state.newSalesPrice,
                        classes : {input: classes.inputRight}
                    }}
                    formControlProps={{
                        onChange: (event) => this.onInputChange('newSalesPrice', event),
                        style: {width:'150px'}
                    }}
                />
            )

            dataRows.push(
                [
                    '',
                    serviceInput,
                    notesInput,
                    costInput,
                    salesInput
                ]
            )

            if (this.state.error.length > 0){
                dataRows.push(
                    {
                        colspan: 5,
                        data: [(<div style={{color:'red', width:'100%', textAlign:'center'}}>{this.state.error}</div>)]
                    }
                )
            }

            let buttons;

            if (this.state.saving){
                buttons = (
                    <div style={{width:'100%', textAlign:'center'}}>
                        <CircularProgress size={30} style={{color:"#a72abd"}}/>
                    </div>
                );
            }else{
                buttons = (
                    <div style={{width:'100%', textAlign:'center'}}>
                        <Button simple color="primary" onClick={() => { this.saveService(); }} >
                            Guardar
                        </Button>
                        <Button simple color="danger" onClick={()=>{this.setState({addRow:false})}}>
                            Cancelar
                        </Button>
                    </div>
                )
            }

            dataRows.push(
                {
                    colspan : 5,
                    data :[buttons]
                }
            );
            
        }
        

        /* -Add new */

        for (let f of financeData) {
            let costLabel = '';
            let salesLabel = '';
            let description = '';

            if (f.ServiceRateId){
                costLabel = f.CostMode === 'Flat' ? '(Tarifa)' : '(' + (parseFloat(f.CostRate) * 100) + '%)';
                salesLabel = f.SalesPriceMode === 'Flat' ? '(Tarifa)' : '(' + (parseFloat(f.SalesRate) * 100) + '%)';
            }

            let delBtn = '';
            if (!f.Deleted){
                delBtn = (
                    <Delete style={{color:'#f44336', fontSize:"18px", marginLeft:"15px", cursor:"pointer"}}
                            deleteFunction={() => this.askDelete(f.ParcelServiceId)}/>
                )
                totalCost += parseFloat(f.Cost);
                totalSales += parseFloat(f.SalesPrice);
            }

            dataRows.push([
                delBtn,
                <ServiceName code={f.ServiceCode} />,
                <ServiceDescription service={f} />,
                <ShowAmount label = {costLabel} amount = {f.Cost} deleted = {f.Deleted}/>,
                <ShowAmount label = {salesLabel} amount = {f.SalesPrice} deleted = {f.Deleted}/>
            ])
            
        }

        if (this.state.financeData.length > 0){
            dataRows.push({
                    color:"gray",
                    data:[
                        "",
                        "",
                        <strong>Total</strong>,
                        <strong>Q. {formatAmount(totalCost)}</strong>,
                        <strong>Q. {formatAmount(totalSales)}</strong>
                    ]
                }
            );
        }

        let customHeadCellClasses =[classes.tableHeaderAction, classes.tableHeader, classes.tableHeader, classes.headerRight, classes.headerRight];

        let customCellClasses = [classes.cell, classes.cell,  classes.cell, classes.cellRight, classes.cellRight];


        let popup = '';
        if (this.state.serviceToDelete !== -1){
            let text = this.state.deleting ? 
                        ( <div>Eliminando...  <CircularProgress size={30} style={{color:"#a72abd"}}/> </div>) 
                        : 'Escribe la razón de por qué deseas eliminar el servicio';
            popup = (
              <SweetAlert
                input
                style={{ display: "block", marginTop: "-200px", width:"40%"  }}
                title="Eliminar servicio"
                onConfirm={ e => this.deleteService(e)}
                onCancel={() => this.dontDelete()}
                confirmBtnCssClass={classes.button + " " + classes.success + (this.state.deleting ? " " + classes.hidden : '')}
                cancelBtnCssClass={classes.button + " " + classes.danger + (this.state.deleting ? " " + classes.hidden : '') }
                confirmBtnText="Eliminarlo"
                cancelBtnText="Cancelar"
                showCancel
                reverseButtons={true}
                disabled = {this.state.deleting}
                closeOnClickOutside = {false}
              >
                <div className={classes.alertText}>{text}</div>
              </SweetAlert>
            )
          }

        let toggleLabel = this.state.showDeleted ? 'Ocultar eliminados' : 'Mostrar eliminados' + ((eliminados > 0) ? ` (${eliminados}) ` : '' ) ;

        return (

            <GridContainer justify="center">
                <GridItem xs={12} style={{textAlign:'right', paddingTop:'0px'}}>
                    <FormControlLabel
                        control={
                            <Switch
                            checked={this.state.showDeleted}
                            onChange={event => this.setState({showDeleted: event.target.checked})}
                            value="showDeleted"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar
                            }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        style={{display:'inline'}}
                        label={toggleLabel}
                    /> {' | '}
                    { ( auth.getUser().role === 'backoffice' ) &&
                        <Button color="primary" simple style = {{textTransform:'none', textDecoration:'underline', fontSize:'14px'}} 
                                onClick={ () => this.onAddClick()}
                                classes={{root: classes.inline}}
                                >
                            <FontAwesomeIcon icon={faPlus} style={{fontSize:"20px", marginRight:"5px", color:"purple"}}/>
                            Agregar servicio
                        </Button>
                    }
                </GridItem>
                <GridItem xs={12}>
                    {popup}
                    <Table
                        showLoading
                        noData = {this.state.noData}
                        
                        tableHead={["", "Servicio", "Descripción", "Costo", "Precio del servicio"]}
                        tableData={dataRows}

                        customHeadCellClasses={customHeadCellClasses}
                        customHeadClassesForCells={[0,1,2,3,4]}

                        customCellClasses={customCellClasses}
                        customClassesForCells={[0,1,2,3,4]}

                        customRowsClass = {classes.row}
                    />
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(style)(ParcelService);