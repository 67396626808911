import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "components/Table/Table";
import {formatAmount} from 'utils/format';
import Typography from '@material-ui/core/Typography';
import  {transactionType, GLAccount} from 'service/finance';

const style = {
    row:{
        height: "20px !important"
    },
    cell :{
        padding: "5px !important"
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap",
        padding: "5px !important"
    },
    headerRight:{
        textAlign:'right',
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    cellRight : {
        textAlign:'right',
        fontSize: '17px',
        whiteSpace: 'nowrap'
    },
    right : {
        textAlign: 'right'
    },
    description:{
        width: '70%'
    }
}

class FinanceTransaction extends React.Component {

    constructor( props ){
        super (props);
    }

    render () {

        const { classes } = this.props;

        let dataRows = [];
        let totalDebit = 0;
        let totalCredit = 0;
        
        for (let d of this.props.transaction.detail){
            let tt = this.props.transaction.TransactionType;
            let trackingNo = '';
            if ( tt === transactionType.BANK_DEPOSIT && d.GLAccountCode === GLAccount.COD_TO_SETTLE){
                trackingNo = ' - Guía ' + d.TrackingNo;
            }else if (tt === transactionType.MERCHANT_SETTLEMENT){
                if ( d.GLAccountCode === GLAccount.PROVISION_FOR_AR){
                    trackingNo = ' - Guía ' + d.Reference;
                }else if ( d.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE){
                    trackingNo = ' - ' + d.Reference;
                }
            }
            dataRows.push([
                parseFloat(d.Credit) > 0 ? '...' + d.Account + trackingNo : d.Account,
                parseFloat(d.Debit) === 0 ? '-' : formatAmount (d.Debit),
                parseFloat(d.Credit) === 0 ? '-' : formatAmount (d.Credit)
            ]);
            totalDebit += parseFloat(d.Debit);
            totalCredit += parseFloat(d.Credit);
        }

        dataRows.push({
            color:"gray",
            data: [
                '',
                <strong>{formatAmount(totalDebit)}</strong>,
                <strong>{formatAmount(totalCredit)}</strong>,
            ]
        });

        let customHeadCellClasses =[
            classes.tableHeader + ' ' + classes.description,
            classes.tableHeader + ' ' + classes.right,
            classes.tableHeader + ' ' + classes.right,
        ];

        let customCellClasses = [classes.cell, classes.cell + ' ' + classes.right, classes.cell + ' ' + classes.right, ];

        return (
            <>
            <Typography variant="subtitle2">
                {this.props.transaction.Description}
            </Typography>
            <Table

                tableHead={["Descripción", "Debe", "Haber"]}

                tableData={dataRows}

                customHeadCellClasses={customHeadCellClasses}
                customHeadClassesForCells={[0,1,2,3,4]}

                customCellClasses={customCellClasses}
                customClassesForCells={[0,1,2,3,4]}

                customRowsClass = {classes.row} />
            </>
        )
    }

}

export default withStyles(style)(FinanceTransaction);