import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "components/Table/Table";
import TrackingStatus from "components/TrackingStatus/TrackingStatus";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import auth from "service/authentication";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from 'components/Snackbar/Snackbar';
import NotificationImportant from '@material-ui/icons/NotificationImportant';

var moment = require('moment');
require('moment/locale/es');

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
    ...customSelectStyle,
  tableHeader:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap"
  },
  tableHeaderDate:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap",
    width: "100px",
    textAlign:"center"
  },
  tableHeaderNotes:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap",
    width: '400px'
  },
  cell:{
      borderTop:"0px !important",
      padding: "7px !important",
      whiteSpace: "nowrap"
  },
  cellDate:{
    borderTop:"0px !important",
    padding: "7px !important",
    textAlign: "left"
  },
  row:{
      height: "28px !important"
  },
  tooltip:{
    fontSize: "15px"
  },
  hide : {
    display: 'none'
  }
};

class TrailTable extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            addRow : false,
            statusList : [],
            actionsList : [],
            deptosList : [],
            munisList: [],
            newStatus : '',
            newAction : '',
            newDepto : '',
            newMuni : '',
            newNotes: '',
            newTimestamp: new Date(),
            error : ''
        }
        this.pullFormLookups = this.pullFormLookups.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.onDeptoChange = this.onDeptoChange.bind(this);
        this.onMuniChange = this.onMuniChange.bind(this);
        this.onNotesChange = this.onNotesChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.saveStatus = this.saveStatus.bind(this);
        this.onTimestampChange = this.onTimestampChange.bind(this);
    }

    pullFormLookups() {
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        axios.get(apiURL + "/status").
            then ( (res) => { 
                this.setState({statusList:res.data.status, actionsList:res.data.actions})
            } 
        ).catch ( ( error ) => { console.log(error) } );
        axios.get(apiURL + "/departamentos").
            then ( (res) => { 
                this.setState({deptosList:res.data})
            } 
        ).catch ( ( error ) => { console.log(error) } );
    }

    onAddClick () {
        this.setState(
            {
                addRow:true,
                newStatus : '',
                newAction : '',
                newDepto : '',
                newMuni : '',
                newNotes: '',
                newTimestamp: new Date(),
                error : '',
                saving: false
            }
        );
        this.pullFormLookups();
    }

    onStatusChange (event) {
        this.setState({newStatus:event.target.value});
    }

    onActionChange (event) {
        this.setState({newAction:event.target.value});
    }

    onDeptoChange (event) {
        this.setState({newDepto:event.target.value});
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        axios.get(apiURL + "/municipios?departamento="+event.target.value).
            then ( (res) => { 
                this.setState({munisList:res.data})
            } 
        ).catch ( ( error ) => { console.log(error) } );
    }

    onMuniChange (event){
        this.setState({newMuni:event.target.value});
    }

    onNotesChange (event){
        this.setState({newNotes:event.target.value});
    }

    onTimestampChange (event){

        for (let t of this.props.trail){
            if ( moment(t.date + ' ' +t.time, 'DD/MM/YY hh:mm A').isAfter(event)){
                this.setState({error:'Ya hay un estado más reciente que la fecha seleccionada.'});
                return;
            }
        }

        this.setState({newTimestamp : event, error:''})
    }

    saveStatus(){
        if (this.state.newStatus.length === 0 || this.state.newAction.length === 0 || this.state.newDepto.length === 0 
            || this.state.newMuni.length === 0 || this.state.newNotes.length < 3 ){
                this.setState({error:'Todos los campos son obligatorios'});
                return;
        }
        if (this.state.newNotes.length > 60){
            this.setState({error:'El campo notas es demasiado largo'});
            return;
        }

        let data = {
            timestamp  : this.state.newTimestamp,
            statusCode : parseInt(this.state.newStatus),
            actionCode : parseInt(this.state.newAction),
            location : 'NA',
            stateCityCode: parseInt(this.state.newMuni),
            remarks: this.state.newNotes
        }

        this.setState({saving:true, error: ''}, () => {

            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            axios.post(apiURL + "/backoffice/parcel/action/"+this.props.parcelId, data).
                then ( (res) => { 
                    this.setState({addRow:false, saving:false});
                    this.props.onSave();
                } 
            ).catch ( ( error ) => { 
                let msg;
                if (error.response && error.response.data && error.response.data.error){
                    msg =  error.response.data.error;
                }else{
                    msg = "Ha ocurrido un error inesperado, por favor, intenta de nuevo."; 
                }
                
                this.setState({error: msg, saving: false}); 
            } );

        });
    
    }

    render(){

        const { classes } = this.props;

        const trail = this.props.trail;

        let dataRows = [];

        let snack = '';
        // if (this.state.error.length > 0){
        //     snack = (
        //         <Snackbar
        //         place="br"
        //         color="danger"
        //         icon={NotificationImportant}
        //         message={this.state.error}
        //         open={Boolean(this.state.error)}
        //         closeNotification={() => this.setState({error:''})}
        //         close
        //       />
        //     );
        // }
       
        
        if (this.state.addRow){

            let dateTime = (
                <FormControl fullWidth style={{width:'100px', marginTop:'5px'}}>
                     <Datetime
                        inputProps={{ placeholder: "Fecha / Hora" }} locale="es" value={this.state.newTimestamp} 
                        onChange = {this.onTimestampChange}
                        isValidDate = { current => current.isBefore(new Date()) }
                        utc = {false} 
                        />
                </FormControl>
            )

            let statusSelect = (
                <FormControl style={{width:'200px'}}>
                    <Select MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    value={this.state.newStatus}
                    onChange={this.onStatusChange}
                    inputProps={{name: "newStatus"}}
                    >
                        {this.state.statusList.map ( (s, index) =>{
                            return (
                                <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                          value={s.statuscode} key={index}>
                                    <TrackingStatus status={s.statuscode} statusDescription={s.statusname}/>
                                </MenuItem>
                            )
                        } )}
                    </Select> 
                </FormControl>
            );

            let actionSelect = (
                <FormControl style={{width:'250px'}}>
                    <Select MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    value={this.state.newAction}
                    onChange={this.onActionChange}
                    inputProps={{name: "newAction"}}
                    >
                        {this.state.actionsList.map ( (a, index) =>{
                            return (
                                a.statuscode === this.state.newStatus &&
                                <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                          value={a.actioncode} key={index}>
                                    {a.actionname}
                                </MenuItem>
                            )
                        } )}
                    </Select> 
                </FormControl>
            );
            
            let locationSelect = (
                <div>
                    <FormControl style={{width:'130px'}}>
                        <Select MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        value={this.state.newDepto}
                        onChange={this.onDeptoChange}
                        inputProps={{name: "newDepto"}}
                        >
                            {this.state.deptosList.map ( (d, index) =>{
                                return (
                                    <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                            value={d.code} key={index}>
                                        {d.name}
                                    </MenuItem>
                                )
                            } )}
                        </Select> 
                    </FormControl>
                    <FormControl style={{width:'210px', marginLeft:'5px'}}>
                        <Select MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        value={this.state.newMuni}
                        onChange={this.onMuniChange}
                        inputProps={{name: "newMuni"}}
                        >
                            {this.state.munisList.map ( (m, index) =>{
                                return (
                                    <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                            value={m.code} key={index}>
                                        {m.name}
                                    </MenuItem>
                                )
                            } )}
                        </Select> 
                    </FormControl>
                </div>
            );

            let notesInput = (
                <CustomInput
                    id={"field"} 
                    inputProps={{
                        placeholder: 'Notas',
                        value: this.state.newNotes
                        
                    }}
                    formControlProps={{
                        onChange: this.onNotesChange,
                        style: {width:'350px'}
                    }}
                />
            )

            dataRows.push(
                [
                    dateTime,
                    statusSelect,
                    actionSelect,
                    locationSelect,
                    notesInput,
                    auth.getUser().userName
                ]
            );

            if (this.state.error.length > 0){
                dataRows.push(
                    {
                        colspan: 6,
                        data: [(<div style={{color:'red', width:'100%', textAlign:'center'}}>{this.state.error}</div>)]
                    }
                )
            }

            let buttons;

            if (this.state.saving){
                buttons = (
                    <div style={{width:'100%', textAlign:'center'}}>
                        <CircularProgress size={30} style={{color:"#a72abd"}}/>
                    </div>
                );
            }else{
                buttons = (
                    <div style={{width:'100%', textAlign:'center'}}>
                        <Button simple color="primary" onClick={() => { this.saveStatus(); }} >
                            Guardar
                        </Button>
                        <Button simple color="danger" onClick={()=>{this.setState({addRow:false})}}>
                            Cancelar
                        </Button>
                    </div>
                )
            }

            dataRows.push(
                {
                    colspan : 6,
                    data :[buttons]
                }
            );
        }

        let priorDate = '';
        trail.forEach( (d, key) => {
            if (d.date !== priorDate){
                priorDate = d.date;
                dataRows.push(
                    {
                        color:"gray",
                        data:[d.date,'','','','','','']
                    }
                )
            }
            // let alert = '';
            // if (d.statusCode === 3){
            //     alert = (<FontAwesomeIcon icon={faExclamationTriangle} style={{fontSize:"20px", color:"#f44336"}}/>);
            // }
            dataRows.push(
                [
                    <div style={{width:'100%', textAlign:'center'}}>{d.time}</div>,
                    <TrackingStatus status={d.statusCode} statusDescription={d.status}/>,
                    d.action,
                    d.municipio + ', ' + d.departamento,
                    d.description,
                    d.actor
                ]
            );
        });

        //Add extra lines to prevent calendar hidden under scroll bars
        if (this.state.addRow && trail.length < 6){
            for (let i = 0; i < (10 - trail.length); i++){
                dataRows.push(
                    {
                        colspan : 6,
                        data : [' ']
                    }
                );
            }
        }

        let customHeadCellClasses =[
            classes.tableHeaderDate,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeaderNotes,
            classes.tableHeaderNotes,
            classes.tableHeader,
        ];

        let customCellClasses = [classes.cellDate, classes.cell, classes.cell, classes.cell, classes.cell, classes.cell];

        if (auth.getUser().role !== 'backoffice' && auth.getUser().role !== 'carrier' ){ //hide some data
            customCellClasses[5] = customCellClasses[5] + ' ' + classes.hide;

            customHeadCellClasses[5] = customHeadCellClasses[1] + " " + classes.hide;
        } 

        return(
            <>
                {snack}
                { ( auth.getUser().role === 'backoffice' || auth.getUser().role === 'carrier' ) &&
                    <div style={{textAlign:'right'}}>
                        <Button color="primary" simple style = {{textTransform:'none', textDecoration:'underline', fontSize:'14px'}} 
                                onClick={ () => this.onAddClick()}>
                            <FontAwesomeIcon icon={faPlus} style={{fontSize:"20px", marginRight:"5px", color:"purple"}}/>
                            Agregar estado
                        </Button>
                    </div>
                }
                <Table
                    tableHead={["Fecha / Hora", "Estado", "Acción", "Ubicación", "Notas", "Usuario"]}
                    tableData={dataRows}

                    customHeadCellClasses={customHeadCellClasses}
                    customHeadClassesForCells={[0,1,2,3,4,5]}

                    customCellClasses={customCellClasses}
                    customClassesForCells={[0,1,2,3,4,5]}

                    customRowsClass = {classes.row}
                />
            </>
        );
    }

}

export default withStyles(style)(TrailTable);
