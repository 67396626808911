import React from "react";
import Table from "components/Table/Table.js";
import {formatAmount} from 'utils/format';
import withStyles from "@material-ui/core/styles/withStyles";
import {GLAccount} from 'service/finance';

const style = {
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
}


function DisplayAmount( props ){
    const { amount, ...other} = props;

    let localAmount = parseFloat(amount);
    let amountStyle = {};
    let displayAmount = amount;
    if (localAmount < 0){
        amountStyle.color = 'red';
        displayAmount = 'Q. '+formatAmount(localAmount);
    }else if (localAmount > 0 ){
        amountStyle.color = 'black';
        displayAmount = 'Q. '+formatAmount(localAmount);
    }else if (localAmount === 0){
        amountStyle.color = 'black';
        displayAmount = '-';
    }

    return (
        <div style={amountStyle}> {displayAmount} </div> 
    );
}


class DetailedView extends React.Component {

    constructor(props){
        super(props);
    }

    render () {

        console.log(this.props.transactions);

        const {classes} = this.props;

        let dataRows = [];

        //amount we owe
        let cod = 0;
        //charges (owed to us)
        let shipping = 0;
        let codCollect = 0;
        let other = 0;

        for (let parcel of this.props.transactions){
            let localCOD = 0;
            let localShipping = 0;
            let localCODCollect = 0;
            let localOther = 0;

            for (let detail of parcel.details){
                if (detail.GLAccountCode === GLAccount.COD_TO_SETTLE){
                    localCOD += parseFloat(detail.Credit);
                }else if (detail.GLAccountCode === GLAccount.ACCOUNTS_RECEIVABLE){
                    if (detail.Reference === 'SHIPPING' || detail.Reference === 'RETURN' ){
                        localShipping += -1*parseFloat(detail.Debit);
                    }else if (detail.Reference === 'COD'){
                        localCODCollect += -1*parseFloat(detail.Debit);
                    }else{
                        localOther += -1*parseFloat (detail.Debit);
                    }
                }
            }

            cod += localCOD;
            shipping += localShipping;
            codCollect += localCODCollect;
            other += localOther;

            dataRows.push([
                parcel.parcelData.trackingNo,
                parcel.parcelData.recipient,
                <DisplayAmount amount={localCOD} />,
                <DisplayAmount amount={localShipping} />,
                <DisplayAmount amount={localCODCollect} />,
                <DisplayAmount amount={localOther} />,
                <DisplayAmount amount={localCOD + localShipping + localCODCollect + localOther} />,
            ]);
        }

        if (this.props.transactions.length > 0){
            dataRows.push({
                color:"gray",
                data: [
                    '','',
                    <DisplayAmount amount={cod} />,
                    <DisplayAmount amount={shipping} />,
                    <DisplayAmount amount={codCollect} />,
                    <DisplayAmount amount={other} />,
                    <strong><DisplayAmount amount={cod + shipping + codCollect + other} /></strong>,
                ]
            });
        }

        let customHeadCellClasses =[
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader + ' ' + classes.right,
            classes.tableHeader + ' ' + classes.right,
            classes.tableHeader + ' ' + classes.right,
            classes.tableHeader + ' ' + classes.right,
            classes.tableHeader + ' ' + classes.right,
        ];

        let customCellClasses = [
            classes.cell, 
            classes.cell, 
            classes.cell + ' ' + classes.right, 
            classes.cell + ' ' + classes.right,
            classes.cell + ' ' + classes.right,
            classes.cell + ' ' + classes.right,
            classes.cell + ' ' + classes.right
        ];

        return (
            <Table

            showLoading
            
            tableHead={["Guía", "Destinatario", "COD Recolectado", "Cobro por transporte", "Cobro por recolección de COD", 
                        "Otros cobros", "Total a transferir"]}

            tableData={dataRows}

            customHeadCellClasses={customHeadCellClasses}
            customHeadClassesForCells={[0,1,2,3,4,5,6]}

            customCellClasses={customCellClasses}
            customClassesForCells={[0,1,2,3,4,5,6]}

            customRowsClass = {classes.row} />
        )
    }
}

export default withStyles(style)(DetailedView);