import React from "react";

import PropTypes from "prop-types";
import Fab from '@material-ui/core/Fab';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from '@material-ui/core/CircularProgress';

// custom components
import ErrorLink from 'components/ErrorLink/ErrorLink';
import Update    from 'components/CRUDLinks/Update';
import Delete    from 'components/CRUDLinks/Delete';
import DeliveryEdit from 'components/Delivery/DeliveryEdit';
import Snackbar from "components/Snackbar/Snackbar.js";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {formatAmount} from 'utils/format';

import cloneDeep from 'lodash.clonedeep';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...buttonStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  header:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap"
  },
  headerReference:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap",
    width: "20%"
  },
  center:{
    textAlign:"center"
  },
  right:{
    textAlign:"right"
  },
  cellNoWrap:{
    whiteSpace: "nowrap"
  },
  buttonGenerar:{
    textTransform:"none",
    fontWeight:'900'
  },
  alertText:{
    fontSize:"25px",
    lineHeight:"30px"
  },
  tooltip:{
    fontSize:'16px'
  },
  tooltip:{
    fontSize: "15px"
  },
};

class Step2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      errors: false,
      deliveryToDelete: -1,
      deliveryToEdit: -1,
      generating: false,
      deleting: false,
      snack:true,
      addDelivery: false,
      error: ''
    };
    this.hideUpdateWindow = this.hideUpdateWindow.bind(this);
    this.saveUpdate = this.saveUpdate.bind(this);
    this.generateTracking = this.generateTracking.bind(this);
    console.log(this.props);
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    let deliveries = this.props.deliveries

    const error = deliveries.find ( d => d.name.error || d.cellphone.error || d.description.error 
                  || d.cod.error || d.address.error || d.municipio.error || d.departamento.error );
    
    if (error){
      this.setState({errors:true});
      return false;
    }
    
    this.setState({errors:false});
    return true;
  }

  updateFunction(key){
    this.setState({deliveryToEdit:key});
  }

  hideUpdateWindow(){
    this.setState({deliveryToEdit:-1});
  }

  saveUpdate(delivery){

    let deliveries = this.props.deliveries;

    //When this method is invoked from new delivery, then there  is no deliveryToEdit
    if (this.state.deliveryToEdit === -1){
      deliveries.push(delivery);
    }else{
      deliveries[this.state.deliveryToEdit] = delivery;
    }

    //update father component
    this.props.setDataPipe(deliveries, this.props.draftNo);
    //update draft
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.put(apiURL+"/users/draftshipments/"+this.props.draftNo, {data:deliveries, draftNo: this.props.draftNo})
    .then( (res) => {
      this.hideUpdateWindow();
    }).catch((error) =>{
      console.log(error);
      this.hideUpdateWindow();
    });
  }

  deleteFunction(key){
    this.setState({deliveryToDelete: key});
  }

  deleteDelivery(){
    
    this.setState({deleting:true});

    const index = this.state.deliveryToDelete;
    let deliveries = this.props.deliveries;
    deliveries.splice(index,1);
    this.props.setDataPipe(deliveries, this.props.draftNo);

    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.put(apiURL+"/users/draftshipments/"+this.props.draftNo, {data:deliveries, draftNo: this.props.draftNo})
    .then( (res) => {
      this.setState({deliveryToDelete:-1, deleting:false});
    }).catch((error) =>{
      console.log('Draft could not be disposed. '+error);
      this.setState({deliveryToDelete:-1, deleting:false});
    });
    
  }

  hideDeleteDeliveryAlert(){
    this.setState({deliveryToDelete: -1, deleting:false});
  }

  buildRow(d, key){

    const { classes } = this.props;

    const actions = (
      <div>
        <Tooltip
          id="tooltip-edit"
          title="Editar envío"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
            <div style={{display:'inline'}}>
              <Update 
                style={{color:'#00cae3', fontSize:"18px", cursor:"pointer"}} 
                updateFunction={() => this.updateFunction(key)}  
              />
            </div>
        </Tooltip>
        <Tooltip
          id="tooltip-delete"
          title="Eliminar envío"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <div style={{display:'inline'}}>
            <Delete 
              style={{color:'#f44336', fontSize:"18px", marginLeft:"12px", cursor:"pointer"}}
              deleteFunction={() => this.deleteFunction(key)}
            />
          </div>
        </Tooltip>
      </div>
    );

    const name = d.name.error ? <ErrorLink val = {d.name.value} tooltip = {d.name.error} action={() => this.updateFunction(key)}/> : d.name.value;
    const description = d.description.error ? <ErrorLink val = {d.description.value} tooltip = {d.description.error} action={() => this.updateFunction(key)}/> : d.description.value;
    const cod = d.cod.error ? <ErrorLink val = {d.cod.value} tooltip = {d.cod.error} action={() => this.updateFunction(key)}/> : formatAmount(d.cod.value);
    const cellphone = d.cellphone.error ?  <ErrorLink val = {d.cellphone.value} tooltip = {d.cellphone.error} action={() => this.updateFunction(key)}/> : d.cellphone.value; 
    const address = d.address.error ? <ErrorLink val = {d.address.value} tooltip = {d.address.error} action={() => this.updateFunction(key)} /> : d.address.value;
    const municipio = d.municipio.error ?  <ErrorLink val = {d.municipio.value} tooltip = {d.municipio.error} action={() => this.updateFunction(key)}/>  : d.municipio.value;
    const departamento = d.departamento.error ?  <ErrorLink val = {d.departamento.value} tooltip = {d.departamento.error} action={() => this.updateFunction(key)}/> : d.departamento.value;

    const row = [
      actions,
      key + 1,
      name,
      d.packageno.value,
      description,
      cod,
      cellphone,
      address,
      departamento,
      municipio,
      d.referencias.value
    ];

    return row;
  }

  hideAlert(){
    this.setState({errors:false});
  }

  generateTracking(){

    if (!this.isValidated()) return false;

    this.setState({generating:true});

    let deliveries = [];
    for (let d of this.props.deliveries){
      deliveries.push (
        {
          name : d.name.value,
          cellphone : d.cellphone.value,
          packageno: d.packageno.value,
          description: d.description.value,
          cod: d.cod.value,
          currency: d.currency.value,
          address: d.address.value,
          municipio: d.municipio.code,
          departamento: d.departamento.code,
          referencias: d.referencias.value
        }
      )
    }
    
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.post(apiURL+"/merchant/parcels/", {deliveries, draftNo: this.props.draftNo})
    .then( (res) => {
      this.props.setDataPipe(res.data.data, this.props.draftNo);
      this.props.nextFunction();
    }).catch((error) =>{
      let e = (error.response && error.response.data && error.response.data.error) ? error.response.data.error : '';
      console.log(e);
      this.setState({generating:false, error: e});
    });

  }

  render() {

    const { classes } = this.props;

    let popup = '';

    if (this.state.error){
      popup = (
              <SweetAlert
                error
                style={{ display: "block", marginTop: "-175px", width:"40%" }}
                title="Ouch! Hubo un error"
                onConfirm={() => this.setState({error:''})}
                onCancel={() => this.setState({error:''})}
                confirmBtnText="Corregir errores"
                confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
              >
                  <div className={classes.alertText}>
                   {this.state.error}
                  </div>
              </SweetAlert>
            );
    }


    if (this.state.errors){
      popup = (
              <SweetAlert
                error
                style={{ display: "block", marginTop: "-175px", width:"40%" }}
                title="Ouch! Algunas de tus guías tienen errores"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnText="Corregir errores"
                confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
              >
                  <div className={classes.alertText}>
                   Por favor, corrígelas para poder continuar.
                  </div>
              </SweetAlert>
            );
    }

    if (this.state.deliveryToDelete !== -1){
      let deleteBtnText = this.state.deleting ? 'Eliminando...' : 'Eliminarlo';
      popup = (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px", width:"40%"  }}
          title="Eliminar envío"
          onConfirm={() => this.deleteDelivery()}
          onCancel={() => this.hideDeleteDeliveryAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          disabled = {this.state.deleting}
          confirmBtnText={deleteBtnText}
          cancelBtnText="Cancelar"
          showCancel
          reverseButtons={true}
        >
          <div className={classes.alertText}>Seguro que deseas eliminar éste envío?</div>
        </SweetAlert>
      )
    }

    let modalEdit = '';

    if (this.state.deliveryToEdit !== -1){
      let d = cloneDeep(this.props.deliveries[this.state.deliveryToEdit]);
      modalEdit =  (
        <DeliveryEdit 
          open = {true} 
          delivery = {d} 
          id = {this.state.deliveryToEdit}
          onSave={this.saveUpdate} 
          onClose={this.hideUpdateWindow}  
        />
      );
    }

    let modalAdd = '';
    
    if (this.state.addDelivery){
      
      modalAdd = (<DeliveryEdit 
          open = {true} 
          delivery = {{
              name: {value: ""},
              cellphone: {value: ""},
              packageno: {value: ""},
              description: {value: ""},
              cod: {value: 0},
              currency: {value: "GTQ"},
              address: {value: ""},
              municipio: {value: 0, code: 0},
              departamento: {value: 0, code: 0, acronym: ""},
              referencias: {value: ""}
          }} 
          id = {-1}
          onSave={this.saveUpdate} 
          onClose={ () => this.setState({addDelivery:false}) }  
        />
      );
    } 
                   

    let dataRows = [];
    var deliveries = this.props.deliveries;
    
    deliveries.forEach( (d, key) => { 
      dataRows.push(this.buildRow(d, key))
    });
   
    var headerClasses = Array(11).fill(classes.header);
    headerClasses.push(classes.headerReference);

    let buttonText = 'Generar guías de envío';
    if (this.state.generating){
      buttonText = (
        <div>Generando... <CircularProgress size={20} style={{marginLeft:'5px', color:"#ffffff"}}/></div>
      )
    }

    let snack = '';
    if (this.state.snack && !this.props.draftLoad){
      snack = (
        <Snackbar
            place="br"
            color="success"
            icon={NotificationImportant}
            message='Se ha creado un borrador de tus envíos. Puedes accederlo desde del menú "Ver envíos en borrador".'
            open={this.state.snack}
            closeNotification={() => this.setState({snack:false})}
            close
          />
      );
      setTimeout( () => this.setState({snack:false}) , 6000 );
       
    }

    return (
      <div>
        {popup}
        {modalEdit}
        {modalAdd}
        {snack}
        <GridContainer justify="center">
          <GridItem xs={12} style={{textAlign:'right'}}> 
              <Tooltip
                id="tooltip-top"
                title="Agregar un envío más"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Fab color="primary" aria-label="add" style={{backgroundColor:"#af2cc5"}} onClick={()=>{this.setState({addDelivery:true})}}>
                  <AddIcon />
                </Fab>
              </Tooltip>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
          <Table
              striped
              tableHead={[
                "Acciones",
                "No.",
                "Nombre",
                "Factura",
                "Contenido",
                "Monto COD",
                "No. Celular",
                "Dirección",
                "Departamento",
                "Municipio",
                "Instrucciones"
              ]}
              tableData={dataRows}

              customCellClasses={[classes.center, classes.center,classes.cellNoWrap, classes.right, classes.center]}
              customClassesForCells={[0,1,3,5,6]}

              customHeadCellClasses={headerClasses}
              customHeadClassesForCells={[0,1,2,3,4,5,6,7,8,9,10,11]}

            />

          </GridItem>
          <GridItem xs={12}>
            <div style={{textAlign:'center', marginTop:"50px", marginBottom:"50px"}}>
              <Button color="primary" 
                      size="lg" 
                      onClick={this.generateTracking}
                      className={classes.buttonGenerar}
                      disabled = {this.state.generating}
              >
                {buttonText}
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step2);
