import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Delete    from 'components/CRUDLinks/Delete';
import Open    from 'components/CRUDLinks/Open';
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
  ...buttonStyle,
  title:{
      fontSize:'18px'
  },
  tableContainer:{
    width:"97%",
    margin:"0 auto"
  },
  tableHeader:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap"
  },
  actions: {
      width:"75px"
  },
  alertText:{
    fontSize:"25px",
    lineHeight:"30px"
  }
};

class DraftShipments extends React.Component{


    constructor(props){
        super(props);

        this.state = {
            drafts : [],
            noData: false,
            draftToDelete : -1,
            deleting: false,
            opening: null,
        };

        this.deleteFunction = this.deleteFunction.bind(this);
        this.openDraft = this.openDraft.bind(this);
    }

    componentDidMount() {
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

        axios.get(apiURL + "/users/draftshipments")
        .then( (res) => {

            if (res.data.length > 0){
                this.setState({drafts:res.data});
            }else{
                this.setState({noData:true});
            }
        }).catch( (error) =>{
            console.log(error);
        });
    }


    deleteFunction(serialNo){
        this.setState({draftToDelete: serialNo});
    }

    deleteDraft(){
        
        this.setState({deleting:true});
        let serialNo = this.state.draftToDelete;
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        axios.delete(apiURL+"/users/draftshipments/"+serialNo)
        .then( (res) => {
            let drafts = this.state.drafts;
            let index = drafts.findIndex( element => element.SerialNo == serialNo );
            drafts.splice(index,1);
            this.setState({drafts, draftToDelete:-1, deleting:false});
            if (this.state.drafts.length === 0){
                this.setState({noData:true});
            }
        }).catch((error) =>{
            this.setState({draftToDelete:-1, deleting:false});
            console.log('Draft could not be disposed. '+error);
        });

    }

    hideDeleteDraftAlert(){
        this.setState({draftToDelete:-1});
    }

    openDraft(step, data, serialNo){

        this.setState({opening:serialNo}, () => {
            this.props.history.push({
                pathname: '/admin/shipment-wizard',
                state: {
                    currentStep : step,
                    data: data,
                    draftNo: serialNo
                }
            })
        });
    }

    render(){

        const { classes } = this.props;

        var dataRows = [];
        for (let d of this.state.drafts){
            let desc = '';
            let step = 0;
            switch (d.Step){
                case 'REVIEW':
                    desc = 'Paso 2: Revisar envíos';
                    step = 1;
                    break;
                case 'TRACKNO':
                    desc = 'Paso 3: Generar guías de envío';
                    step = 2;
                    break;
                case 'PRINT':
                    desc = 'Paso 4: Imprimir guías de envío y manifiesto';
                    step = 3;
                    break;
                case 'NOTIFY':
                    desc = 'Paso 5: Notificar recolección';
                    step = 4;
                    break;
            }

            let actions;
            
            if (Boolean(this.state.opening)){

               if (this.state.opening === d.SerialNo){
                    actions = (
                        <CircularProgress size={30} style={{color:"#a72abd"}}/>
                    );
               }else{
                   actions = (<></>);
               }
              
            }else{
                actions = (
                    <div>
                        <Open
                            style={{color:'#9c27b0', fontSize:"18px", marginLeft:"0px", cursor:"pointer"}}
                            openFunction={() => this.openDraft(step, d.Data, d.SerialNo)}
                        />
                        <Delete 
                            style={{color:'#f44336', fontSize:"18px", marginLeft:"15px", cursor:"pointer"}}
                            deleteFunction={() => this.deleteFunction(d.SerialNo)}
                        />
                    </div>
                )
            }
  
            dataRows.push(
                [
                    actions,
                    desc, 
                    d.Data.length + ' envíos',
                    d.Created
                ]
            );
        }

        let popup = '';
        if (this.state.draftToDelete !== -1){
            let text = this.state.deleting ? 
                        ( <div>Eliminando...  <CircularProgress size={30} style={{color:"#a72abd"}}/> </div>) 
                        : 'Seguro que deseas eliminarlo?';
            popup = (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-200px", width:"40%"  }}
                title="Eliminar envíos en proceso"
                onConfirm={() => this.deleteDraft()}
                onCancel={() => this.hideDeleteDraftAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Eliminarlo"
                cancelBtnText="Cancelar"
                showCancel
                reverseButtons={true}
                disabled = {this.state.deleting}
              >
                <div className={classes.alertText}>{text}</div>
              </SweetAlert>
            )
          }

        return(
            <div>
                {popup}
                <Card className={classes.card}>
                    <div className={classes.tableContainer}>
                        <GridContainer justify="center" style={{marginTop:"15px"}}>
                            <GridItem xs={12}>
                                <p style={{fontSize:"18px", margin:"8px"}}>
                                     Envíos en borrador, pendientes de solicitar recolección
                                </p>
                            </GridItem>
                        </GridContainer>
                        <Table
                            showLoading
                            noData = {this.state.noData}
                            striped
                            tableHead={[
                                "",
                                "Estado",
                                "# de envíos",
                                "Fecha y hora de creación"
                            ]}
                            tableData={dataRows}

                            customHeadCellClasses={ [
                                classes.tableHeader,
                                classes.tableHeader,
                                classes.tableHeader,
                                classes.tableHeader
                            ]}
                            customHeadClassesForCells={[0,1,2,3]}

                            customCellClasses={
                                [classes.actions]
                            }
                            customClassesForCells={[0]}
                        />
                    </div>
                    <div style={{display:'block', height:'20px'}}></div>
                </Card>
            </div>
        );
    }

}

export default withStyles(style)(DraftShipments);
