import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";
import Update    from 'components/CRUDLinks/Update';
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from "components/CustomButtons/Button.js";

import MerchantConfig from "views/Merchant/MerchantConfig";

const styles = {
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    tableContainer: {
        width:"97%",
        margin:"0 auto"
    },
    headerActions:{
        width:"50px",
        textAlign:"center"
    },
    center:{
        textAlign: "center"
    },
    pageTitle:{
        fontSize:"18px",
        lineHeight: "60px"
    }
}

const merchants = [
    {
        name: 'Chopinmol.com',
        contact: 'Heidy López',
        phone: '5454-5454',
        office: 'Via 6 3-56, Guatemala 01004',
        created: '15/03/2019'
    },
    {
        name: 'Molvu',
        contact: 'Oscar Doe',
        phone: '2323-2323',
        office: 'Tec 2, Oficina 301, zona 4',
        created: '05/11/2019'
    },
    {
        name: 'Inmensa Hogar',
        contact: 'Patricia Pérez',
        phone: '5675-0998',
        office: '19 calle, 2-32, zona 1',
        created: '12/12/2019'
    }
]

class MerchantList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            editing: false
        }
        this.updateMerchant = this.updateMerchant.bind(this);
    }

    updateMerchant(){
        this.setState({editing:true});
    }

    render(){

        const { classes } = this.props;

        var dataRows = [];
        var actions = (
            <Update 
            style={{color:'#00cae3', fontSize:"18px", cursor:"pointer"}} 
            updateFunction={() => this.updateMerchant()}  
          />
        )
        for (let m of merchants){
            dataRows.push([
                actions,
                m.name,
                m.contact,
                m.phone,
                m.office,
                m.created
            ]);
        }

        if (this.state.editing){
            return (
                <MerchantConfig/>
            );
        }

        return (
            <Card className={classes.card}>
                <div className={classes.tableContainer}>
                    <GridContainer style={{marginTop:"15px"}}>
                        <GridItem xs={6}>
                            <div class={classes.pageTitle}>
                                Listado de comercios afiliados
                            </div>
                        </GridItem>
                        <GridItem xs={6} sm={6} style={{textAlign:"right"}}>
                            <Button 
                                color="primary" 
                                round 
                                style={{width:"55px", height:"60px"}}
                                onClick={ () => {alert('Agregar un comercio.')} }
                            >
                                <FontAwesomeIcon icon={faPlus} size='2x' />
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="center" style={{marginTop:"15px"}}>
                        <GridItem xs={12} sm={12}>
                            <Table
                                showLoading
                                striped
                                tableHead={[
                                    "",
                                    "Nombre",
                                    "Contacto",
                                    "Teléfono",
                                    "Dirección oficina",
                                    "Creado"
                                ]}
                                tableData={dataRows}

                                customHeadCellClasses={ [
                                    classes.headerActions,
                                    classes.tableHeader,
                                    classes.tableHeader,
                                    classes.tableHeader,
                                    classes.tableHeader,
                                    classes.tableHeader
                                ]}
                                customHeadClassesForCells={[0,1,2,3,4,5]}

                                customCellClasses={[classes.center]}
                                customClassesForCells={[0]}
                            />
                        </GridItem>
                    </GridContainer>
                    <div style={{display:'block', height:'20px'}}>

                    </div>
                </div>
          </Card>
        )
    }

}

export default withStyles(styles)(MerchantList);