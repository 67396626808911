/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import { faTimes, faPaperclip, faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import SummaryView from './SummaryView';
import DetailedView from './DetailedView';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const style = {
    ...styles,
    ...formStyles,
    ...customSelectStyle,
    ...buttonStyle,
    modalTitle:{
        fontSize: "30px",
        lineHeight: "35px",
        textAlign: "center",
        color: "#555555",
        fontWeight: "900"
    },
    modal:{
        width:"90%",
        maxWidth:"90%",
        height: "90%",
        maxHeight: "90%"

    },
    darkerLabel:{
        color:"#878787"
    },
    errorLabel:{
        color: "#e53935"
    },
    modalFooter:{
        margin:"0 auto"
    },
    error:{
        color: "#e53935",
        fontSize: "15px",
        fontWeight: "300"
    },
    select:{
        marginTop:"32px"
    },
    windowToolbar:{
        position: 'absolute',
        top : '5px',
        right : '25px',
        textAlign: "right",
        fontSize:"22px",
        color:"#555555"
    },
    radioRootCustom :{
        paddingTop:'5px'
    },
    formControlCustom: {
        paddingTop: '5px'
    },
    depositRoot:{
        width: '100px',
        marginLeft: '7px'
    },
    alertText:{
        fontSize:"25px",
        lineHeight:"30px"
      }
}

function DisplayFiles( props ){
    const { fileList, ...other} = props;

    if (fileList.length === 0){
        return ('');
    }

    let fileNames = '';

    for (let i = 0; i<fileList.length; i++){
        fileNames +=  fileNames.length === 0 ? fileList[i].name : ', ' + fileList[i].name;
    }

    return (
        fileNames
    );

}


class ModalSettlementSummary extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            open:this.props.open,
            deliveries: this.props.deliveries,
            transactions: [],
            slipNo: '',
            description: '',
            saving: false,
            view:'summary',
            error : '',
            selectedFiles: []
        };

        this.closeModal = this.closeModal.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSlipNoChange = this.onSlipNoChange.bind(this);
        this.doSave = this.doSave.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onFileAttach = this.onFileAttach.bind(this);
    }

    fetchData() {
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        let data = this.props.deliveries.map ( delivery => delivery.parcelId );

        axios.post(apiURL + "/backoffice/finance/merchantsettlement/gettransactions", data)
            .then( (res) => {
                let response = res.data;
                for (let r of response){
                    r.parcelData = this.state.deliveries.find ( item => item.parcelId === r.parcelId );
                }
                this.setState({transactions:response});
            }).catch( (error) =>{
                console.log(error);
            });
    }

    closeModal(){
        this.setState({open:false});
        this.props.onClose();
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.deliveries !== prevProps.deliveries){
            this.setState({...this.props});
        }
    }

    onInputChange (field, event){
        let o = new Object();
        o[field] = event.target.value;

        this.setState(o);
    }

    onSlipNoChange (event) {
        this.setState({slipNo:event.target.value});
    }

    onFileAttach (event) {
        const files = event.target.files;
        //event.target.value = null; //clean up input field 
    
        for (let file of files){
            if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('jpeg') && !file.name.toLowerCase().endsWith('png')){
                this.setState({error:'Solamente puedes adjuntar archivos JPG, JPEG o PNG.'});
                return;
            }
        }

        this.setState({selectedFiles:files}); 
    }

    doSave () {
        //TODO: check that amount to transfer is enough to pay tracks that only have costs
        
        if (this.state.slipNo.length === 0){
            this.setState({error: 'Por favor, escribe el número de la transacción'});
            return;
        }

        if (this.state.description.length === 0){
            this.setState({error: 'Por favor, escribe una descripción'});
            return;
        }

        if (this.state.selectedFiles.length === 0){
            this.setState({error:'Por favor, selecciona un archivo.'});
            return;
        }

        this.setState({saving: true} , () => {
            let parcelList = this.state.deliveries.map ( item => item.parcelId );

            const token = localStorage.getItem('jwt');
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

            const files = this.state.selectedFiles;
            let formData = new FormData();
            for (let f of files){
                formData.append('files',f)
            }

            formData.append('slipNo', this.state.slipNo);
            formData.append('description', this.state.description);
            formData.append('parcels', JSON.stringify(parcelList));

            axios.post(apiURL + "/backoffice/settle/merchant", formData)
            .then( (res) => {
                this.setState({open:false});
                this.props.onClose();
                this.props.onSave();
            }).catch( (error) =>{

                let message = '';
                if (error.toString().includes('Network Error')){
                    message = "No ha sido posible conectarse al servidor. Por favor, intenta más tarde.";
                }else{
                    if (error.response && error.response.data && error.response.data.error){
                        message = error.response.data.error;
                    }else{
                        message = "Ha ocurrido un error inesperado, por favor, intenta de nuevo."; 
                    }
                }

                this.setState({error: message, saving:false});
                
            });
        });
        
    }

    render(){

        const { classes } = this.props;
    
        let popup = '';
        if (this.state.error.length > 0){
            popup = (
              <SweetAlert
                  error
                  style={{ display: "block", marginTop: "-175px", width:"40%" }}
                  title="Ouch! Hubo un error"
                  onConfirm={() => this.setState({error:''})}
                  confirmBtnText="Ok"
                  confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                  confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
                >
                    <div className={classes.alertText}>
                      {this.state.error}
                    </div>
                </SweetAlert>
            );
          }

        return(

            <Dialog
                classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
                }}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.closeModal}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="form-dialog-title">Liquidación de guías al comercio</DialogTitle>

                <div className={classes.windowToolbar}>
                    <FontAwesomeIcon icon={faTimes} style={{cursor:"pointer"}} onClick={this.closeModal}/>
                </div>
                <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
                >
                    {popup}
                    <GridContainer>
                        <GridItem xs={1} style={{marginTop:'23px'}}>
                            Transferencia:
                        </GridItem>
                        <GridItem  xs={11}>
                           
                            <TextField id="standard-basic" label="# de transacción" value={this.state.slipNo} 
                                onChange={(event) => this.onInputChange('slipNo',event) }/>
                            {`  `}
                            <TextField id="standard-basic" label="Descripción" value={this.state.description} 
                                style= {{width:'250px'}}
                                onChange={(event) => this.onInputChange('description',event) }/>
                            {`  `}
                            <DisplayFiles fileList = {this.state.selectedFiles} />
                            <Button color="primary" simple onClick={() => { document.getElementById('slip_file').click(); }} style={{textDecoration:'none'}}>
                                <FontAwesomeIcon icon={faPaperclip} onClick={this.closeModal}/> Adjuntar archivo
                            </Button>
                            <input type="file" name="slip_file" id="slip_file" onChange={this.onFileAttach} multiple accept=".jpg,.jpeg,.png" style={{display:"none"}}/>
                        </GridItem>

                    </GridContainer>


                    <GridContainer style={{width:'95%', margin:'0 auto'}}>
                        <GridItem xs={12}>
                            
                            <div style={{width:'100%', textAlign:'right'}}>
                                <FormControl style={{width:'150px'}}>
                                    <Select MenuProps={{className: classes.selectMenu}}
                                    value={this.state.view}
                                    onChange={(event) => this.onInputChange('view', event)}
                                    inputProps={{id: "view-select"}}
                                    >
                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                                        value={'summary'} key={'0'}>
                                                    Vista financiera
                                        </MenuItem>
                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                                        value={'detail'} key={'1'}>
                                                    Vista por guía
                                        </MenuItem>
                                    </Select> 
                                </FormControl>
                            </div>

                            {this.state.view === 'summary' && 
                            <SummaryView transactions = {this.state.transactions} />}
                            {this.state.view === 'detail' &&
                            <DetailedView transactions = {this.state.transactions} />}
                            
                        </GridItem>
                    </GridContainer>

                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    
                    {this.state.saving ? 
                        (
                            <>
                                <CircularProgress size={20} style={{marginRight:'5px', color:"purple"}}/>
                                Guardando...
                            </>
                        ) : (
                            <>
                                <Button onClick={this.doSave} color="primary">
                                    Guardar liquidación
                                </Button>
                                <Button onClick={this.closeModal} color="danger" style={{marginLeft:'25px'}}>
                                    Cancelar
                                </Button>
                            </>
                        )
                    }
                                
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(style)(ModalSettlementSummary);