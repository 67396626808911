import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import Step4 from "./WizardSteps/Step4.js";
import Step5 from "./WizardSteps/Step5.js";

import { faUpload, faCheck, faBarcode, faPrint, faBell } from "@fortawesome/pro-light-svg-icons";


class WizardView extends React.Component {
  
  constructor(props){
    super(props);
  }

  render(){
    let currentStep = this.props.location.state ? this.props.location.state.currentStep : 0 ;
    let data = this.props.location.state ? this.props.location.state.data : [];
    let draftNo = this.props.location.state ? this.props.location.state.draftNo : 0;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <Wizard
            currentStep = {currentStep}
            data = {data}
            draftNo = {draftNo}
            history = {this.props.history}
            draftLoad = {this.props.location.state}
            validate
            steps={[
              { stepName: "1. Subir archivo", faIcon:faUpload, stepComponent: Step1, stepId: "upload" },
              { stepName: "2. Revisar envíos", faIcon:faCheck, stepComponent: Step2, stepId: "content" },
              { stepName: "3. Generar guías de envío", faIcon:faBarcode, stepComponent: Step3, stepId: "trackingno" },
              { stepName: "4. Imprimir guías de envío y manifiesto", faIcon:faPrint, stepComponent: Step4, stepId: "print" },
              { stepName: "5. Notificar recolección", faIcon:faBell, stepComponent: Step5, stepId: "pickup" }
            ]}
            title="Chopi-track deliveries"
            subtitle=""
            finishButtonClick={e => alert(e)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default WizardView;

