import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import DeliveryView from "components/Delivery/DeliveryView.js";

class ViewShipment extends React.Component {
    
    constructor(props){
      super(props);
      
      let trackingNo = this.props.location.state ? this.props.location.state.trackingNo : "4729-1GU-2EA8A" ;
      this.state = {
        trackingNo : trackingNo
      }

    }

    componentDidUpdate(prevProps) {
      try{
        if (this.props.location.state.trackingNo !== prevProps.location.state.trackingNo){
            this.setState({trackingNo : this.props.location.state.trackingNo});
        }
      } catch ( error ) {
        console.log('Error: ', error);
      }
    }

    render(){
      return (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
              <DeliveryView trackingNo={this.state.trackingNo} />
          </GridItem>
        </GridContainer>
      );
    }
}

export default ViewShipment;