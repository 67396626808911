import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "service/authentication";

const ProtectedRoute = ({ component: Component, ...rest}) => (
    <Route
        {...rest}
        render = {props =>
            auth.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to = {{pathname:'/auth/login-page', state:{ from: props.location }}}
                />
            )
        }
    />
);

export default ProtectedRoute;