import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

//Fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";



class Wizard extends React.Component {

  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }

    //props use in case of preload  from draft shipments
    let currentStep = this.props.currentStep ? this.props.currentStep : 0;
    let data = this.props.data ? this.props.data : [];
    let draftNo = this.props.draftNo ? this.props.draftNo : 0;

    this.state = {
      currentStep: currentStep,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      },
      allStates: {},
      deliveries: data,
      stepData: [],
      draftNo
    };

    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.restartWizard = this.restartWizard.bind(this);
    this.setDataPipe = this.setDataPipe.bind(this);
    this.setStepData = this.setStepData.bind(this);
  }

  wizard = React.createRef();

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  restartWizard(){
    this.setState({
      currentStep:0,
      allState: {},
      deliveries:[],
      raisedData: []
    })
  }

  nextButtonClick() {
    
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !== undefined 
          && this[this.props.steps[this.state.currentStep].stepId].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {

      // if (
      //   this[this.props.steps[this.state.currentStep].stepId].sendState !==
      //   undefined
      // ) {
      //   this.setState({
      //     allStates: {
      //       ...this.state.allStates,
      //       [this.props.steps[this.state.currentStep].stepId]: this[
      //         this.props.steps[this.state.currentStep].stepId
      //       ].sendState()
      //     }
      //   });
      // }

      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  previousButtonClick() {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      this.setState({
        allStates: {
          ...this.state.allStates,
          [this.props.steps[this.state.currentStep].stepId]: this[
            this.props.steps[this.state.currentStep].stepId
          ].sendState()
        }
      });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  finishButtonClick() {
    // if (
    //   (this.props.validate === false &&
    //     this.props.finishButtonClick !== undefined) ||
    //   (this.props.validate &&
    //     ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
    //       undefined &&
    //       this[
    //         this.props.steps[this.state.currentStep].stepId
    //       ].isValidated()) ||
    //       this[this.props.steps[this.state.currentStep].stepId].isValidated ===
    //         undefined) &&
    //     this.props.finishButtonClick !== undefined)
    // ) {
    //   this.setState(
    //     {
    //       allStates: {
    //         ...this.state.allStates,
    //         [this.props.steps[this.state.currentStep].stepId]: this[
    //           this.props.steps[this.state.currentStep].stepId
    //         ].sendState()
    //       }
    //     },
    //     () => {
    //       this.props.finishButtonClick(this.state.allStates);
    //     }
    //   );
    // }
  }
  refreshAnimation(index) {

    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance =
      this.wizard.current.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizard.current.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };

    this.setState({ movingTabStyle: movingTabStyle });
  }

  setDataPipe(data, draftNo){
     this.setState({deliveries:data, draftNo});
  }

  setStepData(data){
    console.log('setup:',data);
    this.setState({stepData:data});
  }

  render() {
   
    const { classes, color, steps } = this.props;
    const init = (this.props.steps.length === 3) ? 15 : 12;
    const wizardProgress = init + this.state.currentStep*(100/this.props.steps.length);
    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        <Card className={classes.card}>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={wizardProgress}
                style={{height: "4px", overflow: "hidden", margin: "0px", position: "relative", top: "35px", zIndex: "0"}}
              />
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <div className={classes.stepIcon + ((key <= this.state.currentStep) ? " " + classes.stepIconActive : "")}>
                      <FontAwesomeIcon icon={prop.faIcon}/>
                    </div>
                    <div className={classes.stepTitleText + ((key <= this.state.currentStep) ? " " + classes.stepTitleTextActive : "") }>
                      {prop.stepName}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key
              });
              return (
                <div className={stepContentClasses} key={key}>
                  {(this.state.currentStep === key || (this.props.hasOwnProperty('reloadStep') && !this.props.reloadStep) ) ?
                  <prop.stepComponent
                    innerRef={node => (this[prop.stepId] = node)}
                    allStates={this.state.allStates} nextFunction = {this.nextButtonClick.bind(this)}
                    setDataPipe = {this.setDataPipe} deliveries = {this.state.deliveries} draftNo = {this.state.draftNo}
                    restartWizardAction = {this.restartWizard} draftLoad = {this.props.draftLoad}
                    previousFunction = {this.previousButtonClick.bind(this)}
                    setStepData = {this.setStepData} childData = {this.stepdData}
                  /> : ''}
                </div>
              );
            })}
          </div>
          {/* <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.right}>
              {this.state.nextButton ? (
                <Button
                  color="rose"
                  className={this.props.nextButtonClasses}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.props.nextButtonText}
                </Button>
              ) : null}
              {this.state.finishButton ? (
                <Button
                  color="rose"
                  className={this.finishButtonClasses}
                  onClick={() => this.finishButtonClick()}
                >
                  {this.props.finishButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.clearfix} />
          </div> */}
        </Card>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Finish"
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.object.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool
};

export default withStyles(wizardStyle)(Wizard);
