import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "components/Table/Table.js";
import {formatAmount} from 'utils/format';
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SettlementView from "./SettlementView";
import Link from '@material-ui/core/Link';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
    tableContainer: {
        width:"97%",
        margin:"0 auto",
        paddingTop: "15px"
    },
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    right:{
        textAlign:"right"
    },
}

class SettlementList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            noData: false,
            data: [],
            viewSerialNo: 0
        }
        this.hideDrillDown = this.hideDrillDown.bind(this);
    }
    
    componentDidMount(){
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

        axios.get(apiURL + "/merchant/settlements")
        .then( (res) => {
            if (res.data.length === 0 ){
                this.setState({noData:true});
            }else{
                this.setState({data : res.data});
            }
        }).catch( (error) =>{
            this.setState({noData:true});
            console.log(error);
        });
    }

    hideDrillDown(){
        this.setState({viewSerialNo: 0});
    }

    render(){

        const { classes } = this.props;

        let dataRows = [];
        let total = 0;
        for (let s of this.state.data){
            dataRows.push([
                <Link
                    component="button"
                    underline="always"
                    style={{fontSize:"14px", userSelect:"text", color:"purple"}}
                    onClick={() => this.setState({viewSerialNo : s.SerialNo}) }>{s.DocumentNo}</Link>,
                s.Description,
                s.Created,
                s.Total
            ]);
            total += parseFloat(s.Total);
        }

        if (this.state.data.length > 0){
            dataRows.push({
                color:"gray",
                data: [
                    '','',
                    <strong>Total</strong>,
                    <strong>{formatAmount(total)}</strong>,
                ]
            });
        }

        let customCellClasses = [classes.right];
        let customClassesForCells = [3];

        let customHeadCellClasses = [
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader + " " + classes.right,
        ];
        let customHeadClassesForCells = [0,1,2,3];

        if (this.state.viewSerialNo > 0){
            return (
                <SettlementView serialNo = {this.state.viewSerialNo} 
                                 onClose={this.hideDrillDown} />
            )
        }else{

            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>

                        <Card>
                            <GridItem xs={12} sm={12} md={12}>
                                <h3>Historial de liquidaciones</h3>
                            </GridItem>

                            <div className={classes.tableContainer}>
                                <Table
                                    showLoading
                                    noData = {this.state.noData}
                                    striped
                                    tableHead={[
                                        "# Transferencia",
                                        "Descripción",
                                        "Fecha",
                                        "Total transferido"
                                    ]}
                                    tableData={dataRows}

                                    customCellClasses={customCellClasses}
                                    customClassesForCells={customClassesForCells}

                                    customHeadCellClasses={customHeadCellClasses}
                                    customHeadClassesForCells={customHeadClassesForCells}

                                    />
                            </div>

                            <GridItem xs={12} sm={12} md={12} style={{marginTop:'15px'}}></GridItem>

                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }
    }

}

export default withStyles(style)(SettlementList);