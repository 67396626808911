import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Link from '@material-ui/core/Link';
import Delete    from 'components/CRUDLinks/Delete';
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from '@material-ui/core/Tooltip';
import {formatAmount} from 'utils/format';

import ModalDeliveryView from 'components/Delivery/ModalDeliveryView';

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...buttonStyle,
  header:{
    fontWeight:"900",
    fontSize:"14px !important",
    whiteSpace: "nowrap"
  },
  center:{
    textAlign:"center"
  },
  right:{
    textAlign:"right"
  },
  cellNoWrap:{
    whiteSpace: "nowrap"
  },
  buttonGenerar:{
    textTransform:"none",
    fontWeight:'900'
  },
  alertText:{
    fontSize:"25px",
    lineHeight:"30px"
  },
  tooltip:{
    fontSize: "15px"
  }
};


class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      deliveryToCancel: -1,
      showParcel : '',
      cancelling: false
    };
    this.viewParcel = this.viewParcel.bind(this);
    this.hideViewWindow = this.hideViewWindow.bind(this);
  }
  sendState() {
    return this.state;
  }

  componentDidMount(){
    if (document.querySelector('.makeStyles-mainPanel-2')){
      document.querySelector('.makeStyles-mainPanel-2').scrollTop = 0;
    }
  }

  viewParcel(trackingNo){
      this.setState({showParcel:trackingNo});
  }

  hideViewWindow(){
      this.setState({showParcel:''});
  }

  cancelFunction(key){
    this.setState({deliveryToCancel: key});
  }

  cancelDelivery(){
    this.setState({cancelling:true});
    
    const index = this.state.deliveryToCancel;
    let deliveries = this.props.deliveries;
    let trackingNo = deliveries[index].trackingno.value;
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    axios.delete(apiURL+"/merchant/parcel/"+trackingNo)
    .then( (res) => {
        deliveries.splice(index,1);
        this.props.setDataPipe(deliveries, this.props.draftNo);

        //update draft
        axios.put(apiURL+"/users/draftshipments/"+this.props.draftNo, {data:deliveries, draftNo: this.props.draftNo})
        .then( (res) => {
          this.setState({deliveryToCancel:-1, cancelling:false});
        }).catch((error) =>{
          console.log('Draft could not be disposed. '+error);
          this.setState({deliveryToCancel:-1, cancelling: false});
        });

    }).catch((error) =>{
      console.log(error);
      this.setState({deliveryToCancel:-1, cancelling:false});
    });
  }

  hidecancelDeliveryAlert(){
    this.setState({deliveryToCancel: -1, cancelling:false});
  }

  render() {

    const { classes } = this.props;
    let dataRows = [];
    let deliveries = this.props.deliveries;

    let modalParcel = '';

    if (this.state.showParcel !== ''){
        modalParcel =  (
            <ModalDeliveryView
                open = {true}
                trackingNo={this.state.showParcel}
                onClose={this.hideViewWindow}  
            />
        );
    }

    deliveries.forEach( (d, key) => { 
      const actions = (
        <Tooltip
        id="tooltip-cancel"
        title="Cancelar envío"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
        >
          <div style={{display:'inline'}}>
            <Delete 
              style={{color:'#f44336', fontSize:"18px", marginLeft:"12px", cursor:"pointer"}}
              deleteFunction={() => this.cancelFunction(key)}
            />
          </div>
        </Tooltip>
      );

      const trackingNo = (
         <div>
           <Link
            component="button"
            underline="always"
            style={{fontSize:"14px", userSelect:"text", color:"#9c27b0"}}
            onClick={() => {
              this.viewParcel(d.trackingno.value)
            }}>{d.trackingno.value}</Link>
         </div>
      );
      dataRows.push(
        [
          actions,
          key + 1,
          trackingNo,
          d.name.value,
          d.packageno.value,
          d.description.value,
          formatAmount(d.cod.value),
          d.cellphone.value,
          d.address.value,
          d.municipio.value,
          d.departamento.value,
          d.referencias.value
        ]
      )
    });

    let popup = '';

    if (this.state.deliveryToCancel !== -1){
      let cancelBtnText = this.state.cancelling ? 'Cancelando...' : 'Si, cancelar envío';
      popup = (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-200px", width:"40%"  }}
          title="Cancelar envío"
          onConfirm={() => this.cancelDelivery()}
          onCancel={() => this.hidecancelDeliveryAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={cancelBtnText}
          disabled = {this.state.cancelling}
          cancelBtnText="Mantenerlo activo"
          showCancel
          reverseButtons={true}
        >
          <div className={classes.alertText}>Seguro que deseas cancelar éste envío?</div>
        </SweetAlert>
      )
    }
   
    return (
      <div>
        {popup}
        {modalParcel}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
          <Table
              striped
              tableHead={[
                "Acciones",
                "No.",
                "Guía de Envío",
                "Nombre",
                "Factura",
                "Contenido",
                "Monto COD",
                "No. Celular",
                "Dirección",
                "Municipio",
                "Departamento",
                "Instrucciones"
              ]}
              tableData={dataRows}

              customCellClasses={[classes.center, classes.center, classes.cellNoWrap, classes.cellNoWrap, classes.right]}
              customClassesForCells={[0,1,2,5,7]}

              customHeadCellClasses={ Array(13).fill(classes.header)}
              customHeadClassesForCells={[0,1,2,3,4,5,6,7,8,9,10,11,12]}

            />

          </GridItem>
          <GridItem xs={12}>
            <div style={{textAlign:'center', marginTop:"50px", marginBottom:"50px"}}>
              <Button color="primary" 
                      size="lg" 
                      onClick={() => this.props.nextFunction()}
                      className={classes.buttonGenerar}>Imprimir guías de envío</Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step3);
