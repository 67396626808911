import React from "react";
import { withStyles } from "@material-ui/core";
import {addDays} from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { DateRangePicker } from 'react-date-range';
import { defaultInputRanges, defaultStaticRanges } from 'components/TrackingTable/dateRanges';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popover from '@material-ui/core/Popover';
import ButtonCore from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import cloneDeep from 'lodash.clonedeep';

const styles = {

}

const months = ['Ene','Feb','Mar','Abr','May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];


class DateRangeInput extends React.Component {

    constructor(props){
        super(props);
        let startDate = this.props.startDate ? this.props.startDate : addDays(new Date(), -7);
        let endDate   = this.props.endDate ? this.props.endDate : new Date();
        this.state = {
            anchorEl: null,
            dateRangePicker: {
                selection: {
                  startDate: startDate,
                  endDate: endDate,
                  key: 'selection',
                }
            }
        }
        this.closeCalendar = this.closeCalendar.bind(this);
        //initialize selection
        this.previousRange = {
            selection: {
                startDate: startDate,
                endDate: endDate,
                key: 'selection',
            }
        }
    }

    closeCalendar () {
        this.setState(
            {
                anchorEl:null,
                dateRangePicker: this.previousRange
            }
        );
    };

    saveSelectedDate () {
        this.setState({anchorEl:null});
        //push selection
        let startDate =  this.state.dateRangePicker.selection.startDate;
        let endDate = this.state.dateRangePicker.selection.endDate;
        this.previousRange = {
            selection: {
                startDate: startDate,
                endDate: endDate,
                key: 'selection',
            }
        }
        if (this.props.onDateRangeChange){
            this.props.onDateRangeChange(startDate, endDate);
        }

    }

    handleRangeChange(which, payload) {
       this.setState({
          dateRangePicker: {
            //...this.state[which],
            ...payload,
          }
        });
    }

    getRangeText(){
        const startDate = this.state.dateRangePicker.selection.startDate;
        const endDate = this.state.dateRangePicker.selection.endDate;
  
        //Day of month and Month name
        var part1 = startDate.getDate() + ' ' + months[startDate.getMonth()];
        //If start date's year is different than end date's, attach it to part 1
        part1 += (startDate.getFullYear() !== endDate.getFullYear()) ? ', '+ startDate.getFullYear() : '';
  
        //Daty of end date
        var part2 = (startDate.getDate() !== endDate.getDate() ) ? ' – ' + endDate.getDate() : '';
        //if month is different than start date, attach it
        part2 += (startDate.getMonth() !== endDate.getMonth() ) ? ' ' + months[endDate.getMonth()] : '';
        //always attach end date's year
        part2 +=  ', '+ endDate.getFullYear();
  
        return part1 + part2;
    }

    render(){

        const {classes} = this.props;     

        const rangeText = this.getRangeText(); 
        let rangeSelection = this.state.dateRangePicker.selection;
        return(
            <div>
                {/* Text & Button */}
                <Link
                component="button"
                underline="none"
                style={{color:"purple",fontSize:"14px"}}
                onClick={(event) => this.setState({anchorEl:event.currentTarget})} 
                >
                    {rangeText}
                </Link>
                <IconButton 
                    aria-label="fechas" 
                    onClick={(event) => this.setState({anchorEl:event.currentTarget})} >
                    <FontAwesomeIcon icon={faCalendarAlt} style={{fontSize:'20px', color:'purple'}}/>
                </IconButton>

                {/* PopUp */}
                <Popover
                id="calendar-filter"
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.closeCalendar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                >
                    <DateRangePicker
                        onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        maxDate = {new Date()}
                        className={'PreviewArea'}
                        locale = {esLocale}
                        months={2}
                        ranges={[rangeSelection]}
                        direction="horizontal"
                        staticRanges = {defaultStaticRanges}
                        inputRanges = {defaultInputRanges}
                    />
                    <GridContainer justify="center" style={{borderTop:"1px solid #e0e0e0", padding:'5px'}}>
                        <GridItem xs={12} style={{textAlign:'right'}}>
                            <ButtonCore simple style={{color:'purple'}} color="primary" onClick={()=>this.closeCalendar()}>
                                Cancelar
                            </ButtonCore>
                            <ButtonCore simple style={{color:'purple', marginLeft:'10px'}} color="primary" onClick={()=>this.saveSelectedDate()}>
                                Aplicar
                            </ButtonCore>
                        </GridItem>
                    </GridContainer>
                </Popover>
            </div>
        );
    }

}

export default withStyles(styles)(DateRangeInput);