import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table";
import { formatAmount } from "../../utils/format";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Button from "components/CustomButtons/Button.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const styles = {
    ...customSelectStyle,
    ...buttonStyle,
    tableHeader:{
        fontWeight:"900",
        fontSize:"14px !important",
        whiteSpace: "nowrap"
    },
    cell:{
        borderTop:"0px !important",
        padding: "7px !important",
        whiteSpace: "nowrap"
    },
    cellRight:{
        textAlign:'right'
    },
    alertText:{
        fontSize:"20px",
        lineHeight:"25px"
    },
    hidden:{
        display:'none'
    }
}

function DisplayRateAmount(props){
    const { mode, amount, ...other } = props;

    if (mode === 'Flat'){
        return (<>Q. {formatAmount(amount)}</>)
    }else if(mode === 'Percentage'){
        return (<>{amount * 100} %</>)
    }
}

function DisplayRateMode(props){
    const {mode, ...other} = props;
    if (mode === 'Percentage'){
        return ('Porcentage');
    }else{
        return ('Tarifa');
    }
}


class ParcelCarrier extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            ratesData : [],
            carrierId : this.props.parcel.carrierId,
            carrierList : [],
            noData: false,
            askSave: false,
            saving: false,
            error: '',
            savedCarrierdId: this.props.parcel.carrierId
        }
        this.pullServiceRates = this.pullServiceRates.bind(this);
        this.onCarrierChange = this.onCarrierChange.bind(this);
        this.saveCarrier = this.saveCarrier.bind(this);
    }

    componentDidMount(){
        this.pullServiceRates();
    }

    pullServiceRates() {

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        //rate for selected carrier
        axios.get(apiURL + "/backoffice/servicerate/"+this.props.parcel.parcelId)
            .then( (res) => {
               
                if (res.data.length === 0){
                    this.setState({noData:true, ratesData:[]});
                }else{
                    this.setState({noData:false, ratesData: res.data})
                }

            }).catch( (error) =>{
                console.log(error);
            });

        //list of carriers
        axios.get(apiURL + "/backoffice/carriers/")
            .then( (res) => {
                this.setState({carrierList: res.data})
            }).catch( (error) =>{
                console.log(error);
            });

    }

    onCarrierChange(event){
        this.setState({carrierId: event.target.value});

        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        axios.get(apiURL + "/backoffice/servicerate/"+this.props.parcel.parcelId+'?carrierId='+event.target.value)
        .then( (res) => {
            if (res.data.length === 0){
                this.setState({noData:true, ratesData:[]});
            }else{
                this.setState({noData:false, ratesData: res.data})
            }
        }).catch( (error) =>{
            console.log(error);
        });
    }

    saveCarrier(){

        if (this.state.ratesData.length === 0){
            this.setState({error:'No se han definido tarifas para el transportista proporcionado.', saving:false, askSave:false})
        }

        this.setState({saving:true});
        let data = {
            parcelId : this.props.parcel.parcelId,
            carrierId : this.state.carrierId
        };
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        axios.post(apiURL + "/backoffice/parcel/carrier", data)
        .then( (res) => {
            this.setState({saving:false, askSave:false, error:'', savedCarrierId: this.state.carrierId});
        }).catch( (error) =>{
            let msg = '';
            if (error.toString().includes('Network Error')){
                msg = "No ha sido posible conectarse al servidor. Por favor, intenta más tarde.";
            }else{
                msg = 'Ha ocurrido un error inesperado, por favor, intenta de nuevo.';
                if (error.response && error.response.data && error.response.data.error){
                    msg = error.response.data.error;
                }
            }
            this.setState({error:msg, saving:false, askSave:false});
        });
    }

    render(){

        const {classes} = this.props;

        const parcel = this.props.parcel;

        let customHeadCellClasses =[
            classes.tableHeader,
            classes.tableHeader,
            classes.tableHeader  +' '+classes.cellRight,
            classes.tableHeader,
            classes.tableHeader  +' '+classes.cellRight,
        ];

        let customCellClasses = [classes.cell, classes.cell, classes.cell +' '+classes.cellRight, classes.cell, classes.cell+' '+classes.cellRight];

        let dataRows = [];

        for (let r of this.state.ratesData){
            dataRows.push([
                r.Service,
                <DisplayRateMode mode={r.CostMode} />,
                <DisplayRateAmount mode={r.CostMode} amount={r.Cost}/>,
                <DisplayRateMode mode={r.SalesPriceMode} />,
                <DisplayRateAmount mode={r.SalesPriceMode} amount={r.SalesPrice}/>
            ]); 
        }

        let popup = '';
        if (this.state.askSave){
            let text = this.state.saving ? 
                        ( <div>Cambiando...  <CircularProgress size={30} style={{color:"#a72abd"}}/> </div>) 
                        : 'Seguro que deseas cambiar el transportista?';
            popup = (
              <SweetAlert
                style={{ display: "block", marginTop: "-200px", width:"40%"  }}
                title="Cambiar transportista"
                onConfirm={ () => this.saveCarrier()}
                onCancel={() => this.setState({askSave:false})}
                confirmBtnCssClass={classes.button + " " + classes.success + (this.state.saving ? " " + classes.hidden : '')}
                cancelBtnCssClass={classes.button + " " + classes.danger + (this.state.saving ? " " + classes.hidden : '') }
                confirmBtnText="Si, cambiarlo"
                cancelBtnText="Cancelar"
                showCancel
                reverseButtons={false}
                disabled = {this.state.saving}
                closeOnClickOutside = {false}
              >
                <div className={classes.alertText}>{text}</div>
              </SweetAlert>
            )
        }

        if (this.state.error.length > 0){
            popup = (
              <SweetAlert
                  error
                  style={{ display: "block", marginTop: "-175px", width:"40%" }}
                  title="Ouch! Hubo un error"
                  onConfirm={() => this.setState({error:''})}
                  onCancel={() => this.setState({error:''})}
                  confirmBtnText="Aceptar"
                  confirmBtnCssClass={classes.button + " " + classes.primary + " " + classes.lg}
                  confirmBtnStyle = {{textTransform:"none", fontWeight:"900", fontSize:"16px"}}
                >
                    <div className={classes.alertText}>
                      {this.state.error}
                    </div>
                </SweetAlert>
            );
          }

        return (
            <>
                <GridContainer justify="center">
                    {popup}
                    <GridItem xs={1} ></GridItem>
                    <GridItem xs={10} style={{textAlign:'left'}}>
                        <FormControl style={{width:'200px'}}>
                            <Select MenuProps={{className: classes.selectMenu}}
                            classes={{select: classes.select}}
                            value={this.state.carrierId}
                            onChange={this.onCarrierChange}
                            inputProps={{name: "carrierId"}}
                            >
                                {this.state.carrierList.map ( (c, index) =>{
                                    return (
                                        <MenuItem classes={{root: classes.selectMenuItem,selected: classes.selectMenuItemSelected}} 
                                                value={c.CarrierId} key={index}>
                                            {c.Name}
                                        </MenuItem>
                                    )
                                } )}
                            </Select> 
                        </FormControl>

                        { (this.state.carrierId !== this.state.savedCarrierdId) ?
                            (
                                <Button simple color="primary" onClick={() => this.setState({askSave:true})} >
                                    Guardar
                                </Button>
                            )
                            :
                            ''
                        }

                    </GridItem>
                    <GridItem xs={1} ></GridItem>
                </GridContainer>
                <GridContainer style={{marginTop:'15px'}}>
                    <GridItem xs={1} ></GridItem>
                    <GridItem xs={10} style={{textAlign:'left'}}>
                        <FontAwesomeIcon icon={faArrowRight} style={{color:"#f44336"}}/> {` `}
                        Tarifas de servicios para <strong>{parcel.merchant}</strong>, transportando a <strong>{parcel.municipio}, {parcel.departamento}</strong>.
                    </GridItem>
                    <GridItem xs={1} ></GridItem>

                    <GridItem xs={1} ></GridItem>
                    <GridItem xs={10}>
                        <Table

                            showLoading
                            noData = {this.state.noData}

                            striped

                            tableHead={["Servicio", "Modalidad Costo", "Costo", "Modalidad Precio", "Precio"]}
                            tableData={dataRows}

                            customHeadCellClasses={customHeadCellClasses}
                            customHeadClassesForCells={[0,1,2,3,4]}

                            customCellClasses={customCellClasses}
                            customClassesForCells={[0,1,2,3,4]}

                            customRowsClass = {classes.row}
                        />
                    </GridItem>
                    <GridItem xs={1} ></GridItem>
                </GridContainer>
            </>
        );

    }

}

export default withStyles(styles)(ParcelCarrier);