import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FinanceTransaction from "components/FinanceTransaction/FinanceTransaction";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const apiURL = process.env.REACT_APP_API_URL;
const axios = require('axios');

const style = {
   
}

function ServiceName( props ){
    const { code, ...other} = props;

    let name = '';

    if (code === 'SHIPPING'){
        name = 'Envío'
    }else if (code === 'COD'){
        name = 'Recolección de COD'
    }else if (code === 'OTHER'){
        name = 'Otros'
    }else if (code === 'RETURN'){
        name = 'Retorno al remitente'
    }else{
        name = code;
    }

    return ( name );
}


class ParcelFinance extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            transactions: [],
            noData: false,
            loading: false
        }

    }

    componentDidMount () {

        this.setState({loading:true})
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        
        axios.get(apiURL + "/backoffice/parcel/finance/"+this.props.parcelId)
            .then( (res) => {
                if (res.data.length === 0){
                    this.setState({noData:true, transactions: [], loading: false});
                }else{
                    this.setState({noData:false, transactions: res.data, loading:false});
                }
            }).catch( (error) =>{
                console.log(error);
                this.setState({noData:true, transactions: [], loading: false});
            });

    }

    render(){
        const { classes } = this.props;
       
        let lastServiceCode = '';
        let services = [];
        let index = -1;
        for (let t of this.state.transactions){
            if (t.ServiceCode != lastServiceCode){
                index++;
                lastServiceCode = t.ServiceCode;
                if (t.ServiceCode === null) lastServiceCode = t.TransactionTypeName
                services.push(
                    {
                        name: lastServiceCode,
                        transactions: []
                    }
                )
            }
            services[index].transactions.push (t);
        }

        console.log(services);

        return (
            <GridContainer>
                {this.state.loading && (
                    <GridItem xs={12}>
                        ...Cargando <CircularProgress size={20} style={{marginRight:'5px', color:"purple"}}/>
                    </GridItem>
                )}
                {this.state.noData && (
                    <GridItem xs={12}>
                        No hay datos para mostrar
                    </GridItem>
                )}
                {services.map ( item => {
                    return (
                        <GridItem xs={12}>
                            <ExpansionPanel style={{borderRadius:'0'}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" style={{fontWeight:'900'}}>
                                    <ServiceName code = {item.name} />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {item.transactions.map ( transaction => { 
                                        return (
                                            <GridItem xs={parseInt(12 / item.transactions.length)}>
                                                <FinanceTransaction transaction = {transaction} />
                                            </GridItem>
                                        )
                                    })}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </GridItem>
                    )
                })}
            </GridContainer>
        
        );

    }

}

export default withStyles(style)(ParcelFinance);
