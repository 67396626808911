import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import People from "@material-ui/icons/People";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import ArtTrack from "@material-ui/icons/ArtTrack";
import CardFooter from "components/Card/CardFooter.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Place from "@material-ui/icons/Place";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import roxana from "assets/img/faces/driver.jpg";
import CardAvatar from "components/Card/CardAvatar.js";
import van1 from "assets/img/van1.jpg";
import van2 from "assets/img/van1.jpg";
import van3 from "assets/img/van1.jpg";
import van4 from "assets/img/van1.jpg";
import dashboard from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


const styles = {
    ...dashboard,
    content:{
        fontSize: '20px',
        borderTop: '1px solid #eaeaea',
        paddingTop: '10px'
    },
}

const vans = [
    {
        name: 'Panel - Modelo 2019',
        service: '12/11/2019',
        kms: '10,234',
        nextservice: '12/02/2019',
        plate: 'C - 234QDW',
        route: 'Ruta Norte',
        image : van1
    },
    {
        name: 'Panel - Modelo 2019',
        service: '19/11/2019',
        kms: '5,234',
        nextservice: '12/01/2019',
        plate: 'C - 433DCC',
        route: 'Ruta Central',
        image : van2
    },
    {
        name: 'Panel - Modelo 2019',
        service: '05/12/2019',
        kms: '30,234',
        nextservice: '05/04/2019',
        plate: 'C - 234FYJ',
        route: 'Ruta Sur',
        image : van3
    },
    {
        name: 'Panel - Modelo 2019',
        service: '12/12/2019',
        kms: '20,522',
        nextservice: '12/03/2019',
        plate: 'C - 626GJR',
        route: 'Ruta Oriente',
        image : van4
    }
]

const staff = [
    {
        name:'Jorge de Paz',
        title: 'Conductor',
        cellphone: '3232-4232',
        licenseNo: '99822837',
        image: roxana
    },
    {
        name:'Pedro Martínez',
        title: 'Conductor',
        cellphone: '4432-5241',
        licenseNo: '3111312123',
        image: roxana
    },
    {
        name:'Marcos Pastor',
        title: 'Conductor',
        cellphone: '2332-9838',
        licenseNo: '443223233',
        image: roxana
    }
];

class FleetConfig extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        const { classes } = this.props;

        var vanGrid = [];
        for (let van of vans){
            vanGrid.push(
                <GridItem xs={12} sm={12} md={3} >
                <Card product className={classes.cardHover}>
                    <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img src={van.image} alt="..." />
                    </a>
                    </CardHeader>
                    <CardBody>
                    <div className={classes.cardHoverUnder}>
                        <Tooltip
                        id="tooltip-top"
                        title="View"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                        >
                        <Button color="transparent" simple justIcon>
                            <ArtTrack className={classes.underChartIcons} />
                        </Button>
                        </Tooltip>
                        <Tooltip
                        id="tooltip-top"
                        title="Edit"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                        >
                        <Button color="success" simple justIcon>
                            <Edit className={classes.underChartIcons} />
                        </Button>
                        </Tooltip>
                        <Tooltip
                        id="tooltip-top"
                        title="Remove"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                        >
                        <Button color="danger" simple justIcon>
                            <Delete className={classes.underChartIcons} />
                        </Button>
                        </Tooltip>
                    </div>
                    <h4 className={classes.cardProductTitle}>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                            {van.name}
                        </a>
                    </h4>
                    <p>
                        
                        Último servicio: {van.service}<br/>
                        Kilometraje: {van.kms}<br/>
                        Fecha estimada próximo servicio: {van.nextservice} <br/>
                    </p>
                    </CardBody>
                    <CardFooter product>
                    <div className={classes.price}>
                        <h4>{van.plate}</h4>
                    </div>
                    <div className={`${classes.stats} ${classes.productStats}`}>
                        <Place /> {van.route}
                    </div>
                    </CardFooter>
                </Card>
            </GridItem>
            )
        }

        var staffList = [];
        for (let s of staff){
            staffList.push(
                [
                    <GridItem xs={4}>
                        <Card profile>
                            <CardAvatar profile>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                <img src={s.image} alt="..." />
                            </a>
                            </CardAvatar>
                            <CardBody profile>
                            <h6 className={classes.cardCategory}>{s.title}</h6>
                            <h4 className={classes.cardTitle}>{s.name}</h4>
                            <p className={classes.description}>{s.cellphone}</p>
                            <Button color="rose" round>
                                Editar
                            </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                ]
            );
        }

        return (
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardBody>
                <NavPills
                    color="primary"
                    alignCenter
                    tabs={[
                    {
                        tabButton: "Vehículos",
                        tabIcon: LocalShipping,
                        tabContent: (
                            <div class={classes.content} >
                                <GridContainer style={{width:'98%'}}>
                                    <GridItem xs={12} style={{textAlign:'right', marginBottom:'20px'}}>
                                        <Button 
                                            color="primary" 
                                            round 
                                            style={{width:"55px", height:"60px"}}
                                            onClick={ () => {alert('Agregar un vehículo')} }
                                        >
                                            <FontAwesomeIcon icon={faPlus} size='2x' />
                                        </Button>
                                    </GridItem>
                                    {vanGrid}
                                </GridContainer>
                            </div>
                        )
                    },
                    {
                        tabButton: "Personal",
                        tabIcon: People,
                        tabContent: (
                            <div class={classes.content} style={{paddingTop:'10px'}}>
                                <GridContainer justify="center" style={{width:"98%", margin:"0 auto"}}>
                                    <GridItem xs={12} style={{textAlign:'right'}}>
                                        <Button 
                                            color="primary" 
                                            round 
                                            style={{width:"55px", height:"60px"}}
                                            onClick={ () => {alert('Agregar personal')} }
                                        >
                                            <FontAwesomeIcon icon={faPlus} size='2x' />
                                        </Button>
                                    </GridItem>
                                   {staffList}
                                </GridContainer>
                            </div>
                        )
                    }
                    ]}
                />
                </CardBody>
                </Card>
                </GridItem>
            </GridContainer>
        );
    }

}

export default withStyles(styles)(FleetConfig);
