const transactionType = {
    SALE : 1,
    COST : 2,
    BANK_DEPOSIT: 3,
    MERCHANT_SETTLEMENT: 4,
    VENDOR_SETTLEMENT: 5
}

const GLAccountType = {
    ASSET: 'ASSET',
    LIABILITY: 'LIABILITY',
    REVENUE: 'REVENUE',
    EXPENSE: 'EXPENSE'
}

const GLAccount = {
    BANK : 1,
    ACCOUNTS_RECEIVABLE: 2,
    ACCOUNTS_PAYABLE: 3,
    COD_TO_SETTLE: 4,
    PROVISION_FOR_AR: 5,
    COSS: 6,
    SALES: 7
}

module.exports = {
    GLAccountType,
    GLAccount,
    transactionType
}